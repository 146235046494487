import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { Individual, Procedure, Step } from 'src/app/classes';

@Component({
  selector: 'app-canvas4',
  templateUrl: './canvas4.component.html',
  styleUrls: ['./canvas4.component.scss']
})
export class Canvas4Component implements OnInit {
  @Input() procedure: Procedure = {};
  @Input() steps = [];
  @Input() currentStep: any;
  @Input() stepIndex: any;
  personSteps: Array<any> = this.steps.filter((step) => step.ouverture_type === 'person');
  textSteps: Array<any> = this.steps.filter((step) => step.ouverture_type === 'text');
  @Output() newPerson = new EventEmitter<any>();
  @Output() parameters = new EventEmitter<any>();
  index: number;

  title = '';
  company = '';
  firstname = '';
  lastname = '';
  address = '';
  npa = '';
  localite = '';
  phone = '';
  email = '';
  date_of_birth = '';
  place_of_birth = '';
  municipality = '';
  date_of_death = '';
  place_of_death = '';
  text = '';
  extra = [];

  config = {
    toolbar: [['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }]]
  };

  constructor(
    public dialog: MatDialog,
    private user: UserService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public state: StateService
  ) {
    this.user.reset.subscribe((step) => {
      if (!step.params[step.index]) {
        this.title = '';
        this.company = '';
        this.firstname = '';
        this.lastname = '';
        this.address = '';
        this.npa = '';
        this.localite = '';
        this.phone = '';
        this.email = '';
        this.date_of_birth = '';
        this.place_of_birth = '';
        this.municipality = '';
        this.date_of_death = '';
        this.place_of_death = '';
        this.text = '';
      } else {
        if (step.params[step.index].type === 'person') {
          this.title = step.params[step.index].title;
          this.company = step.params[step.index].company;
          this.firstname = step.params[step.index].firstname;
          this.lastname = step.params[step.index].lastname;
          this.address = step.params[step.index].address;
          this.npa = step.params[step.index].npa;
          this.localite = step.params[step.index].localite;
          this.phone = step.params[step.index].phone;
          this.email = step.params[step.index].email;
          this.date_of_birth = step.params[step.index].date_of_birth;
          this.place_of_birth = step.params[step.index].place_of_birth;
          this.municipality = step.params[step.index].municipality;
          this.date_of_death = step.params[step.index].date_of_death;
          this.place_of_death = step.params[step.index].place_of_death;
        } else if (step.params[step.index].type === 'text') {
          this.text = step.params[step.index].text;
        }
      }
    });
  }
  ngOnInit(): void {
    this.index = this.steps.length;
    let parameters: Individual | Step;
    this.steps.forEach((step, index) => {
      let translatedStep = step.translations.find(
        (element) => element.language === this.translate.currentLang
      );
      if (step.ouverture_type === 'person') {
        parameters = {
          title: this.title,
          company: this.company,
          firstname: this.firstname,
          lastname: this.lastname,
          address: this.address,
          npa: this.npa,
          localite: this.localite,
          phone: this.phone,
          email: this.email,
          date_of_birth: this.date_of_birth,
          place_of_birth: this.place_of_birth,
          municipality: this.municipality,
          date_of_death: this.date_of_death,
          place_of_death: this.place_of_death,
          index: index,
          type: step.ouverture_type,
          name: translatedStep.name
        };
      } else if (step.ouverture_type === 'text') {
        parameters = {
          text: this.text ? this.text : '',
          index: index,
          type: step.ouverture_type,
          name: translatedStep.name
        };
      }
      this.parameters.emit(parameters);
    });
  }

  onChange() {
    let parameters: Individual | Step;
    if (this.currentStep.ouverture_type === 'person') {
      parameters = {
        title: this.title,
        company: this.company,
        firstname: this.firstname,
        lastname: this.lastname,
        address: this.address,
        npa: this.npa,
        localite: this.localite,
        phone: this.phone,
        email: this.email,
        index: this.stepIndex,
        date_of_birth: this.date_of_birth,
        place_of_birth: this.place_of_birth,
        municipality: this.municipality,
        date_of_death: this.date_of_death,
        place_of_death: this.place_of_death,
        type: this.currentStep.ouverture_type,
        name: this.currentStep.name
      };
    } else if (this.currentStep.ouverture_type === 'text') {
      parameters = {
        text: this.text,
        index: this.stepIndex,
        type: this.currentStep.ouverture_type,
        name: this.currentStep.name
      };
    }
    this.parameters.emit(parameters);
  }

  addPerson() {
    console.log(this.currentStep);
    let parameters = {
      title: '',
      company: '',
      firstname: '',
      lastname: '',
      address: '',
      npa: '',
      localite: '',
      phone: '',
      email: '',
      text: '',
      date_of_birth: '',
      place_of_birth: '',
      municipality: '',
      date_of_death: '',
      place_of_death: '',
      type: this.currentStep.ouverture_type,
      name: this.currentStep.name,
      index: this.index++
    };
    if (this.currentStep.extra) {
      this.currentStep.extra.push(parameters);
    } else {
      Object.assign(this.currentStep, { extra: [parameters] });
    }
    this.translate.get('addedNewPerson').subscribe((res: string) => {
      this.toastr.success(res);
    });
    this.parameters.emit(parameters);
  }
}
