<div class="p-5 scroll">
  <button type="button" class="close" aria-label="Close" (click)="iks()">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="container">
    <h1 style="color: #2c77ff; font-weight: 500" translate>newTypeOfProcedure</h1>

    <form [formGroup]="configurerProcedure" (ngSubmit)="onSubmit()">
      <p style="color: #63799b; font-weight: 500" translate>mainInfo</p>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="form-group m-0">
            <label for="titre1" style="color: #707070; font-weight: lighter" translate>title</label>
            <input
              type="text"
              class="form-control"
              placeholder="Concilation"
              id="titre1"
              formControlName="titre1"
            />
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <label for="titre1" style="color: #707070; font-weight: lighter; margin-bottom: 0.6rem" translate
            >pictogram</label
          >
          <div class="custom-file">
            <label class="custom-file-label" for="inputGroupFile01" style="margin: 0" translate
              >chooseFile</label
            >
            <input
              type="file"
              class="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              (change)="handleFileInput($event.target.files)"
            />
          </div>
        </div>
      </div>

      <p translate>openFile</p>
      <div class="d-flex flex-wrap align-items-center">
        <ng-container *ngIf="overtures.length > 0">
          <span
            *ngFor="let item of overtures; let i = index"
            class="p-1 px-2 mr-2 mb-2"
            style="
              background-color: #f3f5f7;
              color: #545871;
              min-width: 70px;
              display: flex;
              align-items: center;
            "
          >
            {{ item.name }}
            <button
              type="button"
              class="close ml-2"
              aria-label="Close"
              (click)="deleteOverture(i, item.id)"
              style="box-sizing: border-box; color: #545871"
            >
              <span aria-hidden="true" id="close-button">&times;</span>
            </button>
          </span>
        </ng-container>

        <button type="button" class="btn btn-primary rounded-circle mb-2" (click)="addOuverture()">+</button>
      </div>

      <ul class="list-group" *ngIf="overtures.length > 0">
        <li class="list-group-item" *ngFor="let item of overtures; let i = index">
          Info{{ item.sorting ? item.sorting : i + 1 }}: {{ item.name }}
        </li>
      </ul>
      <h1 class="my-3" style="color: #63799b" translate>steps</h1>

      <div class="etapes" *ngFor="let etape of configurerProcedure.get('etapes')['controls']; let i = index">
        <div formArrayName="etapes">
          <div [formGroupName]="i">
            <div class="container">
              <div class="row">
                <div class="col-1">
                  <div class="circle">{{ i + 1 }}</div>
                </div>
                <div class="col-11" [class]="i == 0 ? '' : 'mt-4'">
                  <div class="custom-control custom-checkbox my-3">
                    <input
                      formControlName="canskip"
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheck{{ i + 1 }}"
                      (change)="changeTest(etape)"
                      value="1"
                    />
                    <label class="custom-control-label" for="customCheck{{ i + 1 }}" translate>canSkip</label>
                  </div>
                  <div class="custom-control custom-checkbox my-3">
                    <input
                      formControlName="canwaiting"
                      type="checkbox"
                      class="custom-control-input"
                      id="customCheckWaiting{{ i + 1 }}"
                    />
                    <label class="custom-control-label" for="customCheckWaiting{{ i + 1 }}" translate
                      >canWait</label
                    >
                  </div>
                  <div class="form-group">
                    <label for="titre2en" style="color: #707070; font-weight: lighter" translate>en</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Title ex.Recieve the request"
                      id="titre2en"
                      formControlName="nameEn"
                    />
                  </div>
                  <div class="form-group">
                    <label for="titre2fr" style="color: #707070; font-weight: lighter" translate>fr</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Titre ex.Réceptionner la requête"
                      id="titre2fr"
                      formControlName="nameFr"
                    />
                  </div>
                  <div class="form-group">
                    <label for="titre2de" style="color: #707070; font-weight: lighter" translate>de</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Titel ex.Erhalten Sie die Anfrage"
                      id="titre2de"
                      formControlName="nameDe"
                    />
                  </div>
                  <div class="form-group">
                    <label for="texteen" style="color: #707070; font-weight: lighter" translate>en</label>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="4"
                      id="texteen"
                      formControlName="textEn"
                      placeholder="Text"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="textefr" style="color: #707070; font-weight: lighter" translate>fr</label>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="4"
                      id="textefr"
                      formControlName="textFr"
                      placeholder="Texte"
                    ></textarea>
                  </div>
                  <div class="form-group">
                    <label for="textede" style="color: #707070; font-weight: lighter" translate>de</label>
                    <textarea
                      type="text"
                      class="form-control"
                      rows="4"
                      id="textede"
                      formControlName="textDe"
                      placeholder="Text"
                    ></textarea>
                  </div>
                  <div class="d-flex flex-wrap align-items-center">
                    <ng-container *ngIf="links[i]">
                      <span
                        *ngFor="let item of links[i]; let k = index"
                        class="p-1 px-2 mr-2 mb-2"
                        style="
                          background-color: #f3f5f7;
                          color: #545871;
                          min-width: 70px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ returnLinkName(item) }}
                        <button
                          type="button"
                          class="close ml-2"
                          aria-label="Close"
                          (click)="deleteLinks(i, k)"
                          style="box-sizing: border-box; color: #545871"
                        >
                          <span aria-hidden="true" id="close-button">&times;</span>
                        </button>
                      </span>
                    </ng-container>
                    <button
                      type="button"
                      class="btn btn-primary rounded-circle mb-2"
                      style="padding-left: 13px; padding-right: 13px"
                      (click)="addLink(i)"
                    >
                      +
                    </button>
                  </div>

                  <p style="color: #707070; font-weight: lighter" translate>docTemplates</p>

                  <label for="file" style="color: #707070; font-weight: lighter" translate>en</label>
                  <div class="d-flex flex-wrap align-items-center">
                    <ng-container *ngIf="uploadedDocumentsEn[i] && uploadedDocumentsEn[i].length > 0">
                      <span
                        *ngFor="let doc of uploadedDocumentsEn[i]; let k = index"
                        class="p-1 px-2 mr-2 mb-2"
                        style="
                          background-color: #f3f5f7;
                          color: #545871;
                          min-width: 70px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ doc.name }}
                        <button
                          type="button"
                          class="close ml-2"
                          aria-label="Close"
                          (click)="deleteUploaded(i, k, doc.id, 'en')"
                          style="box-sizing: border-box; color: #545871"
                        >
                          <span aria-hidden="true" id="close-button">&times;</span>
                        </button>
                      </span>
                    </ng-container>
                  </div>

                  <ngx-file-drag-drop
                    multiple
                    emptyPlaceholder="{{ 'uploadFiles' | translate }}"
                    #enDocs
                    (valueChanged)="dragDropDoc($event, i, 'en')"
                    style="border-color: #2c77ff; max-width: 1000px"
                    [accept]="acceptedExt"
                  >
                  </ngx-file-drag-drop>

                  <label class="mt-2" for="file" style="color: #707070; font-weight: lighter" translate
                    >fr</label
                  >
                  <div class="d-flex flex-wrap align-items-center">
                    <ng-container *ngIf="uploadedDocumentsFr[i] && uploadedDocumentsFr[i].length > 0">
                      <span
                        *ngFor="let doc of uploadedDocumentsFr[i]; let k = index"
                        class="p-1 px-2 mr-2 mb-2"
                        style="
                          background-color: #f3f5f7;
                          color: #545871;
                          min-width: 70px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ doc.name }}
                        <button
                          type="button"
                          class="close ml-2"
                          aria-label="Close"
                          (click)="deleteUploaded(i, k, doc.id, 'fr')"
                          style="box-sizing: border-box; color: #545871"
                        >
                          <span aria-hidden="true" id="close-button">&times;</span>
                        </button>
                      </span>
                    </ng-container>
                  </div>
                  <ngx-file-drag-drop
                    multiple
                    emptyPlaceholder="{{ 'uploadFiles' | translate }}"
                    #frDocs
                    (valueChanged)="dragDropDoc($event, i, 'fr')"
                    style="border-color: #2c77ff; max-width: 1000px"
                    [accept]="acceptedExt"
                  ></ngx-file-drag-drop>

                  <label class="mt-2" for="file" style="color: #707070; font-weight: lighter" translate
                    >de</label
                  >
                  <div class="d-flex flex-wrap align-items-center">
                    <ng-container *ngIf="uploadedDocumentsDe[i] && uploadedDocumentsDe[i].length > 0">
                      <span
                        *ngFor="let doc of uploadedDocumentsDe[i]; let k = index"
                        class="p-1 px-2 mr-2 mb-2"
                        style="
                          background-color: #f3f5f7;
                          color: #545871;
                          min-width: 70px;
                          display: flex;
                          align-items: center;
                        "
                      >
                        {{ doc.name }}
                        <button
                          type="button"
                          class="close ml-2"
                          aria-label="Close"
                          (click)="deleteUploaded(i, k, doc.id, 'de')"
                          style="box-sizing: border-box; color: #545871"
                        >
                          <span aria-hidden="true" id="close-button">&times;</span>
                        </button>
                      </span>
                    </ng-container>
                  </div>
                  <ngx-file-drag-drop
                    multiple
                    emptyPlaceholder="{{ 'uploadFiles' | translate }}"
                    #deDocs
                    (valueChanged)="dragDropDoc($event, i, 'de')"
                    style="border-color: #2c77ff; max-width: 1000px"
                    [accept]="acceptedExt"
                  ></ngx-file-drag-drop>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top: 10px; padding-left: 15px">
        <button
          type="button"
          class="btn btn-primary rounded-circle"
          style="padding-left: 13px; padding-right: 13px"
          (click)="addEtapesButton()"
        >
          +
        </button>
        <a class="btn btn-link text-primary" (click)="addEtapesButton()" translate> addNewStep </a>
      </div>

      <div class="text-right pb-1">
        <button class="btn btn-primary rounded-pill" (click)="onSubmit($event)" translate>save</button>
      </div>
    </form>
  </div>
</div>
