<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="name" style="color: #707070; font-weight: lighter" translate>en</label>
    <input type="text" class="form-control mb-1" id="name" [(ngModel)]="nameEn" placeholder="Name" />
    <label for="name" style="color: #707070; font-weight: lighter" translate>fr</label>
    <input type="text" class="form-control mb-1" [(ngModel)]="nameFr" placeholder="Nom" />
    <label for="name" style="color: #707070; font-weight: lighter" translate>de</label>
    <input type="text" class="form-control mb-1" [(ngModel)]="nameDe" placeholder="Name" />
  </div>
  <div class="mb-3">
    <label for="select" style="color: #707070; font-weight: lighter" translate>type</label>
    <select class="form-control" id="select" [(ngModel)]="type">
      <option *ngFor="let t of options" value="{{ t.toLowerCase() }}">
        {{ t }}
      </option>
    </select>
  </div>
  <div *ngIf="type === 'person'" class="custom-control custom-checkbox my-3">
    <input
      [(ngModel)]="canAddPerson"
      type="checkbox"
      class="custom-control-input"
      id="add-person"
      value="1"
    />
    <label class="custom-control-label" for="add-person" translate>canAddPerson</label>
  </div>

  <div class="text-center">
    <button class="btn btn-primary rounded-pill" (click)="save()" translate>save</button>
  </div>
</div>
