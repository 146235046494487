import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { StateService } from 'src/app/services/state.service';

interface DialogData {
  user: Number;
}

export interface Organization {
  id: number;
  name: string;
  translations_folder: string;
  users: User[];
  groups: Group[];
  created_by: number;
  updated_by: null;
  deleted_by: null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export interface Group {
  id: number;
  organization_id: number;
  name: string;
  description: string;
  users: User[];
  created_at: string;
  updated_at: string;
  deleted_at: null;
}

export interface User {
  id: number;
  organization_id: number;
  firstname: string;
  lastname: string;
  profile_pic: ProfilePic;
  npa: null;
  localite: null;
  email: string;
  created_by: null;
  updated_by: null;
  deleted_by: null;
}

export enum ProfilePic {
  ImagesUserdefaultPNG = '/images/userdefault.png',
}

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent implements OnInit {
  organization: Organization = null;

  constructor(
    public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private state: StateService,
  ) {
  }

  ngOnInit(): void {
    this.state.organization.subscribe((res) => {
      this.organization = res;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
