import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';

interface DialogData {
  role: String;
}

@Component({
  selector: 'app-change-role-dialog',
  templateUrl: './change-role-dialog.component.html',
  styleUrls: ['./change-role-dialog.component.scss']
})
export class ChangeRoleDialogComponent implements OnInit {
  organization = null;

  constructor(
    public dialogRef: MatDialogRef<ChangeRoleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private state: StateService,
    private translateService: TranslateService
  ) {
    // translateService.setDefaultLang('fr');
  }

  ngOnInit(): void {
    this.state.organization.subscribe((res) => {
      this.organization = res;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  // switchLanguage(language: string) {
  //   this.translateService.use(language);
  // }
}
