import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

interface DialogData {
  procedure: any;
  person: any;
  personTypes: any;
  document: any;
}

@Component({
  selector: 'app-word-file-dialog',
  templateUrl: './word-file-dialog.component.html',
  styleUrls: ['./word-file-dialog.component.scss']
})
export class WordFileDialogComponent implements OnInit, AfterViewInit {
  procedure: any = this.data.procedure;
  personTypes = this.data.personTypes;
  document: any = this.data.document;
  organization: any = null;
  user: any = null;
  form: FormGroup;
  noMailing: any = false;
  baseUrl = this.axiosCallService.baseUrl;

  constructor(
    public dialogRef: MatDialogRef<WordFileDialogComponent>,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private state: StateService,
    private fb: FormBuilder,
    private axiosCallService: AxiosCallService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      recipient: new FormControl(null, Validators.required),
      mailing: this.fb.array([]),
      noMailing: new FormControl(false)
    });

    this.state.refreshOrganization();
    // Fix the sorting of the persons by type
    this.procedure.people = this.procedure.people.sort((a, b) => a.sorting - b.sorting);

    // console.log(this.procedure);
    // console.log('personTypes: ', this.personTypes);
    // console.log('people: ', this.procedure.people);
    this.state.organization.subscribe((res) => {
      this.organization = res;
      // console.log(this.organization);
    });
  }

  ngAfterViewInit(): void {
    const recipientFormControl = this.form.controls.recipient;
    recipientFormControl.setValue(this.procedure.people[0]);

    this.state.user.subscribe((res) => {
      this.user = res;
    });
  }

  compareUsers(u1: any, u2: any): boolean {
    console.log('compare', u1, u2)
    return u1 && u2 ? u1.id === u2.id : u1 === u2; 
 }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getSinglePersonTypeTranslation(contact): string {
    const personType = this.personTypes.find((el) => el.name === contact.civil_status);
    if (!personType) {
      return contact.civil_status;
    }
    return this.state.returnTranslation(personType.translations, 'name');
  }

  onRecipientSelect(e) {
    console.log(e);
  }

  noMailingChange(e) {
    this.noMailing = e.target.checked;
  }

  submitForm() {
    // console.log(this.form.value);
    // console.log(this.form.value.mailing);
    // console.log(this.noMailing);

    if (this.noMailing) {
      window.location.href = `${this.baseUrl}/${this.document.path}`;
      this.onNoClick();
    }

    let user = this.organization.users.find(user => user.id == this.user.id);

    let obj = {
      user: user,
      language: this.translate.currentLang,
      group: this.procedure.groups[0],
      contacts: this.procedure.people,
      recipient: this.form.value.recipient,
      document: this.document,
      procedure: this.procedure
    };

    // send request
    this.axiosCallService.api_call_post(`mailing`, obj).then((res) => {
      window.open(`${this.baseUrl}/${res.data.path}`);
    });

    this.onNoClick();
  }
}
