import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
    public user: any;
    public loaderActivate = new Subject<any> ();
    public reset = new Subject<any> ();
    public overtures = [];
    public currentProcedure = null;
    public links = [];
    public currentLinkInd = 0;
    public editId = 0;
    // reminder variable
    public date: any;
    public editStep: any;
    public reminder = new Subject<any> ();
    // Global variables
    public organizationId: any;
}