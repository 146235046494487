<div class="mb-3">
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<h2 class="text-center nowrap mb-4" translate>downloadWordDoc </h2>

<div class="scroll">
  <form [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
    <div class="container">

      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <label class="text-nowrap font-weight-bold mt-2" translate>mailTo </label>
        </div>
        <div class="col-12 col-md-5">
          <select
            class="custom-select"
            style="cursor: pointer"
            formControlName="recipient"
            [attr.disabled]="noMailing ? '' : null"
            (change)="onRecipientSelect($event)"
          >
            <ng-container *ngFor="let person of procedure.people; let first = first;">
              <ng-container *ngIf="person.firstname || person.lastname">
                <option [ngValue]="person" [attr.selected]="first ? '' : null">
                  {{ getSinglePersonTypeTranslation(person) }} ({{ person.firstname }} {{ person.lastname }})
                </option>
              </ng-container>
              <ng-container *ngIf="!person.firstname && !person.lastname">
                <option disabled>
                  {{ getSinglePersonTypeTranslation(person) }}
                </option>
              </ng-container>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-12 col-md-4">
          <label class="text-nowrap font-weight-bold mt-2" translate>noMailing </label>
        </div>
        <div class="col-12 col-md-6">
          <ul class="form-row p-0 m-0">
            <li class="d-flex flex-row flex-wrap">
              <div class="custom-control custom-checkbox my-2">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="noMailing"
                  formControlName="noMailing"
                  (change)="noMailingChange($event)"
                  style="cursor: pointer"
                />
                <label for="noMailing" class="form-check-label" style="font-weight: lighter; cursor: pointer" translate>downloadBlank</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-right">
          <button
            type="button"
            class="btn btn rounded-pill"
            style="
              border-color: #c4c4c4;
              padding-left: 27px;
              padding-right: 27px;
              background-color: #c4c4c4;
              color: white;
            "
            (click)="onNoClick()"
            translate
          >
            cancel
          </button>
          <button
            type="submit"
            value="Submit"
            class="btn btn rounded-pill ml-2"
            style="
              border-color: #2c77ff;
              padding-left: 27px;
              padding-right: 27px;
              background-color: #2c77ff;
              color: white;
            "
            translate
          >
            download
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
