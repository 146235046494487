import { StateService } from 'src/app/services/state.service';
import { ForgotPasswordComponent } from './../forgot-password/forgot-password.component';
import { MatDialog } from '@angular/material/dialog';
// import { axios } from 'axios';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account-service';
import { first } from 'rxjs/operators';
import { ApiCallService } from 'src/app/services/api-call.service';
import { AxiosCallService } from '../../services/axios-call.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  user: any = {};
  loading = false;
  submitted = false;
  local: Boolean = false;
  devUser: Number = 0;
  devUsers: Array<any>;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public http: HttpClient,
    private apiCall: ApiCallService,
    private axiosService: AxiosCallService,
    private state: StateService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.local = !environment.production;

    this.loginForm = new FormGroup({
      email: new FormControl('', {
        updateOn: 'change',
        validators: [
          Validators.email
          // Validators.pattern(
          //   "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          // )
        ]
      }),

      password: new FormControl('', {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(8)]
      })
    });

    if (this.local) {
      this.axiosService
        .api_call_get(`dev/users`)
        .then((res) => {
          this.devUsers = res.data;
        })
        .catch((error) => {
          error.response ? console.log(error.response) : console.log(error);
        });
    }
  }

  onSubmit() {
    this.loading = true;

    const params = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    };

    this.axiosService
      .login(params)
      .then((response: any) => {
        this.loading = false;
        this.state.refreshUser();

        this.axiosService
          .getUser()
          .then((response) => {
            if (response.data.data.verified) {
              if (response.data.data.organization_id) {
                this.router.navigate(['/dashboard']);
              } else {
                this.router.navigate(['/organizations']);
              }
            } else {
              this.axiosService
                .api_call_post_base('/logout', {})
                .then((response) => {
                  this.translate.get('verifyEmailError').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                })
                .catch(console.error);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        this.loading = false;

        if (params !== null) {
          this.translate.get('invalidData').subscribe((res: string) => {
            this.toastr.error(res);
          });
        }

        if (params.password.length < 8) {
          this.translate.get('passLengthError').subscribe((res: string) => {
            this.toastr.error(res);
          });
        }

        console.log('error', err);
      });
  }

  forgotPassword() {
    this.dialog.open(ForgotPasswordComponent);
  }

  changeLanguage(lang) {
    localStorage.setItem('currentLanguage', lang);
    this.translate.use(lang);
    // this.translate.setDefaultLang(lang)
  }

  developerLogin() {
    if (this.devUser == 0) return;

    this.axiosService
      .devLogin({ id: this.devUser })
      .then((response: any) => {
        this.loading = false;
        this.state.refreshUser();

        this.axiosService
          .getUser()
          .then((response) => {
            if (response.data.data.organization_id) {
              this.router.navigate(['/dashboard']);
            } else {
              this.router.navigate(['/organizations']);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((err) => {
        this.loading = false;

        console.log('error', err);
      });
  }

  getLang(language: string): string {
    if (!language) language = 'fr';
    let result = '';

    this.translate.get(language).subscribe((res) => {
      if (res) result = res;
    });

    return result;
  }

  getFlag(language: string): string {
    if (!language) language = 'fr';

    switch (language) {
      case 'fr':
        return '../../assets/images/fr.svg';
        break;

      case 'de':
        return '../../assets/images/de.svg';
        break;

      case 'en':
        return '../../assets/images/en.svg';
        break;

      default:
        break;
    }
  }
}
