<div>
  <div class="header mb-4">
    <app-header></app-header>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2 col-xl-1 mb-3 mb-md-0">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-12 col-md-10 col-lg-10 col-xl-11">
        <h2 class="text-muted font-weight-light" translate>folders</h2>
        <div class="shadow bg-white rounded">
          <div class="d-flex justify-content-between px-2 px-sm-4 pt-2">
            <input
              class="form-control rounded-pill p-0"
              type="text"
              name="search"
              [(ngModel)]="folderSearch"
              placeholder="{{ 'search' | translate }}"
              aria-label="Rechercher"
              style="height: 30px; border: none"
            />
            <button
              (click)="openDialog()"
              class="btn btn-primary rounded-pill text-nowrap"
              style="padding-left: 20px; padding-right: 20px"
              translate
            >
              createFolder
            </button>
          </div>
          <div class="scroll px-3">
            <table *ngIf="dossiers.length > 0" class="table table-hover">
              <thead>
                <tr>
                  <th translate>
                    fileRef &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="nomPrenom"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>
                    fileNo &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="dossierNumber"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>
                    dateOfCreation &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="created"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>
                    procedure &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="procedure"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>
                    state &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="etat"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>access</th>
                  <th translate>
                    status &nbsp;<svg
                      [appSort]="dossiers"
                      data-order="desc"
                      data-name="statut"
                      style="cursor: pointer"
                      id="sort_arrows"
                      xmlns="http://www.w3.org/2000/svg"
                      width="8.506"
                      height="11.116"
                      viewBox="0 0 8.506 11.116"
                    >
                      <g
                        id="noun_Arrow_Down_2022881"
                        data-name="noun_Arrow Down_2022881"
                        transform="translate(0 7)"
                      >
                        <path
                          id="Path_2051"
                          data-name="Path 2051"
                          d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                      <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                        <path
                          id="Path_2051-2"
                          data-name="Path 2051"
                          d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                          transform="translate(-34.977 -41.352)"
                          fill="#63799b"
                        />
                      </g>
                    </svg>
                  </th>
                  <th translate>actions</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let dossier of dossiers | filter: folderSearch" style="cursor: pointer">
                  <td class="font-weight-bold" (click)="goToProcedure(dossier.id)">
                    {{ dossier.nomPrenom }}
                  </td>
                  <td (click)="goToProcedure(dossier.id)">
                    {{ dossier.dossierNumber }}
                  </td>
                  <td (click)="goToProcedure(dossier.id)">
                    {{ dossier.created | date: "dd/MM/yyyy" }}
                  </td>
                  <td (click)="goToProcedure(dossier.id)">
                    {{ dossier.procedure }}
                  </td>
                  <td (click)="goToProcedure(dossier.id)">
                    {{ dossier.etat }}
                  </td>
                  <td>
                    <div class="avatar-stack">
                      <img
                        *ngFor="let user of dossier.users | slice: 0:3"
                        class="avatar shadow"
                        src="{{ baseUrl + user.profile_pic }}"
                        onerror="this.src='../../assets/images/default-profile-pic.png'"
                        [title]="user.firstname + ' ' + user.lastname + ' ' + '(' + user.email + ')'"
                        routerlink="/profile"
                      />
                      <ng-container *ngIf="dossier.users.length > 3">
                        <div (click)="openOthers(dossier.users)" class="circle shadow">
                          + {{ dossier.users.length - 3 }}
                        </div>
                      </ng-container>
                    </div>
                  </td>
                  <td
                    (click)="goToProcedure(dossier.id)"
                    [ngStyle]="dossier.statut ? { color: '#00C94D' } : { color: '#FF2919' }"
                    translate
                  >
                    {{ dossier.statut ? "inProgress" : "finished" }}
                  </td>
                  <td>
                    <svg
                      data-toggle="collapse"
                      data-target="#collapseEdit"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 39 39"
                      style="cursor: pointer"
                      (click)="editProcedure(dossier.id)"
                    >
                      <g id="Group_2285" data-name="Group 2285" transform="translate(-454 -404)">
                        <path
                          id="Path_142"
                          data-name="Path 142"
                          d="M3,17.248V21H6.749L17.808,9.939,14.058,6.19ZM20.708,7.039a1,1,0,0,0,0-1.41l-2.34-2.34a1,1,0,0,0-1.41,0l-1.83,1.83,3.749,3.749,1.83-1.83Z"
                          transform="translate(462 412.002)"
                          fill="#2c77ff"
                        />
                      </g>
                    </svg>
                    <button
                      type="button"
                      class="close text-danger ml-2"
                      aria-label="Close"
                      (click)="dangerDialogProcedure(dossier.id)"
                    >
                      <span aria-hidden="false" id="close-button">&times;</span>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="dossiers.length < 1"
              style="display: flex; justify-content: center; align-items: center; height: 100%"
            >
              <h1 class="my-4 text-muted" translate>noFoldersMessage</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
