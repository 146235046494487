<div>
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="text-center">
    <h1 translate>verifyEmail</h1>
    <p class="lead" translate>verificationCode <span class="font-weight-bold">{{ data.email }}</span></p>
    <p translate>verifyMessage</p>
    <button class="btn btn-primary" [mat-dialog-close]>Close</button>
  </div>
</div>