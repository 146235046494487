<div>
  <div class="header mb-4">
    <app-header></app-header>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2 col-xl-1 mb-3 mb-md-0">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-12 col-md-10 col-lg-10 col-xl-11">
        <h1 class="text-muted" id="title" translate>organization</h1>
        <div class="card shadow bg-white p-3" style="border-radius: 10px; border-style: none">
          <div class="card-body">
            <div class="profile">
              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="d-flex">
                    <h1 class="text-primary d-inline m-0" id="name">
                      {{ organization ? organization.name : "" }}
                    </h1>
                    <svg
                      *ngIf="isAdmin"
                      data-toggle="collapse"
                      data-target="#collapseEdit"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 39 39"
                      style="cursor: pointer"
                    >
                      <g id="Group_2285" data-name="Group 2285" transform="translate(-454 -404)">
                        <path
                          id="Path_142"
                          data-name="Path 142"
                          d="M3,17.248V21H6.749L17.808,9.939,14.058,6.19ZM20.708,7.039a1,1,0,0,0,0-1.41l-2.34-2.34a1,1,0,0,0-1.41,0l-1.83,1.83,3.749,3.749,1.83-1.83Z"
                          transform="translate(462 412.002)"
                          fill="#2c77ff"
                        />
                      </g>
                    </svg>
                  </div>
                  <div *ngIf="isAdmin" class="collapse input-group my-2" id="collapseEdit">
                    <input
                      [(ngModel)]="newName"
                      type="text"
                      class="form-control"
                      style="font-size: 18px"
                      placeholder="{{ 'orgName' | translate }}"
                      aria-label="Organization name"
                      aria-describedby="button-addon2"
                    />
                    <div class="input-group-append">
                      <button
                        class="btn btn-outline-danger"
                        data-toggle="collapse"
                        data-target="#collapseEdit"
                        type="button"
                        id="button-addon2"
                        translate
                      >
                        cancel
                      </button>
                      <button
                        (click)="editOrganization()"
                        data-toggle="collapse"
                        data-target="#collapseEdit"
                        class="btn btn-outline-success"
                        type="button"
                        translate
                      >
                        save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 20px">
              <h1 translate>
                groups
                <span *ngIf="isAdmin" (click)="createGroup()" class="btn btn-sm btn-primary">+</span>
              </h1>
              <div class="row">
                <ng-container *ngIf="organization && user">
                  <div
                    class="col-12 col-md-6 col-lg-4 col-xl-3 my-3"
                    *ngFor="let group of isAdmin ? organization.groups : user.groups"
                  >
                    <div class="card" id="group-card" style="height: 100%">
                      <div class="card-body" id="group-card-body">
                        <div class="d-flex justify-content-between align-items-center mb-2">
                          <h3 class="card-title mb-1">
                            {{ group.name }}
                          </h3>

                          <div
                            *ngIf="group.profile_pic != '/images/userdefault.png'"
                            style="position: relative"
                          >
                            <img
                              *ngIf="group.profile_pic != '/images/userdefault.png'"
                              class="rounded-circle"
                              [src]="baseUrl + group.profile_pic"
                              alt=""
                              style="width: 30px; height: 30px; object-fit: cover; object-position: center"
                            />
                            <button
                              type="button"
                              class="close text-danger ml-2"
                              aria-label="Close"
                              style="position: absolute; bottom: 0; right: 0; font-size: 16px"
                              (click)="deleteLogo(group.id)"
                            >
                              <span aria-hidden="true" id="close-button">&times;</span>
                            </button>
                          </div>
                        </div>
                        <div class="d-flex flex-row flex-wrap">
                          <span
                            *ngIf="isAdmin"
                            class="btn btn-sm btn-success mr-2 mb-1"
                            style="cursor: pointer"
                            (click)="addUser(group.id)"
                            translate
                            >addUsers</span
                          >
                          <span
                            *ngIf="isAdmin"
                            class="btn btn-sm btn-warning mr-2 mb-1"
                            style="cursor: pointer"
                            (click)="editGroup(group.id)"
                            translate
                            >edit</span
                          >
                          <span
                            *ngIf="isAdmin && organization.groups.length > 1"
                            class="btn btn-sm btn-danger mb-1"
                            (click)="dangerDialogGroup(group.id)"
                            translate
                            >delete</span
                          >
                          <div *ngIf="isAdmin">
                            <input
                              type="file"
                              (change)="handleFileInput(group.id, $event.target.files)"
                              [id]="'file-input-' + group.id"
                              style="display: none"
                            />
                            <label
                              class="m-0 btn btn-sm btn-primary ml-2"
                              [for]="'file-input-' + group.id"
                              translate
                            >
                              addLogo
                            </label>
                          </div>
                        </div>
                        <p class="text-muted">{{ group.description }}</p>
                        <ul class="list-unstyled" *ngFor="let user of group.users">
                          <li [title]="user.email">
                            <img
                              class="rounded-circle"
                              style="width: 25px; height: 25px; object-fit: cover; object-position: center"
                              src="{{ baseUrl + user.profile_pic }}"
                            />
                            {{
                              user.firstname && user.lastname
                                ? user.firstname + " " + user.lastname
                                : user.email
                            }}
                            <button
                              *ngIf="isAdmin"
                              type="button"
                              class="close text-danger ml-2"
                              aria-label="Close"
                              (click)="removeGroupUserDialog(group.id, user.id)"
                            >
                              <span aria-hidden="true" id="close-button">&times;</span>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div *ngIf="organization && isAdmin">
                <h1 translate>users</h1>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <div class="justify-content-center">
                        <input
                          class="form-control mr-sm-2 rounded-pill my-2"
                          type="text"
                          name="search"
                          [(ngModel)]="searchUser"
                          placeholder="{{ 'search' | translate }}"
                          aria-label="Rechercher"
                          style="height: 30px; border: none"
                        />
                      </div>
                    </thead>
                    <caption translate>
                      users
                    </caption>
                    <thead>
                      <tr>
                        <th translate>name</th>
                        <th translate>email1</th>
                        <th translate>role</th>
                        <th translate>actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let orgUser of organization.users | filter: searchUser">
                        <td>
                          <img
                            [src]="baseUrl + orgUser.profile_pic"
                            class="rounded-circle"
                            style="width: 25px; height: 25px; object-fit: cover; object-position: center"
                          />
                          {{ orgUser.firstname }} {{ orgUser.lastname }}
                        </td>
                        <td>{{ orgUser.email }}</td>
                        <td style="text-transform: uppercase; letter-spacing: 0.03125rem">
                          {{ orgUser.roles.length ? "Administrator" : "Member" }}
                        </td>
                        <td>
                          <span
                            *ngIf="orgUser.id != user.id"
                            (click)="changeRole(orgUser.id)"
                            class="btn btn-sm btn-primary mr-1"
                            translate
                            >editRole</span
                          >
                          <span
                            *ngIf="isAdmin && orgUser.id != user.id"
                            (click)="removeOrganizationUserDialog(orgUser.id)"
                            class="btn btn-sm btn-danger mr-1"
                            translate
                            >remove</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- Membership Requests -->
              <div *ngIf="isAdmin">
                <ng-container *ngIf="requests && requests.length">
                  <h1 translate>membershipRequests</h1>
                  <div class="table-responsive">
                    <thead>
                      <div class="justify-content-center">
                        <input
                          class="form-control mr-sm-2 rounded-pill my-2"
                          type="text"
                          name="search"
                          [(ngModel)]="searchMembership"
                          placeholder="{{ 'search' | translate }}"
                          aria-label="Rechercher"
                          style="height: 30px; border: none"
                        />
                      </div>
                    </thead>
                    <table class="table">
                      <caption translate>
                        membershipRequests
                      </caption>
                      <thead>
                        <tr>
                          <th scope="col" translate>name1</th>
                          <th scope="col" translate>email1</th>
                          <th scope="col" translate>status</th>
                          <th scope="col" translate>actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let request of requests | filter: searchMembership">
                          <td>
                            <img
                              width="25"
                              height="25"
                              class="rounded-circle mr-1"
                              style="object-fit: cover; object-position: center"
                              [src]="baseUrl + request.user.profile_pic"
                            />
                            {{ request.user.firstname }} {{ request.user.lastname }}
                          </td>
                          <td>{{ request.user.email }}</td>
                          <td>
                            <span
                              class="btn btn-sm"
                              [ngClass]="{
                                'btn-warning': request.status == true,
                                'btn-success': request.status == false
                              }"
                              translate
                            >
                              {{ request.status ? "pending" : "accepted" }}
                            </span>
                          </td>
                          <td>
                            <div *ngIf="request.status">
                              <span
                                (click)="acceptRequest(request.id)"
                                class="btn btn-sm btn-success mr-2"
                                translate
                                >accept</span
                              >
                              <span
                                (click)="declineRequest(request.id)"
                                class="btn btn-sm btn-danger"
                                translate
                                >decline</span
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
