<div class="container">
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true">&times;</span>
  </button>
  <h1 id="head" translate>attenteTitle</h1>
  <div id="content">
    <div *ngIf="data?.edit">
      <p translate>updateReminderParagraph</p>
    </div>
    <div *ngIf="!data?.edit">
      <p translate>attenteParagraph1</p>
      <p translate>attenteParagraph2</p>
      <p translate>attenteParagraph3</p>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <div class="input-group date" id="datetimepicker1">
          <mat-form-field appearance="fill">
            <input matInput [matDatepicker]="dp" [formControl]="date" />
            <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
            <mat-datepicker #dp></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div class="text-right">
    <button
      class="btn btn-primary rounded-pill"
      style="padding-left: 20px; padding-right: 20px"
      [mat-dialog-close]="data"
      translate
    >
      {{ data?.edit ? "editReminder" : "createReminder" }}
    </button>
  </div>
</div>
