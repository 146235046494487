import { StateService } from './../../services/state.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  user: any;
  isAdmin: Boolean = false;
  imgUrl: string = '../../assets/images/default-profile-pic.png';
  baseUrl = this.axiosService.baseUrl;
  constructor(private axiosService: AxiosCallService, private router: Router, private state: StateService) {}

  ngOnInit(): void {
    this.state.user.subscribe((res) => {
      if (res.id) {
        this.user = res;
        if (res.roles.length) this.isAdmin = true;
      }
    });
  }
}
