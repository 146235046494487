<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="label" style="color: #707070; font-weight: lighter" translate>areYouSure</label>
  </div>

  <div class="text-center">
    <button type="button" class="btn btn-secondary px-4 mr-3" (click)="onNoClick()" translate>no</button>
    <button type="button" class="btn btn-primary px-4" [mat-dialog-close]="data" translate>yes</button>
  </div>
</div>