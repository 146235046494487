import { AxiosCallService } from 'src/app/services/axios-call.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  users: Array<any>;
}

@Component({
  selector: 'app-users-access',
  templateUrl: './users-access.component.html',
  styleUrls: ['./users-access.component.scss']
})
export class UsersAccessComponent implements OnInit {
  baseUrl: String = null;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private axiosCallService: AxiosCallService) {}

  ngOnInit(): void {
    this.baseUrl = this.axiosCallService.baseUrl;
  }
}
