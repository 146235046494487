import axios from 'axios';
import { AxiosInstance } from 'axios';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
// import { resolve } from 'dns';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface Params {
  [key: string]: any;
}

export interface GetOptions {
  url: string;
  params?: Params;
}

export interface ErrorResponse {
  id: string;
  code: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class AxiosCallService {
  private axiosClient: AxiosInstance;
  private errorHandler: ErrorHandler;

  private apiUrl = environment.apiUrl;
  public baseUrl = environment.baseUrl;

  constructor(errorHandler: ErrorHandler) {
    this.errorHandler = errorHandler;

    // The ApiClient wraps calls to the underlying Axios client.
    this.axiosClient = axios.create({
      withCredentials: true,
      headers: {
        'X-Initialized-At': Date.now().toString(),
        Accept: 'application/json'
      }
    });
  }

  public login(params?: any) {
    return new Promise((resolve, reject) => {
      this.axiosClient.get(this.baseUrl + '/api/csrf-cookie').then((response) => {
        this.axiosClient
          .post(this.baseUrl + '/login', params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(response);
          });
      });
    });
  }

  public devLogin(params) {
    return new Promise((resolve, reject) => {
      this.axiosClient.get(this.baseUrl + '/api/csrf-cookie').then((response) => {
        this.axiosClient
          .post(this.baseUrl + '/api/dev/login', params)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(response);
          });
      });
    });
  }

  public async register(params?: any) {
    await this.axiosClient.get(this.baseUrl + '/api/csrf-cookie');
    return await this.axiosClient.post(this.baseUrl + '/register', params);
  }

  public getUser() {
    return this.axiosClient.get(`${this.baseUrl}/api/user`);
  }

  public getProcedures(orgId) {
    return this.axiosClient.get(`${this.apiUrl}organizations/${orgId}/procedures`);
  }
  public deleteProcedure(orgId, procedureId) {
    return this.axiosClient.get(`${this.apiUrl}organizations/${orgId}/procedures/${procedureId}`);
  }

  public getProcedureType(organization) {
    return this.axiosClient.get(this.baseUrl + `/api/organizations/${organization}}/proceduretypes`);
  }

  public getProcedureTypeById(organization, procedure) {
    return this.axiosClient.get(
      this.baseUrl + `/api/organizations/${organization}}/proceduretypes/${procedure}`
    );
  }

  public getProcedureTypeDetails(organization, procedure) {
    return this.axiosClient.get(
      this.baseUrl + `/api/organizations/${organization}}/proceduretypes/${procedure}`
    );
  }

  public api_call_post(link: string, params: any, multipart = false) {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    return this.axiosClient.post(this.apiUrl + link, params, multipart ? config : undefined);
  }

  public api_call_delete(link: string) {
    return this.axiosClient.delete(this.apiUrl + link);
  }

  public api_call_post_base(link: string, params: any) {
    return this.axiosClient.post(this.baseUrl + link, params);
  }

  public api_call_get(link, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();

      // tslint:disable-next-line: prefer-const tslint:disable-next-line: forin
      for (let k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }

    return this.axiosClient.get(this.apiUrl + link, reqOpts);
  }

  // end of test
  // Errors can occur for a variety of reasons. I normalize the error response so that
  // the calling context can assume a standard error structure.
  private normalizeError(error: any): ErrorResponse {
    this.errorHandler.handleError(error);

    return {
      id: '-1',
      code: 'UnknownError',
      message: 'An unexpected error occurred.'
    };
  }
}
