import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-ouverture',
  templateUrl: './ouverture.component.html',
  styleUrls: ['./ouverture.component.scss']
})
export class OuvertureComponent implements OnInit {
  // overturForm: FormGroup;
  public nameEn = '';
  public nameFr = '';
  public nameDe = '';
  public type: any = 'person';
  public options = ['Person', 'Text'];
  public canAddPerson = false

  constructor(
    private user: UserService,
    private dialogRef: MatDialogRef<OuvertureComponent>,
    public translate: TranslateService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void { }

  save(): void {
    if (!this.nameEn || !this.nameFr || !this.nameDe) {
      this.translate.get('nameEmpty').subscribe((res: string) => {
        this.toastr.error(res);
      });
    } else {
      let translations = [
        { language: 'en', name: this.nameEn, id: 0 },
        { language: 'fr', name: this.nameFr, id: 0 },
        { language: 'de', name: this.nameDe, id: 0 }
      ];

      let name = '';

      // Find the translation that matches the users language and set it as the primary name
      const currentLanguage = this.translate.currentLang ? this.translate.currentLang : 'fr';
      let primary = translations.find((translation) => translation.language == currentLanguage);
      name = primary.name;

      this.user.overtures.push({ id: 0, name, ouverture_type: this.type, multiple: this.canAddPerson, translations });
      this.dialogRef.close();
    }
  }
}
