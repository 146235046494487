<div>
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]> 
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="mb-3">
    <h2 translate>uploadFiles</h2>
  </div>
  <div class="mb-3">
    <ul class="list-unstyled">
      <li *ngFor="let file of data.files">
        {{ file.name }} ({{ file.size }} Kb)
      </li>
    </ul>
  </div>
  <div class="text-center">
    <button class="btn btn-secondary mr-3" (click)="onNoClick()" translate>cancel</button>
    <button class="btn btn-primary" (click)="data.confirm = true" [mat-dialog-close]="data" translate>
      upload
    </button>
  </div>
</div>