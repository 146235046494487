<div>
  <div class="header mb-4">
    <app-header></app-header>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2 col-xl-1 mb-3 mb-md-0">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-12 col-md-10 col-lg-10 col-xl-11">
        <nav aria-label="breadcrumb" class="mb-2">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/dossiers">
                <h2 class="m-0" translate>myFolders</h2>
              </a>
            </li>
            <li class="breadcrumb-item active p-0 d-flex" aria-current="page">
              <h2 *ngIf="procedure" class="m-0">{{ procedure.type.slug }}</h2>
            </li>
          </ol>
        </nav>
        <div class="row">
          <div
            class="d-lg-none col-12 col-sm-4"
            style="
              color: #63799b;
              font-weight: 500;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: small;
            "
            translate
          >
            <div (click)="openInfo()" translate>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24.788 24.788">
                <g id="Group_2285" data-name="Group 2285" transform="translate(-135.93 -155)">
                  <g id="Group_2247" data-name="Group 2247" transform="translate(135.93 155)">
                    <g id="Group_2246" data-name="Group 2246">
                      <path
                        id="Path_2213"
                        data-name="Path 2213"
                        d="M12.394,0A12.394,12.394,0,1,0,24.788,12.394,12.408,12.408,0,0,0,12.394,0ZM14.46,20.14a.516.516,0,0,1-.516.516h-3.1a.516.516,0,0,1-.516-.516V12.394H9.812a.516.516,0,0,1-.516-.516V9.812A.516.516,0,0,1,9.812,9.3h4.131a.516.516,0,0,1,.516.516ZM12.394,8.263A2.066,2.066,0,1,1,14.46,6.2,2.068,2.068,0,0,1,12.394,8.263Z"
                        fill="#63799b"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              &nbsp; viewProcedure
            </div>
          </div>
          <div
            class="d-lg-none col-12 col-sm-8 justify-content-start justify-content-sm-end"
            style="
              color: #171d20;
              font-weight: 500;
              cursor: pointer;
              display: flex;
              align-items: center;
              font-size: large;
            "
          >
            {{ procedure?.name }}
          </div>
        </div>

        <div class="row mb-3">
          <div
            class="d-none d-lg-flex col-lg-3 col-xl-2"
            style="
              color: #63799b;
              font-weight: 500;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: small;
            "
            translate
          >
            <div (click)="openInfo()" translate>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24.788 24.788">
                <g id="Group_2285" data-name="Group 2285" transform="translate(-135.93 -155)">
                  <g id="Group_2247" data-name="Group 2247" transform="translate(135.93 155)">
                    <g id="Group_2246" data-name="Group 2246">
                      <path
                        id="Path_2213"
                        data-name="Path 2213"
                        d="M12.394,0A12.394,12.394,0,1,0,24.788,12.394,12.408,12.408,0,0,0,12.394,0ZM14.46,20.14a.516.516,0,0,1-.516.516h-3.1a.516.516,0,0,1-.516-.516V12.394H9.812a.516.516,0,0,1-.516-.516V9.812A.516.516,0,0,1,9.812,9.3h4.131a.516.516,0,0,1,.516.516ZM12.394,8.263A2.066,2.066,0,1,1,14.46,6.2,2.068,2.068,0,0,1,12.394,8.263Z"
                        fill="#63799b"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              &nbsp; viewProcedure
            </div>
          </div>
          <div
            class="d-none d-lg-flex col-lg-3 col-xl-5"
            style="
              color: #171d20;
              font-weight: 500;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: large;
            "
          >
            <div translate
              style="
                color: #63799b;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: small;
              "
            >
              fileRef
            </div>
            &nbsp;{{ procedure?.name }}&nbsp;|&nbsp;
            <div translate
              style="
                color: #63799b;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: small;
              "
            >
              fileNo
            </div>
            &nbsp;{{ procedure?.number }}
          </div>

          <div class="col-12 col-lg-6 col-xl-5" *ngIf="procedure">
            <div class="row">
              <div
                class="
                  d-none d-sm-flex
                  col-sm-3 col-md-2 col-lg-3 col-xl-3
                  justify-content-sm-start justify-content-lg-end
                  center-stuff
                "
              >
                <button *ngIf="hasPerson" (click)="viewContacts()" class="btn btn-primary" translate>
                  contacts
                </button>
              </div>

              <div
                class="
                  col-7 col-sm-6 col-md-7 col-lg-5 col-xl-5
                  justify-content-start justify-content-sm-end
                  center-stuff
                "
              >
                <p
                  class="greenCircle m-0 mr-1"
                  [ngStyle]="{
                    'background-color': procedure.active ? '#00C94D' : '#FF2919'
                  }"
                ></p>
                <p class="m-0" [ngStyle]="procedure.active ? { color: '#00C94D' } : { color: '#FF2919' }">
                  {{ procedure.active ? ("inProgress" | translate) : ("finished" | translate) }}&nbsp;
                </p>
                <p class="m-0 mx-1 font-weight-light" style="font-size: xx-large; color: #707070">|</p>
              </div>

              <div class="col-5 col-sm-3 col-md-3 col-lg-4 col-xl-4 avatar-stack center-stuff">
                <img
                  *ngFor="let user of procedure.groups[0].users | slice: 0:3"
                  class="avatar shadow"
                  src="{{ baseUrl + user.profile_pic }}"
                  onerror="this.src='../../assets/images/default-profile-pic.png'"
                  [title]="user.firstname + ' ' + user.lastname + ' ' + '(' + user.email + ')'"
                  routerlink="/profile"
                />
                <ng-container *ngIf="procedure.groups[0].users.length > 3">
                  <div (click)="openOthers(procedure.groups[0].users)" class="circle shadow">
                    + {{ procedure.groups[0].users.length - 3 }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="d-sm-none col-12 center-stuff justify-content-center">
            <button *ngIf="hasPerson" (click)="viewContacts()" class="btn btn-primary" translate>
              contacts
            </button>
          </div>
        </div>

        <div class="card shadow bg-white" style="border-radius: 10px; border-style: none">
          <div class="card-body p-0">
            <div class="row no-gutters" style="border: 10px; border-style: none">
              <div class="col-12 col-lg-4">
                <div
                  class="roundedLeft p-3 pl-4"
                  style="background-color: #d3ddea; overflow-y: scroll; height: 100%"
                >
                  <div *ngIf="procedure">
                    <div *ngFor="let step of allSteps; let i = index" class="m-3">
                      <div class="card" (click)="onChangeStep(i)">
                        <div
                          class="row card-body body rounded"
                          [ngClass]="returnColors(i, step)"
                          style="cursor: pointer"
                        >
                          <div class="col-12" style="display: flex; margin-left: -15px">
                            <div class="circleNumber" [ngClass]="returnNumberColors(i, step)">
                              {{ i + 1 }}
                            </div>
                            <div style="display: flex; align-items: center">
                              <h4 class="m-0" style="cursor: pointer">
                                {{ state.returnTranslation(step.translations, "name") }}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-8">
                <div class="roundedRight">
                  <div id="content">
                    <div
                      *ngIf="procedure"
                      id="text"
                      [innerHTML]="state.returnTranslation(currentStep.translations, 'text')"
                    ></div>

                    <div [ngSwitch]="userLanguage">
                      <span *ngSwitchCase="'fr'">
                        <a
                          [id]="'link-' + index"
                          class="text-muted mr-1"
                          [href]="link.url_fr"
                          target="_blank"
                          *ngFor="let link of linksFr; let index = index"
                          ><u>{{ link.label_fr }}{{ index === linksFr.length - 1 ? "" : "," }}</u></a
                        >
                      </span>
                      <span *ngSwitchCase="'de'">
                        <a
                          [id]="'link-' + index"
                          class="text-muted mr-1"
                          [href]="link.url_de"
                          target="_blank"
                          *ngFor="let link of linksDe; let index = index"
                          ><u>{{ link.label_de }}{{ index === linksDe.length - 1 ? "" : "," }}</u></a
                        >
                      </span>
                      <span *ngSwitchCase="'en'">
                        <a
                          [id]="'link-' + index"
                          class="text-muted mr-1"
                          [href]="link.url_en"
                          target="_blank"
                          *ngFor="let link of linksEn; let index = index"
                          ><u>{{ link.label_en }}{{ index === linksEn.length - 1 ? "" : "," }}</u></a
                        >
                      </span>
                      <span *ngSwitchDefault></span>
                    </div>
                  </div>
                  <div id="docs" class="mt-2">
                    <p class="lead mb-2" *ngFor="let doc of returnDocs(currentStep?.docs)">
                      <span (click)="openWordFileDialog(doc)" style="cursor: pointer">
                        <span class="badge badge-primary p-1 mr-1">{{ doc.extension | uppercase }}</span>
                        <span>{{ doc.name }}.{{ doc.extension }}</span>
                      </span>
                      <!-- <a [href]="baseUrl + '/' + docs.path" target="_blank" download
                        >{{ docs.name }}.{{ docs.extension }}</a
                      > -->
                    </p>

                    <!-- <p class="lead mb-2" *ngFor="let docs of returnDocs(currentStep?.docs)">
                      <span class="badge badge-primary p-1 mr-1">{{ docs.extension | uppercase }}</span>
                      <a [href]="baseUrl + '/' + docs.path" target="_blank" download
                        >{{ docs.name }}.{{ docs.extension }}</a
                      >
                    </p> -->
                  </div>
                  <!-- <button type="button" (click)="openWordFileDialog()">test</button> -->
                  <div id="actions">
                    <div class="form-group">
                      <label for="remarques" style="color: #707070" translate>notes</label>

                      <div
                        id="remarks"
                        class="d-flex flex-wrap align-items-center"
                        *ngIf="currentStep.data && currentStep.data && currentStep.data.length > 0"
                      >
                        <ng-container *ngFor="let remark of currentStep.data; let index = index">
                          <span
                            [id]="'remark-' + index"
                            class="remark p-1 px-2 mr-2 mb-2"
                            *ngIf="remark.text_value != null"
                          >
                            {{ remark.text_value }}
                            <button
                              type="button"
                              class="close ml-2"
                              aria-label="Close"
                              (click)="deleteRemark(remark.id)"
                              style="box-sizing: border-box; color: #545871"
                            >
                              <span aria-hidden="true" id="close-button">&times;</span>
                            </button>
                          </span>
                        </ng-container>
                      </div>

                      <textarea
                        [disabled]="currentStep.validated || currentStep.skipped"
                        class="form-control"
                        id="remarques"
                        rows="3"
                        [(ngModel)]="stepData"
                      >
                      </textarea>
                      <div class="text-right">
                        <button
                          [disabled]="stepData?.length < 1"
                          class="btn btn-primary rounded-pill mt-2 action-button"
                          (click)="addRemark()"
                          translate
                        >
                          addRemark
                        </button>
                      </div>
                    </div>

                    <div
                      id="uploadedDocs"
                      *ngIf="currentStep.data && currentStep.data && currentStep.data.length > 0"
                    >
                      <p style="color: #707070" translate>uploadDocs</p>
                      <ng-container *ngFor="let remark of currentStep.data; let index = index">
                        <ng-container *ngIf="remark.media.length">
                          <p class="lead mb-2" *ngFor="let docs of remark.media">
                            <span class="badge badge-primary p-1 mr-1">
                              {{ docs.path.split(".").pop() | uppercase }}</span
                            >
                            <a [href]="baseUrl + docs.path" target="_blank" download
                              >{{ docs.name }}.{{ docs.path.split(".").pop() }}</a
                            >
                            <span
                              (click)="deleteDocument(docs.procedure_data_id, docs.id)"
                              class="text-danger ml-2"
                              style="cursor: pointer"
                              aria-hidden="false"
                              id="close-button"
                              >&times;</span
                            >
                          </p>
                        </ng-container>
                      </ng-container>
                    </div>

                    <ngx-file-drag-drop
                      #dragDrop
                      [disabled]="currentStep.validated || currentStep.skipped"
                      [formControl]="fileControl"
                      multiple
                      emptyPlaceholder="{{ 'uploadFiles' | translate }}"
                      (valueChanged)="onValueChange($event)"
                      style="border-color: #2c77ff; max-width: 1400px"
                      [accept]="acceptedExt"
                    >
                    </ngx-file-drag-drop>

                    <div class="row py-3">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-1 text-center p-1">
                        <button
                          *ngIf="currentStep.validated || currentStep.skipped"
                          class="btn btn-primary rounded-pill mr-2 action-button"
                          (click)="reopenStep()"
                          translate
                        >
                          reopenStep
                        </button>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-1 text-center p-1">
                        <button
                          [disabled]="currentStep.validated || currentStep.skipped"
                          *ngIf="currentStep.canskip"
                          class="btn btn-secondary rounded-pill action-button"
                          style="background-color: #c4c4c4"
                          (click)="skip()"
                          translate
                        >
                          notRequired
                        </button>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-1 text-center p-1">
                        <button
                          [disabled]="currentStep.validated || currentStep.skipped"
                          *ngIf="currentStep.canwaiting"
                          class="btn btn-warning rounded-pill text-light action-button"
                          style="background-color: #feba00"
                          (click)="openReminderDialog()"
                          translate
                        >
                          makeReminder
                        </button>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 p-1 text-center p-1">
                        <button
                          [disabled]="currentStep.validated || currentStep.skipped"
                          class="btn btn-success rounded-pill action-button"
                          style="background-color: #06cb75"
                          (click)="validate()"
                          translate
                        >
                          validate
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
