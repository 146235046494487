import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-add-link',
  templateUrl: './add-link.component.html',
  styleUrls: ['./add-link.component.scss']
})
export class AddLinkComponent implements OnInit {
  public labelEn = '';
  public labelFr = '';
  public labelDe = '';
  public linkUrlEn = '';
  public linkUrlFr = '';
  public linkUrlDe = '';
  private pattern = new RegExp('^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  constructor(
    private user: UserService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private dialogRef: MatDialogRef<AddLinkComponent>
  ) { }

  ngOnInit(): void { }

  save(): void {

    if (!this.user.links[this.user.currentLinkInd]) {
      this.user.links[this.user.currentLinkInd] = [];
    }

    // Invalid UrlEn error
    if (this.labelEn && this.linkUrlEn) {
      if (!this.pattern.test(this.linkUrlEn)) {
        this.translate.get('invalidLink').subscribe((res: string) => {
          this.toastr.error(res);
        });
        return;
      }
    }

    // Invalid UrlDe error
    if (this.labelDe && this.linkUrlDe) {
      if (!this.pattern.test(this.linkUrlDe)) {
        this.translate.get('invalidLink').subscribe((res: string) => {
          this.toastr.error(res);
        });
        return;
      }
    }

    // Invalid UrlFr error
    if (this.labelFr && this.linkUrlFr) {
      if (!this.pattern.test(this.linkUrlFr)) {
        this.translate.get('invalidLink').subscribe((res: string) => {
          this.toastr.error(res);
        });
        return;
      }
    }

    // ka label en, ska link en
    if (this.labelEn && !this.linkUrlEn) {
      this.translate.get('noUrl').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }

    // ska label en, ka link en
    else if (!this.labelEn && this.linkUrlEn) {
      this.translate.get('noLabel').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }

    // ka label Fr, ska link Fr
    else if (this.labelFr && !this.linkUrlFr) {
      this.translate.get('noUrl').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }

    // ska label Fr, ka link Fr
    else if (!this.labelFr && this.linkUrlFr) {
      this.translate.get('noLabel').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }

    // ka label De, ska link De
    else if (this.labelDe && !this.linkUrlDe) {
      this.translate.get('noUrl').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }

    // ska label De, ka link De
    else if (!this.labelDe && this.linkUrlDe) {
      this.translate.get('noLabel').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return
    }


    // nese gjitha inputet jane  empty
    if (!this.labelEn && !this.labelFr && !this.labelDe && !this.linkUrlEn && !this.linkUrlFr && !this.linkUrlDe) {
      this.translate.get('invalidForm').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return;
    }


    else {
      let translations = [
        { language: 'en', label: this.labelEn },
        { language: 'fr', label: this.labelFr },
        { language: 'de', label: this.labelDe }
      ]
      let label = '';

      // Find the translation that matches the users language and set it as the primary name
      const currentLanguage = this.translate.currentLang ? this.translate.currentLang : 'fr';
      let primary = translations.find((translation) => translation.language == currentLanguage);

      // ka link vetem ne en
      if (!this.labelFr && !this.labelDe) {
        label = this.labelEn
      }
      // ka link vetem ne fr
      else if (!this.labelEn && !this.labelDe) {
        label = this.labelFr
      }
      // ka link vetem ne de
      else if (!this.labelEn && !this.labelFr) {
        label = this.labelDe
      }

      // nese nuk ka link ne gjuhen e perzgjedhur
      else if (currentLanguage === 'fr' && !this.labelFr) {
        label = this.labelEn
      }
      else if (currentLanguage === 'de' && !this.labelDe) {
        label = this.labelEn
      }
      else if (currentLanguage === 'en' && !this.labelEn) {
        if (this.labelFr) {
          label = this.labelFr
        }
        else {
          label = this.labelDe
        }
      }
      else {
        label = primary.label;
      }
      this.user.links[this.user.currentLinkInd].push({
        label_en: this.labelEn,
        label_fr: this.labelFr,
        label_de: this.labelDe,
        url_en: this.linkUrlEn,
        url_fr: this.linkUrlFr,
        url_de: this.linkUrlDe,
        label
      });
      this.dialogRef.close();
    }
  }
  checkUrl() {
    return this.pattern.test(this.linkUrlEn || this.linkUrlFr || this.linkUrlDe) ? 'form-control' : 'form-control linkUrl-invalid';
  }
}
