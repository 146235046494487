<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="label" style="color: #707070; font-weight: lighter" translate>groupName</label>
    <input type="text" class="form-control" id="label" [(ngModel)]="data.name" />
  </div>
  <div class="mb-3">
    <label for="description" style="color: #707070; font-weight: lighter" translate>groupDesc</label>
    <input type="text" class="form-control" id="description" [(ngModel)]="data.description" />
  </div>
  <div class="mb-3">
    <label for="place" style="color: #707070; font-weight: lighter" translate>groupPlace</label>
    <input type="text" class="form-control" id="place" [(ngModel)]="data.place" />
  </div>
  <div class="mb-3">
    <label for="po-box" style="color: #707070; font-weight: lighter" translate>groupAddress</label>
    <input type="text" class="form-control" id="po-box" [(ngModel)]="data.po_box" />
  </div>
  <div class="mb-3">
    <label for="zip" style="color: #707070; font-weight: lighter" translate>groupPostalCode</label>
    <input type="text" class="form-control" id="zip" [(ngModel)]="data.zip" />
  </div>
  <div class="mb-3">
    <label for="email" style="color: #707070; font-weight: lighter" translate>groupEmail</label>
    <input type="email" class="form-control" id="email" [(ngModel)]="data.email" />
  </div>
  <div class="mb-3">
    <label for="website" style="color: #707070; font-weight: lighter" translate>groupWeb</label>
    <input type="url" class="form-control" id="website" [(ngModel)]="data.web" />
  </div>
  <div class="mb-3">
    <label for="phone" style="color: #707070; font-weight: lighter" translate>groupPhone</label>
    <input type="tel" class="form-control" id="phone" [(ngModel)]="data.phone" />
  </div>

  <div class="text-center">
    <button class="btn btn-secondary rounded-pill mr-3" (click)="onNoClick()" translate>
      cancel
    </button>
    <button class="btn btn-primary rounded-pill" [mat-dialog-close]="data" translate>
      save
    </button>
  </div>
</div>
