import { AreYouSureComponent } from './../are-you-sure/are-you-sure.component';
import { Router } from '@angular/router';
import { AxiosCallService } from './../../services/axios-call.service';
import { Component, ElementRef, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmUploadComponent } from '../confirm-upload/confirm-upload.component';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { last } from 'rxjs/operators';
import { UsersAccessComponent } from '../users-access/users-access.component';
import { ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { EditIdentifierComponent } from '../edit-identifier/edit-identifier.component';
import { AttenteComponent } from '../attente/attente.component';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  remindersSearch;
  docSearch;
  foldersSearch;
  reminders = [];
  dossiers = [];
  allSteps = [];
  unValidatedSteps = [];
  validatedSteps = [];
  firstUnvalidStepIndex = 0;
  lastValidStepIndex = 0;
  dirtySteps = [];
  cleanSteps = [];
  lastDirtyStepIndex = 0;
  firstDirtyStepIndex = 0;
  lastCleanStepIndex = 0;
  firstCleanStepIndex = 0;
  documents = [];
  baseUrl = null;
  orgId = null;
  isAdmin: Boolean = false;
  loaded: Boolean = false;
  userLanguage: string = 'fr';
  docId = null;
  folderId = null;
  tempReminders;
  currentStep = null;
  userId = null;
  testReminders = [];
  user = null;

  @ViewChild('myInput') myInputVariable: ElementRef;

  constructor(
    private axiosService: AxiosCallService,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    public state: StateService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public datePipe: DatePipe
  ) {}

  refreshData() {
    // this.userService.loaderActivate.next(true);
    this.state.user.subscribe((result) => {
      if (result.id) {
        if (!result.organization_id) {
          return this.router.navigate(['/organizations']);
        }
        this.user = result;
        result.roles.length ? (this.isAdmin = true) : (this.isAdmin = false);

        this.getSharedDocuments(this.user.organization_id);
        this.refreshLanguageDocuments();
        this.getDossiers(this.user.id, this.user.organization_id);
        this.getReminders();
      }
    });
  }

  ngOnInit(): void {
    this.userService.loaderActivate.next(true);
    this.baseUrl = this.axiosService.baseUrl;
    this.refreshData();
  }

  // *** REMINDERS SECTION START

  getReminders() {
    this.axiosService
      .api_call_get('user/reminders')
      .then((res) => {
        this.reminders = res.data.data.map((element) => {
          return {
            id: element.id,
            dossier: element.procedure.name,
            tache: element.procedure.slug,
            delai: element.date,
            procedure: {
              id: element.procedure.id
            }
          };
        });
      })
      .catch((error) => (error.response ? console.log(error.response) : console.log(error)));
  }

  /*   getReminders() {
      this.axiosService
        .api_call_get(`organizations/${this.user.organization_id}/reminders`)
        .then((reminders: any) => {
          this.filterReminders(reminders);
          this.reminders = this.reminders.map((element) => {
            return {
              dossier: element.procedure.name,
              tache: element.procedure.slug,
              delai: element.date,
              procedure: {
                id: element.procedure.id
              }
            };
          });
        })
        .catch((error) => error.response ? console.log(error.response) : console.log(error));
    } */

  /* filterReminders(reminders) {
    // Filter user reminders
    const sortedReminders = _.orderBy(reminders.data.data, 'date', 'desc');
    this.reminders = sortedReminders.filter((reminder) => {
      if (this.dossiers.find((dossier) => reminder.procedure && reminder.procedure.id === dossier.id)) {
        return true;
      }
    });
  } */

  editReminder(id, date) {
    date = new Date(date);
    date = this.datePipe.transform(date, 'y-MM-d');
    const dialogRef = this.dialog.open(AttenteComponent, {
      data: {
        date: date,
        edit: true
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.date) {
        this.updateReminder(id, result.date)
          .then((res) => {
            this.reminders.map((reminder) => {
              if (reminder.id === id) {
                reminder.delai = res.data.data.date;
              }
            });
          })
          .catch((error) => console.error(error));
      }
    });
  }

  updateReminder(id, date) {
    return this.axiosService.api_call_post(
      `organizations/${this.user.organization_id}/reminders/${id}?_method=PATCH`,
      { date: date }
    );
  }

  // *** REMINDERS SECTION END

  // *** DOCUMENTS SECTION START
  getSharedDocuments(orgId) {
    // this.userService.loaderActivate.next(true);
    this.axiosService
      .api_call_get(`organizations/${orgId}/shared`)
      .then((response) => {
        let data = _.orderBy(response.data.data, 'id', 'desc');
        data.forEach((element) => {
          this.docId = element.id;
          this.folderId = element.document_folder_id;
        });
        this.documents = data
          .filter((doc) => doc.language == this.userLanguage)
          .map((doc) => {
            return {
              name: doc.name,
              extension: doc.extension,
              path: doc.path,
              language: doc.language,
              id: doc.id,
              folderId: doc.document_folder_id
            };
          });
        // this.userService.loaderActivate.next(false);
      })
      .catch((error) => (error.response ? console.log(error.response) : console.log(error)));
  }

  openBrowser() {
    const element: HTMLElement = document.querySelector('#dashboardFile');
    element.click();
  }

  handleFiles(event) {
    const files = event.target.files;

    const dialogRef = this.dialog.open(ConfirmUploadComponent, {
      data: {
        files: files,
        confirm: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          formData.append('files[]', files[i]);
        }
        const currentLanguage = this.translate.currentLang ? this.translate.currentLang : 'fr';
        formData.append('language', currentLanguage);

        // this.userService.loaderActivate.next(true);
        // Send request
        this.axiosService
          .api_call_post(`organizations/${this.user.organization_id}/shared`, formData)
          .then((response) => {
            this.translate.get('uploadedSuccessfully').subscribe((res: string) => {
              this.toastr.success(res);
            });
            this.getSharedDocuments(this.user.organization_id);
            // this.userService.loaderActivate.next(false);
          })
          .catch((error) => {
            if (error.response) {
              this.userService.loaderActivate.next(false);
              console.log(error.response);
              this.translate.get('invalidData').subscribe((res: string) => {
                this.toastr.error(res);
              });
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
              this.userService.loaderActivate.next(false);
            }
          });
      }

      this.myInputVariable.nativeElement.value = '';
    });
  }

  dangerDialogDocument(folderId, docId) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        folder_id: folderId,
        document_id: docId
      }
    });

    dialogRef.afterClosed().subscribe((document) => {
      if (document) this.deleteDoc(document.folder_id, document.document_id);
    });
  }

  deleteDoc(folderId, docId) {
    // this.userService.loaderActivate.next(true);
    this.axiosService
      .api_call_delete(`organizations/${this.user.organization_id}/folders/${folderId}/documents/${docId}`)
      .then((res) => {
        this.translate.get('deletedDoc').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getSharedDocuments(this.user.organization_id);
        // this.userService.loaderActivate.next(false);
      })
      .catch((error) => {
        if (error.response) {
          this.userService.loaderActivate.next(false);
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
          this.userService.loaderActivate.next(false);
        }
      });
  }

  refreshLanguageDocuments() {
    /*
     * Document Language Filtering
     */
    this.userLanguage = this.translate.currentLang;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.userLanguage = event.lang;
      this.getSharedDocuments(this.user.organization_id);
    });
  }
  // *** DOCUMENTS SECTION END

  // *** DOSSIERS SECTION START
  getDossiers(userId, orgId) {
    // this.userService.loaderActivate.next(true);
    this.axiosService
      .api_call_get(`organizations/${orgId}/procedures`)
      .then((procedures: any) => this.storeSteps(this.filterDossiers(procedures, userId)))
      .catch((error) => (error.response ? console.log(error.response) : console.log(error)))
      .finally(() => this.userService.loaderActivate.next(false));
  }

  filterDossiers(procedures, userId) {
    // Filter procedures that user has access to
    const sortedProcedures = _.orderBy(procedures.data.data, 'created_at', 'desc');

    return sortedProcedures.filter((procedure) => {
      if (!procedure.deleted_by && procedure.groups[0].users.find((user) => user.id == userId)) {
        return true;
      }
    });
  }

  storeSteps(myProcedures) {
    this.dossiers = myProcedures.map((procedure) => {
      // store all steps
      this.allSteps = procedure.steps.filter((step) => !step.is_ouverture);

      // store steps that are dirty, ex: validated or skipped or waiting
      this.dirtySteps = this.allSteps.filter((step) => step.validated || step.skipped || step.waiting);

      // store steps that are clean
      this.cleanSteps = this.allSteps.filter((step) => !step.validated && !step.skipped && !step.waiting);

      if (this.dirtySteps.length) {
        // store first index of dirty steps
        this.firstDirtyStepIndex = this.allSteps.findIndex((step) => step.id === this.dirtySteps[0].id);
        // store last index of dirty steps
        this.lastDirtyStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.dirtySteps).id);
      }

      if (this.cleanSteps.length) {
        // store first index of clean steps
        this.firstCleanStepIndex = this.allSteps.findIndex((step) => step.id === this.cleanSteps[0].id);
        // store last index of clean steps
        this.lastCleanStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.cleanSteps).id);
      }

      // nese skemi prek asgje
      if (this.dirtySteps.length < 1) {
        this.currentStep = this.allSteps[0].translations;
      }

      // nese i kemi prek gjitha
      else if (this.cleanSteps.length < 1) {
        this.currentStep = this.allSteps[_.findLastIndex(this.allSteps)].translations;
      }

      // nese kemi prek disa
      else {
        this.currentStep = this.allSteps[this.firstCleanStepIndex].translations;
      }

      return {
        id: procedure.id,
        nomPrenom: procedure.name,
        dossierNumber: procedure.number,
        procedure: procedure.type.slug,
        etat: this.state.returnTranslation(this.currentStep, 'name'),
        statut: procedure.active,
        users: procedure.groups[0].users,
        created: procedure.created_at
      };
    });
  }

  goToProcedure(id) {
    this.router.navigate(['/procedure', id]);
  }

  openOthers(users) {
    this.dialog.open(UsersAccessComponent, {
      data: {
        users
      }
    });
  }

  dangerDialogProcedure(id) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        procedure_id: id
      }
    });

    dialogRef.afterClosed().subscribe((procedure) => {
      if (procedure) this.deleteProcedure(procedure.procedure_id);
    });
  }

  deleteProcedure(id) {
    this.axiosService
      .api_call_delete(`organizations/${this.user.organization_id}/procedures/${id}`)
      .then((res) => {
        this.translate.get('deletedProc').subscribe((res: string) => {
          this.toastr.success(res);
        });
        this.getDossiers(this.user.id, this.user.organization_id);
        this.getReminders();
      })
      .catch((error) => {
        if (error.response) {
          this.userService.loaderActivate.next(false);
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
          this.userService.loaderActivate.next(false);
        }
      });
  }

  editProcedure(id) {
    const procedure = this.dossiers.find((procedure) => procedure.id === id);
    const dialogRef = this.dialog.open(EditIdentifierComponent, {
      data: {
        name: procedure.nomPrenom
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.name) {
        let params = {
          name: result.name
        };

        if (!params.name) delete params.name;
        this.axiosService
          .api_call_post(`organizations/${this.user.organization_id}/procedures/${id}?_method=PATCH`, params)
          .then((res) => {
            this.translate.get('procedureUpdated').subscribe((res: string) => {
              this.toastr.success(res);
            });
            this.getDossiers(this.user.id, this.user.organization_id);
            this.getReminders();
          })
          .catch((error) => {
            if (error.response) {
              this.userService.loaderActivate.next(false);
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
              this.userService.loaderActivate.next(false);
            }
          });
      }
    });
  }
  // *** DOSSIERS SECTION END
}
