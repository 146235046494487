import { AreYouSureComponent } from './../are-you-sure/are-you-sure.component';
import { TimelineComponent } from './../timeline/timeline.component';
import { Canvas27Component } from './../canvas27/canvas27.component';
import { AttenteComponent } from './../attente/attente.component';
import { MatDialog } from '@angular/material/dialog';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { UserService } from 'src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from 'src/app/services/state.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UsersAccessComponent } from '../users-access/users-access.component';
import { FileValidators, NgxFileDragDropComponent } from 'ngx-file-drag-drop';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ContactListComponent } from '../contact-list/contact-list.component';
import { AxiosResponse } from 'axios';
import { WordFileDialogComponent } from '../word-file-dialog/word-file-dialog.component';

interface Organization {
  id: Number;
  users: Array<any>;
  groups: Array<any>;
}

@Component({
  selector: 'app-canvas8',
  templateUrl: './canvas8.component.html',
  styleUrls: ['./canvas8.component.scss']
})
export class Canvas8Component implements OnInit {
  @ViewChild('dragDrop') dragDrop: NgxFileDragDropComponent;
  user = null;
  organization = null;
  baseUrl = null;
  currentStep: any = { text: '' };
  procId = '';
  stepData: string = '';
  orgId: any;
  ind = 0;
  procedure = null;
  stepFiles: Array<any> = [];
  fileControl = new FormControl([], [FileValidators.required, FileValidators.maxFileCount(10)]);
  userLanguage: string = '';
  mandatorySteps = [];
  allSteps = [];
  unValidatedSteps = [];
  validatedSteps = [];
  skippedSteps = [];
  dirtySteps = [];
  cleanSteps = [];
  lastDirtyStepIndex = 0;
  firstDirtyStepIndex = 0;
  lastCleanStepIndex = 0;
  firstCleanStepIndex = 0;
  lastSkippedStepIndex = 0;
  firstUnvalidStepIndex = 0;
  lastValidStepIndex = 0;
  acceptedExt = [
    '.pdf',
    '.docx',
    '.docm',
    '.dotx',
    '.dotm',
    '.docb',
    // '.jpg',
    // '.pjpeg',
    // '.gif',
    // '.svg',
    // '.png',
    // '.ico',
    // '.json',
    '.ppt',
    '.pptx',
    '.rtf',
    '.txt',
    '.xls',
    '.xlsx',
    '.csv'
  ];
  linksEn = [];
  linksDe = [];
  linksFr = [];
  ouvertureStepsMultiplePerson = [];
  ouvertureStepsSinglePerson = [];
  ouvertureSteps = [];
  hasPerson = false;

  constructor(
    private axiosCallService: AxiosCallService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    public state: StateService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    this.baseUrl = axiosCallService.baseUrl;
  }

  ngOnInit(): void {
    this.userService.loaderActivate.next(true);
    this.procId = this.route.snapshot.paramMap.get('id');
    this.refreshData();
    setTimeout(() => {
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.userService.loaderActivate.next(true);
        this.userLanguage = event.lang;
        this.getProcedure(this.user.organization_id, this.procId);
      });
    }, 1);
    this.setUserLanguage();
    this.setStepFiles();
  }

  setUserLanguage() {
    this.userLanguage = this.translate.currentLang;
  }

  setStepFiles() {
    this.fileControl.valueChanges.subscribe((files: File[]) => {
      this.stepFiles = files;
    });
  }

  setLinks() {
    this.linksEn = this.currentStep.links.filter((link) => link.label_en);
    this.linksDe = this.currentStep.links.filter((link) => link.label_de);
    this.linksFr = this.currentStep.links.filter((link) => link.label_fr);
  }

  stepOnFocus() {
    // nese skemi prek asgje
    if (this.dirtySteps.length < 1) {
      this.switchStep(0);
    }
    // nese i kemi prek gjitha
    else if (this.cleanSteps.length < 1) {
      this.switchStep(_.findLastIndex(this.allSteps));
    }
    // nese kemi prek disa
    else {
      this.switchStep(this.firstCleanStepIndex);
    }
  }

  refreshSteps() {
    // store mandatory steps
    this.mandatorySteps = this.allSteps.filter((step_canskip) => !step_canskip.skipped);

    // store steps that are not validated
    this.unValidatedSteps = this.allSteps.filter((step) => !step.validated);

    // store steps that are validated
    this.validatedSteps = this.allSteps.filter((step) => step.validated);

    // store steps that are dirty, ex: validated or skipped or waiting
    this.dirtySteps = this.allSteps.filter((step) => step.validated || step.skipped || step.waiting);

    // store steps that are clean
    this.cleanSteps = this.allSteps.filter((step) => !step.validated && !step.skipped && !step.waiting);

    if (this.dirtySteps.length) {
      // store first index of dirty steps
      this.firstDirtyStepIndex = this.allSteps.findIndex((step) => step.id === this.dirtySteps[0].id);
      // store last index of dirty steps
      this.lastDirtyStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.dirtySteps).id);
    }
    if (this.cleanSteps.length) {
      // store first index of clean steps
      this.firstCleanStepIndex = this.allSteps.findIndex((step) => step.id === this.cleanSteps[0].id);
      // store last index of clean steps
      this.lastCleanStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.cleanSteps).id);
    }

    if (this.validatedSteps.length) {
      // store last index of validated steps
      this.lastValidStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.validatedSteps).id);
    }

    if (this.unValidatedSteps.length) {
      // store first index of unvalidated steps
      this.firstUnvalidStepIndex = this.allSteps.findIndex((step) => step.id === this.unValidatedSteps[0].id);
    }
  }

  refreshData() {
    this.state.user.subscribe((result) => {
      if (result.id) {
        this.user = result;
        this.getProcedure(this.user.organization_id, this.procId);
      }
    });
  }

  getProcedure(orgId, procId) {
    this.userService.loaderActivate.next(true);
    this.apiGetProcedure(orgId, procId)
      .then((procedure) => {
        console.log('this.user', this.user);
        if (
          procedure.data.data.deleted_by ||
          !this.user.groups.find((group) => group.id === procedure.data.data.groups[0].id)
        ) {
          this.translate.get('noAcces').subscribe((res: string) => {
            this.toastr.error(res);
          });
          this.router.navigate(['/dossiers']);
        }
        this.procedure = procedure.data.data;

        // store persons that are multiple
        this.ouvertureStepsMultiplePerson = this.procedure.steps.filter(
          (step) => step.is_ouverture && step.ouverture_type === 'person' && step.multiple
        );

        // store persons that are single
        this.ouvertureStepsSinglePerson = this.procedure.steps.filter(
          (step) => step.is_ouverture && step.ouverture_type === 'person' && !step.multiple
        );

        // set person check
        if (this.ouvertureStepsMultiplePerson.length || this.ouvertureStepsSinglePerson.length) {
          this.hasPerson = true;
        } else {
          this.hasPerson = false;
        }

        // get unique person steps
        this.ouvertureSteps = _.uniqBy(this.ouvertureStepsMultiplePerson, 'name');

        // store all steps that are not ouverture
        this.allSteps = this.procedure.steps.filter((step) => !step.is_ouverture);

        // refresh
        this.refreshSteps();
        this.stepOnFocus();
        this.setLinks();

        this.organization = { id: procedure.data.data.organization_id }; //?
        // this.userService.loaderActivate.next(false);
      })
      .catch((error) => {
        if (error.response) {
          // this.userService.loaderActivate.next(false);
          console.log(error.response);
          if (error.response.status == 404) {
            this.router.navigate(['/dossiers']);
            this.translate.get('noAcces').subscribe((res: string) => {
              this.toastr.error(res);
            });
          } else this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
          // this.userService.loaderActivate.next(false);
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  switchStep(i) {
    this.currentStep = this.allSteps[i];
    this.ind = this.allSteps.indexOf(this.currentStep);
    this.stepData = '';
    this.dragDrop.files.length > 0 ? this.dragDrop.clear() : null;
    this.setLinks();
  }

  onChangeStep(i) {
    //  nese shtyp stepin e njejt
    if (i === this.ind) {
      return;
    }
    // nese shtyp step tjeter
    else {
      // nese ai step eshte i validuar/skipped/waiting
      if (this.allSteps[i].validated || this.allSteps[i].skipped || this.allSteps[i].waiting) {
        this.switchStep(i);
        return;
      }
      // nese ai step ku shkojme eshte pa-prek
      else {
        // nese eshte stepi i pare clean
        if (i === this.firstCleanStepIndex) {
          this.switchStep(i);
          return;
        }
        // nese nuk ka step paraprak te validuar/skipped/waiting ose nuk eshte i pari
        else {
          this.translate.get('validateStep').subscribe((res: string) => {
            this.toastr.error(res);
          });
          return;
        }
      }
    }
  }

  finishProcedure() {
    let params = {
      active: '0'
    };
    this.updateProcedure(this.user.organization_id, this.procId, params)
      .then(() => {
        const dialogRef = this.dialog.open(Canvas27Component, {
          data: {
            procedure: this.procedure
          }
        });
        // this.refreshData();
        this.getProcedure(this.user.organization_id, this.procId);
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  skip() {
    let formData = new FormData();

    formData.append('skipped', '1');
    formData.append('waiting', '0');
    formData.append('validated', '0');

    this.userService.loaderActivate.next(true);
    this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
      .then(() => {
        // find index
        let stepIndex = this.allSteps.findIndex((step) => step == this.currentStep);

        this.getCurrentStep(this.user.organization_id, this.procId, this.currentStep.id).then((res) => {
          // update step
          this.allSteps[stepIndex] = res.data;

          // refresh
          this.refreshSteps();
          this.stepOnFocus();

          // nese gjithe stepsat kryesore jane validu
          if (this.mandatorySteps.every((step) => step.validated)) {
            this.finishProcedure();
          }
          this.userService.loaderActivate.next(false);
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  openReminderDialog() {
    const dialogRef = this.dialog.open(AttenteComponent, {
      data: {
        date: '',
        edit: false
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.date) {
        this.setReminder(result.date);
      }
    });
  }

  setReminder(date) {
    let formData = new FormData();

    formData.append('waiting', '1');
    formData.append('validated', '0');
    formData.append('skipped', '0');

    let params = {
      procedure_id: this.procId,
      procedure_step_id: this.currentStep.id,
      date
    };

    this.userService.loaderActivate.next(true);
    // Create the reminder
    this.createReminder(this.user.organization_id, params)
      .then(() => {
        // If reminder created successfully update the step
        this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
          .then(() => {
            // find index
            let stepIndex = this.allSteps.findIndex((step) => step == this.currentStep);

            this.getCurrentStep(this.user.organization_id, this.procId, this.currentStep.id).then((res) => {
              // update step
              this.allSteps[stepIndex] = res.data;

              // refresh
              this.refreshSteps();
              this.stepOnFocus();
            });

            this.translate.get('reminderAdded').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          })
          .finally(() => this.userService.loaderActivate.next(false));
      })
      .catch((error) => {
        if (error.response) {
          this.userService.loaderActivate.next(false);
          console.log(error.response);
          if (error.response.status == 422)
            this.translate.get('invalidDate').subscribe((res: string) => {
              this.toastr.error(res);
            });
          else if (error.response.status == 403)
            this.translate.get('existingReminder').subscribe((res: string) => {
              this.toastr.error(res);
            });
          else this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
          this.userService.loaderActivate.next(false);
        }
      });
  }

  reopenStep() {
    let params = {
      active: '1'
    };
    let formData = new FormData();

    formData.append('validated', '0');
    formData.append('waiting', '0');
    formData.append('skipped', '0');

    this.userService.loaderActivate.next(true);
    this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
      .then(() => {
        this.updateProcedure(this.user.organization_id, this.procId, params)
          .then(() => this.getProcedure(this.user.organization_id, this.procId))
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          })
          .finally(() => this.userService.loaderActivate.next(false));
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  validate() {
    let formData = new FormData();

    formData.append('validated', '1');
    formData.append('waiting', '0');
    formData.append('skipped', '0');

    this.userService.loaderActivate.next(true);
    this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
      .then(() => {
        // find index
        let stepIndex = this.allSteps.findIndex((step) => step == this.currentStep);

        this.getCurrentStep(this.user.organization_id, this.procId, this.currentStep.id).then((res) => {
          // update step
          this.allSteps[stepIndex] = res.data;

          // refresh
          this.refreshSteps();
          this.stepOnFocus();

          // nese gjithe stepsat kryesore jane validu
          if (this.mandatorySteps.every((step) => step.validated)) {
            this.finishProcedure();
          }
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  addRemark() {
    let formData = new FormData();
    this.addRemarks(formData);

    this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
      .then(() => {
        this.getCurrentStep(this.user.organization_id, this.procId, this.currentStep.id).then((res) => {
          this.stepData = '';
          this.currentStep.data = res.data.data;
          // this.currentStep.data.push(res.data.data.at(-1));
        });
      })
      .catch(() => {});
  }

  addRemarks(formData) {
    if (this.stepData !== '') {
      formData.append('remarques', this.stepData);
    }
  }

  onValueChange(files: File[]) {
    let formData = new FormData();

    // this.stepFiles = files;
    this.stepFiles = this.dragDrop?.files;

    this.stepFiles?.forEach((element) => {
      if (this.isFileAllowed(element.name)) {
      } else {
        this.stepFiles.pop();
      }
    });

    if (this.user && this.currentStep.id) {
      this.addStepFiles(formData);
    }
  }

  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = this.acceptedExt;
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);

    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
      if (!isFileAllowed) {
        this.translate.get('fileError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      }
    }
    return isFileAllowed;
  }

  addStepFiles(formData) {
    if (this.stepFiles?.length > 0) {
      for (let i = 0; i < this.stepFiles.length; i++) {
        formData.append('files[]', this.stepFiles[i]);
      }
      this.dragDrop.clear();
    }
    this.updateStep(this.user.organization_id, this.procId, this.currentStep.id, formData)
      .then(() => {
        this.getCurrentStep(this.user.organization_id, this.procId, this.currentStep.id)
          .then((res) => {
            this.currentStep.data = res.data.data;
          })
          .catch(() => {});
      })
      .catch(() => {});
  }

  openOthers(users) {
    this.dialog.open(UsersAccessComponent, {
      maxHeight: '80vh',
      width: '30vw',
      data: {
        users
      }
    });
  }

  openInfo() {
    const dialogRef = this.dialog.open(TimelineComponent, {
      data: {
        baseUrl: this.baseUrl,
        procedure: this.procedure,
        language: this.userLanguage
      }
    });
  }

  returnNumberColors(i, step) {
    // validated
    if (step.validated) {
      return 'validated clicked';
    }
    // waiting
    else if (step.waiting) {
      return 'waiting clicked';
    }
    // skipped
    else if (step.skipped) {
      return 'skipped clicked';
    }
    // default
    else if (i === this.ind) {
      return 'default-number default-clicked clicked';
    } else {
      return 'default-number';
    }
  }

  returnColors(i, step) {
    // validated
    if (step.validated && i === this.ind) {
      return 'validated clicked';
    } else if (step.validated && i !== this.ind) {
      return 'validated';
    }
    // waiting
    else if (step.waiting && i === this.ind) {
      return 'waiting clicked';
    } else if (step.waiting && i !== this.ind) {
      return 'waiting';
    }
    // skipped
    else if (step.skipped && i === this.ind) {
      return 'skipped clicked';
    } else if (step.skipped && i !== this.ind) {
      return 'skipped';
    }
    // default
    else if (!step.waiting && !step.validated && i === this.ind) {
      return 'default clicked default-clicked';
    } else if (!step.waiting && !step.validated && i !== this.ind) {
      return 'default';
    }
  }

  returnDocs(val) {
    val = val?.filter((doc) => doc.language == this.userLanguage);
    return val;
  }

  deleteDocument(dataId, documentId) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        dataId: dataId,
        documentId: documentId
      }
    });

    dialogRef.afterClosed().subscribe((document) => {
      if (document) {
        this.apiDeleteDocument(
          this.user.organization_id,
          this.procId,
          this.currentStep.id,
          dataId,
          documentId
        )
          .then(() => {
            this.currentStep.data.map((data) => {
              if (data.id === dataId) {
                data.media = [];
              }
            });
            this.translate.get('deletedDoc').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            if (error.response) {
              this.userService.loaderActivate.next(false);
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
              this.userService.loaderActivate.next(false);
            }
          });
      }
    });
  }
  deleteRemark(remarkId) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        dataId: remarkId
      }
    });

    dialogRef.afterClosed().subscribe((document) => {
      if (document) {
        this.apiDeleteRemark(this.user.organization_id, this.procId, this.currentStep.id, remarkId)
          .then(() => {
            this.currentStep.data = this.currentStep.data.filter((data) => data.id !== remarkId);
            this.translate.get('deletedRemark').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            if (error.response) {
              this.userService.loaderActivate.next(false);
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
              this.userService.loaderActivate.next(false);
            }
          });
      }
    });
  }

  async viewContacts() {
    let freshProcedure: AxiosResponse<any>;

    try {
      freshProcedure = await this.apiGetProcedure(this.user.organization_id, this.procId);
    } catch (error) {
      console.error(error);
    }

    const dialogRef = this.dialog.open(ContactListComponent, {
      maxHeight: '80vh',
      minWidth: '25vw',
      data: {
        procedure: freshProcedure.data.data,
        multiPerson: this.ouvertureSteps,
        singlePerson: this.ouvertureStepsSinglePerson
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
      }
    });
  }

  apiGetProcedure(orgId, procId) {
    return this.axiosCallService.api_call_get(`organizations/${orgId}/procedures/${procId}`);
  }

  updateProcedure(orgId, procId, params) {
    return this.axiosCallService.api_call_post(
      `organizations/${orgId}/procedures/${procId}?_method=PATCH`,
      params
    );
  }

  updateStep(orgId, procId, currentStepId, params) {
    return this.axiosCallService.api_call_post(
      `organizations/${orgId}/procedures/${procId}/steps/${currentStepId}?_method=PATCH`,
      params
    );
  }

  getCurrentStep(orgId, procId, currentStepId) {
    return this.axiosCallService.api_call_get(
      `organizations/${orgId}/procedures/${procId}/steps/${currentStepId}`
    );
  }

  createReminder(orgId, params) {
    return this.axiosCallService.api_call_post(`organizations/${orgId}/reminders`, params);
  }

  apiDeleteDocument(orgId, procId, currentStepId, dataId, documentId) {
    return this.axiosCallService.api_call_delete(
      `organizations/${orgId}/procedures/${procId}/steps/${currentStepId}/data/${dataId}/media/${documentId}`
    );
  }

  apiDeleteRemark(orgId, procId, currentStepId, remarkId) {
    return this.axiosCallService.api_call_delete(
      `organizations/${orgId}/procedures/${procId}/steps/${currentStepId}/data/${remarkId}`
    );
  }

  async openWordFileDialog(document) {
    let freshProcedure: AxiosResponse<any>;

    try {
      freshProcedure = await this.apiGetProcedure(this.user.organization_id, this.procId);
    } catch (error) {
      console.error(error);
    }

    // console.log(this.procedure.steps);
    let personTypes = this.procedure.steps.filter(
      (procedure) => procedure.is_ouverture && procedure.ouverture_type === 'person'
    );

    const dialogRef = this.dialog.open(WordFileDialogComponent, {
      width: '600px',
      maxHeight: '90vh',
      data: {
        procedure: freshProcedure.data.data,
        personTypes: personTypes,
        document: document
      }
    });
  }
}
