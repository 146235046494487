<div class="register-page">
  <div class="container">
    <div class="text-center py-5">
      <img class="main-logo" src="assets/images/logo_stepline_white.png" />
    </div>

    <div class="card">
      <div class="card-body p-0">
        <div class="container px-0">
          <div class="row no-gutters">
            <div class="col-12 col-md-4 d-none d-md-block">
              <img
                class="img-fluid rounded-left left-image"
                id="img-main"
                src="assets/images/judge-bg.jpg"
              />
            </div>

            <div class="col-12 col-md-8 p-2 d-flex flex-column justify-content-around">
              <div class="d-flex flex-nowrap justify-content-end align-items-center">
                <div class="dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img [src]="getFlag(translate.currentLang)" class="mb-1 mr-1" />
                    {{ getLang(translate.currentLang) }}
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <li
                      *ngFor="let lang of translate.getLangs()"
                      (click)="changeLanguage(lang)"
                      class="dropdown-item"
                      translate
                    >
                      <img [src]="getFlag(lang)" class="mb-1 mr-1" /> {{ lang }}
                    </li>
                  </ul>
                </div>
              </div>

              <div class="text-center my-4">
                <h1 class="text-muted font-weight-bold m-0" translate>welcomeMessage</h1>
                <p class="lead m-0" translate>welcomeSubtitle</p>
              </div>

              <div class="mb-2">
                <form (ngSubmit)="onSubmit()" [formGroup]="form">
                  <div class="container">
                    <div class="row justify-content-center pt-2">
                      <div class="form-group col-sm-7 col-8 shadow bg-white p-0">
                        <input
                          id="email"
                          type="email"
                          class="form-control"
                          formControlName="email"
                          name="email"
                          required
                          autocomplete="email"
                          placeholder="byron@email.com"
                        />
                        <label
                          for="email"
                          class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate
                          >email</label
                        >
                      </div>
                    </div>

                    <div class="row justify-content-center py-2">
                      <div class="form-group col-sm-7 col-8 shadow bg-white p-0">
                        <input
                          id="password"
                          type="password"
                          class="form-control"
                          formControlName="password"
                          name="password"
                          required
                          minlength="8"
                          autocomplete="current-password"
                          [ngStyle]="notMatching() ? { borderLeft: '5px solid #a94442' } : null"
                        />
                        <label
                          for="password"
                          class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate
                          >password</label
                        >
                      </div>
                    </div>

                    <div class="row justify-content-center pb-2">
                      <div class="form-group col-sm-7 col-8 shadow bg-white p-0">
                        <input
                          id="password_confirmation"
                          type="password"
                          class="form-control"
                          formControlName="password_confirmation"
                          name="password_confirmation"
                          required
                          minlength="8"
                          autocomplete="current-password"
                          [ngStyle]="notMatching() ? { borderLeft: '5px solid #a94442' } : null"
                        />
                        <label
                          for="password_confirmation"
                          class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate
                          >confirmPassword</label
                        >
                      </div>
                    </div>

                    <div class="text-center py-3">
                      <button type="submit" class="btn btn-primary font-weight-bold px-5" translate>
                        registerButton
                      </button>
                    </div>

                    <div class="row">
                      <div class="col-12 position-absolute">
                        <mat-spinner class="m-auto" *ngIf="loading" [diameter]="50"></mat-spinner>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="text-center d-flex flex-column py-2" style="font-size: 16px">
                <a class="text-primary font-weight-bold" routerLink="/login" id="login" translate> login </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer text-light text-center py-5">
      <small translate> loginFooter </small>
    </footer>
  </div>
</div>
