<nav
  id="i"
  class="navbar navbar-expand navbar-light shadow bg-white rounded"
  style="display: flex; justify-content: space-between"
>
  <div>
    <a class="navbar-brand" routerLink="/dashboard">
      <img src="../../assets/images/logo_stepline.png" height="25px" />
    </a>
  </div>

  <div class="d-inline-flex">
    <div class="dropdown mr-0 mr-sm-2">
      <a
        class="nav-link dropdown-toggle"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img [src]="getFlag(translate.currentLang)" class="mb-1 mr-1" />
        {{ getLang(translate.currentLang) }}
      </a>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li
          *ngFor="let lang of translate.getLangs()"
          (click)="changeLanguage(lang)"
          class="dropdown-item"
          translate
        >
          <img [src]="getFlag(lang)" class="mb-1 mr-1" /> {{ lang }}
        </li>
      </ul>
    </div>
    <span (click)="logout()" class="material-icons text-danger" style="align-self: center; cursor: pointer">
      logout
    </span>
  </div>
</nav>
