import { AreYouSureComponent } from './../are-you-sure/are-you-sure.component';
import { ListeProcedureComponent } from './../liste-procedure/liste-procedure.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import { UsersAccessComponent } from '../users-access/users-access.component';
import * as _ from 'lodash';
import { EditIdentifierComponent } from '../edit-identifier/edit-identifier.component';

@Component({
  selector: 'app-dossiers',
  templateUrl: './dossiers.component.html',
  styleUrls: ['./dossiers.component.scss']
})
export class DossiersComponent implements OnInit {
  folderSearch;
  dossiers = [];
  allSteps = [];
  unValidatedSteps = [];
  validatedSteps = [];
  firstUnvalidStepIndex = 0;
  lastValidStepIndex = 0;
  dirtySteps = [];
  cleanSteps = [];
  lastDirtyStepIndex = 0;
  firstDirtyStepIndex = 0;
  lastCleanStepIndex = 0;
  firstCleanStepIndex = 0;
  baseUrl = null;
  currentStep = null;
  user = null;

  constructor(
    public dialog: MatDialog,
    private axiosCallService: AxiosCallService,
    private userService: UserService,
    private router: Router,
    public state: StateService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {}

  refreshData() {
    this.state.user.subscribe((result) => {
      if (result.id) {
        this.user = result;
        this.getDossiers(this.user.id, this.user.organization_id);
      }
    });
  }

  getDossiers(userId, orgId) {
    this.userService.loaderActivate.next(true);
    this.axiosCallService
      .api_call_get(`organizations/${orgId}/procedures`)
      .then((procedures) => {
        this.storeSteps(this.filterDossiers(procedures, userId));
      })
      .catch((error) => (error.response ? console.log(error.response) : console.log(error)))
      .finally(() => this.userService.loaderActivate.next(false));
  }

  filterDossiers(procedures, userId) {
    // Filter procedures that user has access to
    const sortedProcedures = _.orderBy(procedures.data.data, 'created_at', 'desc');
    return sortedProcedures.filter((procedure) => {
      if (!procedure.deleted_by && procedure.groups[0].users.find((user) => user.id == userId)) {
        return true;
      }
    });
  }

  storeSteps(myProcedures) {
    this.dossiers = myProcedures.map((procedure) => {
      // store all steps
      this.allSteps = procedure.steps.filter((step) => !step.is_ouverture);

      // store steps that are dirty, ex: validated or skipped or waiting
      this.dirtySteps = this.allSteps.filter((step) => step.validated || step.skipped || step.waiting);

      // store steps that are clean
      this.cleanSteps = this.allSteps.filter((step) => !step.validated && !step.skipped && !step.waiting);

      if (this.dirtySteps.length) {
        // store first index of dirty steps
        this.firstDirtyStepIndex = this.allSteps.findIndex((step) => step.id === this.dirtySteps[0].id);
        // store last index of dirty steps
        this.lastDirtyStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.dirtySteps).id);
      }

      if (this.cleanSteps.length) {
        // store first index of clean steps
        this.firstCleanStepIndex = this.allSteps.findIndex((step) => step.id === this.cleanSteps[0].id);
        // store last index of clean steps
        this.lastCleanStepIndex = this.allSteps.findIndex((step) => step.id === _.last(this.cleanSteps).id);
      }

      // nese skemi prek asgje
      if (this.dirtySteps.length < 1) {
        this.currentStep = this.allSteps[0].translations;
      }

      // nese i kemi prek gjitha
      else if (this.cleanSteps.length < 1) {
        this.currentStep = this.allSteps[_.findLastIndex(this.allSteps)].translations;
      }

      // nese kemi prek disa
      else {
        this.currentStep = this.allSteps[this.firstCleanStepIndex].translations;
      }

      return {
        id: procedure.id,
        nomPrenom: procedure.name,
        dossierNumber: procedure.number,
        procedure: procedure.type.slug,
        etat: this.state.returnTranslation(this.currentStep, 'name'),
        statut: procedure.active,
        users: procedure.groups[0].users,
        created: procedure.created_at
      };
    });
  }

  ngOnInit(): void {
    this.userService.loaderActivate.next(true);
    this.baseUrl = this.axiosCallService.baseUrl;
    this.refreshData();
  }

  goToProcedure(id) {
    this.router.navigate(['/procedure', id]);
  }

  dangerDialogProcedure(id) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        procedure_id: id
      }
    });

    dialogRef.afterClosed().subscribe((procedure) => {
      if (procedure) this.deleteProcedure(procedure.procedure_id);
    });
  }

  deleteProcedure(id) {
    this.axiosCallService
      .api_call_delete(`organizations/${this.user.organization_id}/procedures/${id}`)
      .then((res) => {
        this.getDossiers(this.user.id, this.user.organization_id);
        this.translate.get('deletedProc').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((error) => {
        if (error.response) {
          this.userService.loaderActivate.next(false);
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
          this.userService.loaderActivate.next(false);
        }
      });
  }

  editProcedure(id: Number) {
    const procedure = this.dossiers.find((procedure) => procedure.id === id);
    const dialogRef = this.dialog.open(EditIdentifierComponent, {
      data: {
        name: procedure.nomPrenom
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.name) {
        let params = {
          name: result.name
        };

        if (!params.name) delete params.name;
        this.axiosCallService
          .api_call_post(`organizations/${this.user.organization_id}/procedures/${id}?_method=PATCH`, params)
          .then((res) => {
            this.getDossiers(this.user.id, this.user.organization_id);
            this.translate.get('procedureUpdated').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            if (error.response) {
              this.userService.loaderActivate.next(false);
              console.log(error.response);
              this.toastr.error(error.response.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
              this.userService.loaderActivate.next(false);
            }
          });
      }
    });
  }

  openDialog() {
    this.dialog.open(ListeProcedureComponent, {
      width: '80%',
      data: {
        user: this.user
      }
    });
  }

  openOthers(users) {
    this.dialog.open(UsersAccessComponent, {
      data: {
        users
      }
    });
  }
}
