<!-- <div class="timeline/-page"> -->
<button type="button" class="close pb-4" aria-label="Close" (click)="iks()">
  <span aria-hidden="true" id="close-button">&times;</span>
</button>
<div class="container-fluid scroll">
  <div class="title text-center mb-4 mt-0">
    <h1 style="color: #2c77ff; font-weight: 500">{{ data.procedure.type.slug }}</h1>
    <p style="color: #707070; font-weight: 400" translate>processDesc</p>
  </div>
  <div class="text-center" style="height: 30px">
    <div class="rreth"></div>
  </div>
  <div class="timeline">
    <ul *ngFor="let step of data.procedure.steps">
      <li *ngIf="!step.is_ouverture">
        <div class="timeline-content">
          <h1 style="color: #707070">{{ state.returnTranslation(step.translations, "name") }}</h1>
          <div [innerHTML]="state.returnTranslation(step.translations, 'text')" style="color: #909090"></div>
          <div *ngFor="let docs of returnDocs(step.docs)">
            <p class="lead">
              <span class="badge badge-primary p-1 mr-1">{{ docs.extension | uppercase }}</span>
              <a [href]="data.baseUrl + '/' + docs.path" target="_blank" download
                >{{ docs.name }}.{{ docs.extension }}</a
              >
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<!-- </div> -->
