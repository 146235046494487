import { Router } from '@angular/router';
import { StateService } from './../../services/state.service';
import { Component, OnInit } from '@angular/core';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-organization-access',
  templateUrl: './organization-access.component.html',
  styleUrls: ['./organization-access.component.scss']
})
export class OrganizationAccessComponent implements OnInit {
  user: any = null;
  organizations: Array<any> = [];
  orgs = [];
  organizationName;
  
  constructor(
    private state: StateService,
    private axios: AxiosCallService,
    private router: Router,
    public translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.state.refreshUser();
    this.state.user.subscribe((result) => {
      if (result.id) {
        if (result.organization_id) {
          this.router.navigate(['/dashboard']);
        }

        this.user = result;

        result.requests.forEach((request) => {
          this.axios
            .api_call_get(`organizations/${request.organization_id}`, {})
            .then((response) => {
              this.orgs.push(response.data.data);
            })
            .catch(console.error);
        });
      }
    });

    this.axios
      .api_call_get(`organizations/all`)
      .then((res) => {
        this.organizations = res.data.data;
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  // switchLanguage(language: string) {
  //   this.translateService.use(language);
  // }

  getOrganizationName(id) {
    let organization = this.orgs.find((org) => org.id === id);
    if (organization) return organization.name;
  }

  getOrganizationCreated(id) {
    let organization = this.orgs.find((org) => org.id === id);
    if (organization) return organization.created_at;
  }

  getAllOwner(id) {
    let organization = this.organizations.find((org) => org.id === id);
    let user = organization.users.find((user) => user.id === organization.created_by);
    if (user.firstname) {
      return `${user.firstname} ${user.lastname}`;
    } else {
      return `${user.email}`;
    }
  }

  getAllOwnerAvatar(id) {
    let organization = this.organizations.find((org) => org.id === id);
    let user = organization.users.find((user) => user.id === organization.created_by);
    return `${this.axios.baseUrl}${user.profile_pic}`;
  }

  getOwner(id) {
    let organization = this.orgs.find((org) => org.id === id);
    if (organization) {
      let user = organization.users.find((user) => user.id === organization.created_by);
      if (user.firstname) {
        return `${user.firstname} ${user.lastname}`;
      } else {
        return `${user.email}`;
      }
    }
  }

  getOwnerAvatar(id) {
    let organization = this.orgs.find((org) => org.id === id);
    if (organization) {
      let user = organization.users.find((user) => user.id === organization.created_by);
      return `${this.axios.baseUrl}${user.profile_pic}`;
    }
  }

  apply(id) {
    this.axios
      .api_call_post(`organizations/${id}/requests`, { organization_id: id })
      .then((res) => {
        this.state.refreshUser();
        this.translate.get('createdRequest').subscribe((res: string) => {
              this.toastr.success(res);
            });
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  submit() {
    this.axios
      .api_call_post(`organizations`, { name: this.organizationName })
      .then((res) => {
        this.state.refreshOrganization();
        this.state.refreshUser();
        this.router.navigate(['/dashboard']);
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  logout(): void {
    // this.loading = true;
    this.axios
      .api_call_post_base('/logout', {})
      .then((res: any) => {
        this.state.clearState();
        // this.toastr.success('Notification', res.data.message);
        if (res.status == 200) {
          // this.loading = false;
          this.router.navigate(['/login']);
        }
      })
      .catch((err) => {
        err.response ? console.log(err.response) : console.error(err);
        // this.loading = false;
      });
  }

  changeLanguage(lang) {
    localStorage.setItem('currentLanguage', lang);
    this.translate.use(lang);
    // this.translate.setDefaultLang(lang)
  }

  getLang(language: string): string {
    if (!language) language = 'fr';
    let result = '';

    this.translate.get(language).subscribe((res) => {
      if (res) result = res;
    });

    return result;
  }

  getFlag(language: string): string {
    if (!language) language = 'fr';

    switch (language) {
      case 'fr':
        return '../../assets/images/fr.svg';
        break;

      case 'de':
        return '../../assets/images/de.svg';
        break;

      case 'en':
        return '../../assets/images/en.svg';
        break;

      default:
        break;
    }
  }
}
