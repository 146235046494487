<div>
  <div class="header mb-4">
    <app-header></app-header>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2 col-xl-1 mb-3 mb-md-0">
        <app-sidebar></app-sidebar>
      </div>
      <div class="col-12 col-md-10 col-lg-10 col-xl-11">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-7">
            <h2 class="text-muted font-weight-light" translate>reminders</h2>
            <div class="scroll shadow bg-white rounded px-3">
              <table *ngIf="reminders.length > 0" class="table table-hover">
                <thead>
                  <div class="justify-content-center">
                    <input
                      class="form-control mr-sm-2 rounded-pill my-2"
                      type="text"
                      name="search"
                      [(ngModel)]="remindersSearch"
                      placeholder="{{ 'search' | translate }}"
                      aria-label="Rechercher"
                      style="height: 30px; border: none"
                    />
                  </div>
                </thead>
                <thead>
                  <tr>
                    <th translate>
                      folders &nbsp;<svg
                        [appSort]="reminders"
                        data-order="desc"
                        data-name="dossier"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      tasks &nbsp;<svg
                        [appSort]="reminders"
                        data-order="desc"
                        data-name="tache"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      remindersDate &nbsp;<svg
                        [appSort]="reminders"
                        data-order="desc"
                        data-name="delai"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let reminder of reminders | filter: remindersSearch" style="cursor: pointer">
                    <td (click)="goToProcedure(reminder.procedure.id)">{{ reminder.dossier }}</td>
                    <td (click)="goToProcedure(reminder.procedure.id)">{{ reminder.tache }}</td>
                    <td (click)="goToProcedure(reminder.procedure.id)">
                      {{ reminder.delai | date: "dd/MM/yyyy" }}
                    </td>
                    <td>
                      <svg
                        data-toggle="collapse"
                        data-target="#collapseEdit"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 39 39"
                        style="cursor: pointer"
                        (click)="editReminder(reminder.id, reminder.delai)"
                      >
                        <g id="Group_2285" data-name="Group 2285" transform="translate(-454 -404)">
                          <path
                            id="Path_142"
                            data-name="Path 142"
                            d="M3,17.248V21H6.749L17.808,9.939,14.058,6.19ZM20.708,7.039a1,1,0,0,0,0-1.41l-2.34-2.34a1,1,0,0,0-1.41,0l-1.83,1.83,3.749,3.749,1.83-1.83Z"
                            transform="translate(462 412.002)"
                            fill="#2c77ff"
                          />
                        </g>
                      </svg>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="reminders.length < 1"
                style="display: flex; justify-content: center; align-items: center; height: 100%"
              >
                <h1 class="text-muted font-weight-light" translate>noRemindersMessage</h1>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-5 mt-4 mt-md-0">
            <h2 class="text-muted font-weight-light" translate>
              infoDocs
              <span *ngIf="isAdmin" (click)="openBrowser()" class="btn btn-sm btn-success ml-1" translate
                >+</span
              >
            </h2>
            <!-- Hidden input -->
            <input
              #myInput
              (change)="handleFiles($event)"
              type="file"
              id="dashboardFile"
              style="display: none"
              multiple
            />
            <div class="scroll shadow bg-white rounded px-3">
              <table *ngIf="documents.length > 0" class="table">
                <thead>
                  <div class="justify-content-center">
                    <input
                      class="form-control mr-sm-2 rounded-pill my-2"
                      type="text"
                      name="search"
                      [(ngModel)]="docSearch"
                      placeholder="{{ 'search' | translate }}"
                      aria-label="Rechercher"
                      style="height: 30px; border: none"
                    />
                  </div>
                </thead>
                <thead>
                  <tr>
                    <th translate>
                      docName &nbsp;<svg
                        [appSort]="documents"
                        data-order="desc"
                        data-name="name"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>actions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr style="cursor: pointer" *ngFor="let document of documents | filter: docSearch">
                    <td>{{ document.name }}.{{ document.extension }}</td>
                    <td>
                      <a
                        [href]="baseUrl + document.path"
                        target="_blank"
                        class="btn btn-sm btn-primary"
                        download
                        translate
                        >download</a
                      >
                      <button
                        *ngIf="isAdmin"
                        (click)="dangerDialogDocument(document.folderId, document.id)"
                        type="button"
                        class="close text-danger"
                        aria-label="Close"
                      >
                        <span aria-hidden="true" id="close-button">&times;</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="documents.length < 1"
                style="display: flex; justify-content: center; align-items: center; height: 100%"
              >
                <h1 class="text-muted font-weight-light" translate>noDocsMessage</h1>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-lg-12">
            <h2 class="text-muted font-weight-light" translate>latestFiles</h2>
            <div class="scroll shadow bg-white rounded px-3">
              <table *ngIf="dossiers.length > 0" class="table table-hover">
                <thead>
                  <div class="justify-content-center">
                    <input
                      class="form-control mr-sm-2 rounded-pill my-2"
                      type="text"
                      name="search"
                      [(ngModel)]="foldersSearch"
                      placeholder="{{ 'search' | translate }}"
                      aria-label="Rechercher"
                      style="height: 30px; border: none"
                    />
                  </div>
                </thead>
                <thead>
                  <tr>
                    <th translate>
                      fileRef &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="nomPrenom"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      fileNo &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="dossierNumber"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      dateOfCreation &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="created"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      procedure &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="procedure"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>
                      state &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="etat"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>access</th>
                    <th translate>
                      status &nbsp;<svg
                        [appSort]="dossiers"
                        data-order="desc"
                        data-name="statut"
                        style="cursor: pointer"
                        id="sort_arrows"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8.506"
                        height="11.116"
                        viewBox="0 0 8.506 11.116"
                      >
                        <g
                          id="noun_Arrow_Down_2022881"
                          data-name="noun_Arrow Down_2022881"
                          transform="translate(0 7)"
                        >
                          <path
                            id="Path_2051"
                            data-name="Path 2051"
                            d="M39.242,45.468h0a.7.7,0,0,1-.446-.148l-3.631-3.056a.475.475,0,0,1,0-.754.724.724,0,0,1,.9,0l3.183,2.682,3.183-2.682a.723.723,0,0,1,.873.018.472.472,0,0,1,.021.733L39.688,45.32A.7.7,0,0,1,39.242,45.468Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                        <g id="noun_Arrow_Down_2022881-2" data-name="noun_Arrow Down_2022881">
                          <path
                            id="Path_2051-2"
                            data-name="Path 2051"
                            d="M39.242,41.352h0a.7.7,0,0,0-.446.148l-3.631,3.056a.475.475,0,0,0,0,.754.724.724,0,0,0,.9,0l3.182-2.682,3.182,2.682A.723.723,0,0,0,43.3,45.3a.472.472,0,0,0,.021-.733L39.688,41.5A.7.7,0,0,0,39.242,41.352Z"
                            transform="translate(-34.977 -41.352)"
                            fill="#63799b"
                          />
                        </g>
                      </svg>
                    </th>
                    <th translate>actions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr *ngFor="let dossier of dossiers | filter: foldersSearch" style="cursor: pointer">
                    <td class="font-weight-bold" (click)="goToProcedure(dossier.id)">
                      {{ dossier.nomPrenom }}
                    </td>
                    <td (click)="goToProcedure(dossier.id)">
                      {{ dossier.dossierNumber }}
                    </td>
                    <td (click)="goToProcedure(dossier.id)">
                      {{ dossier.created | date: "dd/MM/yyyy" }}
                    </td>
                    <td (click)="goToProcedure(dossier.id)">
                      {{ dossier.procedure }}
                    </td>
                    <td (click)="goToProcedure(dossier.id)">
                      {{ dossier.etat }}
                    </td>
                    <td>
                      <div class="avatar-stack">
                        <img
                          *ngFor="let user of dossier.users | slice: 0:3"
                          class="avatar shadow"
                          src="{{ baseUrl + user.profile_pic }}"
                          onerror="this.src='../../assets/images/default-profile-pic.png'"
                          [title]="user.firstname + ' ' + user.lastname + ' ' + '(' + user.email + ')'"
                          routerlink="/profile"
                        />
                        <ng-container *ngIf="dossier.users.length > 3">
                          <div (click)="openOthers(dossier.users)" class="circle shadow">
                            + {{ dossier.users.length - 3 }}
                          </div>
                        </ng-container>
                      </div>
                    </td>
                    <td
                      (click)="goToProcedure(dossier.id)"
                      [ngStyle]="dossier.statut ? { color: '#00C94D' } : { color: '#FF2919' }"
                      translate
                    >
                      {{ dossier.statut ? "inProgress" : "finished" }}
                    </td>
                    <td>
                      <svg
                        data-toggle="collapse"
                        data-target="#collapseEdit"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 39 39"
                        style="cursor: pointer"
                        (click)="editProcedure(dossier.id)"
                      >
                        <g id="Group_2285" data-name="Group 2285" transform="translate(-454 -404)">
                          <path
                            id="Path_142"
                            data-name="Path 142"
                            d="M3,17.248V21H6.749L17.808,9.939,14.058,6.19ZM20.708,7.039a1,1,0,0,0,0-1.41l-2.34-2.34a1,1,0,0,0-1.41,0l-1.83,1.83,3.749,3.749,1.83-1.83Z"
                            transform="translate(462 412.002)"
                            fill="#2c77ff"
                          />
                        </g>
                      </svg>
                      <button
                        type="button"
                        class="close text-danger ml-2"
                        aria-label="Close"
                        (click)="dangerDialogProcedure(dossier.id)"
                      >
                        <span aria-hidden="false" id="close-button">&times;</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="dossiers.length < 1"
                style="display: flex; justify-content: center; align-items: center; height: 100%"
              >
                <h1 class="text-muted font-weight-light" translate>noFoldersMessage</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
