<div>
  <div class="header mb-4">
    <app-header></app-header>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-md-2 col-lg-2 col-xl-1 mb-3 mb-md-0">
        <app-sidebar></app-sidebar>
      </div>

      <div class="col-12 col-md-10 col-lg-10 col-xl-11">
        <h2 class="text-muted font-weight-light" id="title" translate>myProfile</h2>
        <div class="card shadow bg-white" style="border-radius: 10px; border-style: none">
          <div class="card-body p-4 p-sm-5">
            <div class="row" id="profile-info-row">
              <div id="profile-info-image" class="d-flex flex-row col-12 col-md-5 col-lg-4 col-xl-3">
                <div id="profile-image">
                  <img class="rounded-circle profile-pic" [src]="imgUrl"
                    onerror="this.src='../../assets/images/default-profile-pic.png'" />
                </div>
                <div id="change-profile-image" class="d-flex align-items-end ml-n5">
                  <input type="file" #img (change)="handleFileInput($event.target.files)" id="file-input"
                    style="display: none" />
                  <label for="file-input">
                    <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39"
                      style="cursor: pointer">
                      <!-- style="margin-left: -30px; margin-top: 112px; cursor: pointer" -->
                      <g id="Group_2285" data-name="Group 2285" transform="translate(-454 -404)">
                        <circle id="Ellipse_168" data-name="Ellipse 168" cx="19.5" cy="19.5" r="19.5"
                          transform="translate(454 404)" fill="#2c77ff" />
                        <path id="Path_142" data-name="Path 142"
                          d="M3,17.248V21H6.749L17.808,9.939,14.058,6.19ZM20.708,7.039a1,1,0,0,0,0-1.41l-2.34-2.34a1,1,0,0,0-1.41,0l-1.83,1.83,3.749,3.749,1.83-1.83Z"
                          transform="translate(462 412.002)" fill="#f3f5f7" />
                      </g>
                    </svg>
                  </label>
                </div>
              </div>

              <div id="profile-general-info" class="pt-3 pt-md-0 col-12 col-md-7 col-lg-8 col-xl-9">
                <h1 *ngIf="user.firstname && user.lastname" class="text-primary" id="name">
                  {{ user.firstname }} {{ user.lastname }}
                </h1>
                <p *ngIf="user.npa && user.localite" class="text-muted">{{ user.npa }} {{ user.localite }}</p>
              </div>
            </div>

            <div id="change-info" class="row mt-5">
              <div id="change-general-info" class="col-12 col-md-12 col-xl-8 mb-5 mb-xl-0">
                <h2 class="text-muted font-weight-light mb-3" translate>changeGeneralInfo</h2>
                <form class="profile-form" [formGroup]="profileForm" (ngSubmit)="changeGeneralInfo()">
                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="firstname" type="text" class="form-control" formControlName="firstname"
                          name="firstname" required autocomplete="text" />
                        <label for="firstname" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>name</label>
                      </div>
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="lastname" type="text" class="form-control" formControlName="lastname" name="lastname"
                          required minlength="2" autocomplete="text" />
                        <label for="lastname" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>surname</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="email" type="email" class="form-control" formControlName="email" name="email"
                          required />
                        <label for="email" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>email</label>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary rounded-pill" translate>save</button>
                  </div>
                </form>
              </div>

              <div id="change-password" class="col-12 col-xl-4 mb-5 mb-xl-0">
                <h2 class="text-muted font-weight-light mb-3" translate>changePassword1</h2>
                <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group col-12 shadow-sm bg-white p-0 d-flex">
                        <div class="d-flex" style="flex: 1">
                          <label for="current" class="form-control-placeholder mb-1 font-size-sm text-muted"
                            translate>currentPassword</label>
                          <input [type]="hide ? 'password' : 'text'" class="form-control" id="current"
                            formControlName="current" name="current" required />
                        </div>
                        <div class="input-group-addon"
                          style="display: flex; flex: 0; align-items: center; padding-right: 10px">
                          <span class="material-icons" (click)="hide = !hide" style="cursor: pointer">
                            {{ hide ? "visibility_off" : "visibility" }}
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-12 shadow-sm bg-white p-0 d-flex">
                        <label for="password" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>newPassword</label>
                        <div class="d-flex" style="flex: 1">
                          <input [type]="hide ? 'password' : 'text'" class="form-control" id="password"
                            formControlName="password" name="password" required />
                        </div>
                        <div class="input-group-addon"
                          style="display: flex; flex: 0; align-items: center; padding-right: 10px">
                          <span class="material-icons" (click)="hide = !hide" style="cursor: pointer">
                            {{ hide ? "visibility_off" : "visibility" }}
                          </span>
                        </div>
                      </div>
                      <div class="form-group col-12 shadow-sm bg-white p-0 d-flex">
                        <label for="password_confirmation" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>passConfirmation</label>
                        <div class="d-flex" style="flex: 1">
                          <input [type]="hide ? 'password' : 'text'" class="form-control" id="password_confirmation"
                            formControlName="password_confirmation" name="password_confirmation" required />
                        </div>
                        <div class="input-group-addon"
                          style="display: flex; flex: 0; align-items: center; padding-right: 10px">
                          <span class="material-icons" (click)="hide = !hide" style="cursor: pointer">
                            {{ hide ? "visibility_off" : "visibility" }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary rounded-pill" translate>save</button>
                  </div>
                </form>
              </div>

              <div id="change-contact-info" class="col-12 col-md-12 col-xl-8 mb-5 mb-xl-0">
                <h2 class="text-muted font-weight-light mb-3" translate>changeProfessionalInfo</h2>
                <form class="profile-form" [formGroup]="professionalInfoForm" (ngSubmit)="changeProfessionalInfo()">
                  <div class="form-row">
                    <div class="col-12 col-md-6">
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="npa" type="text" class="form-control" formControlName="npa" name="npa" />
                        <label for="npa" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>zipCode</label>
                      </div>
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="localite" type="text" class="form-control" formControlName="localite" name="localite" />
                        <label for="localite" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>city</label>
                      </div>
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="role" type="text" class="form-control" formControlName="role" name="role" />
                        <label for="role" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>roleOfUser</label>
                      </div>
                    </div>

                    <div class="col-12 col-md-6">
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="phone" type="tel" class="form-control" formControlName="personalPhone" name="phone" />
                        <label for="phone" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>personalPhone</label>
                      </div>
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="businessPhone" type="tel" class="form-control" formControlName="businessPhone" name="businessPhone" />
                        <label for="businessPhone" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>businessPhone</label>
                      </div>
                      <div class="form-group col shadow-sm bg-white p-0">
                        <input id="businessEmail" type="email" class="form-control" formControlName="businessEmail" name="businessEmail" />
                        <label for="businessEmail" class="form-control-placeholder mb-1 font-size-sm text-muted"
                          translate>businessEmail</label>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button class="btn btn-primary rounded-pill" translate>save</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
