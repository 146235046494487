<div
  class="px-5 scroll d-flex justify-content-between flex-column"
  style="padding-top: 2rem; padding-bottom: 1rem"
>
  <div>
    <button type="button" class="close" aria-label="Close" (click)="close()" style="box-sizing: border-box">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="container p-0">
      <h1 class="text-primary text-center text-bold" translate>folderName</h1>
      <div class="form-row">
        <div class="form-group col-md-4">
          <label for="fileNo" translate="">fileNo</label>
          <input type="text" class="form-control" id="fileNo" [(ngModel)]="fileNo" debounce="50" />
        </div>
        <div class="form-group col-md-4">
          <label for="folderName" translate="">folderNameSurname</label>
          <input type="text" class="form-control" id="folderName" [(ngModel)]="nom" debounce="50" />
        </div>
        <div class="form-group col-md-4">
          <label for="label" translate>selectGroup</label>
          <select [(ngModel)]="group" class="custom-select">
            <option *ngFor="let group of userGroups" [value]="group.id">
              {{ group.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <app-canvas4
      [procedure]="procedure"
      [steps]="ouvertureSteps"
      [currentStep]="ouvertureSteps[step]"
      [stepIndex]="step"
      (parameters)="getParams($event)"
    >
    </app-canvas4>
  </div>

  <div class="">
    <button
      *ngIf="step != 0"
      (click)="previous()"
      type="submit"
      class="btn rounded-pill"
      style="
        border-color: #2c77ff;
        padding-left: 24px;
        padding-right: 24px;
        color: #2c77ff;
        background-color: white;
      "
      translate
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="arrow_forward" transform="translate(24 24) rotate(180)">
          <path
            id="Icon_color"
            data-name="Icon color"
            d="M0,7.5v-1A.5.5,0,0,1,.5,6H16.67l-4.45-4.44a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0l6.14,6.13a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-6.14,6.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L16.67,8H.5A.5.5,0,0,1,0,7.5Z"
            transform="translate(2 5.002)"
            fill="#2c77ff"
          />
        </g>
      </svg>
      back
    </button>

    <button
      *ngIf="step != maxSteps - 1"
      (click)="next()"
      type="submit"
      class="btn rounded-pill float-right ml-2"
      style="
        border-color: #2c77ff;
        padding-left: 27px;
        padding-right: 25px;
        color: #2c77ff;
        background-color: white;
      "
      translate
    >
      next
      <svg id="arrow_forward" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
          id="Icon_color"
          data-name="Icon color"
          d="M0,7.5v-1A.5.5,0,0,1,.5,6H16.67l-4.45-4.44a.5.5,0,0,1,0-.71l.71-.7a.5.5,0,0,1,.71,0l6.14,6.13a.75.75,0,0,1,.22.53v.38a.77.77,0,0,1-.22.53l-6.14,6.13a.5.5,0,0,1-.71,0l-.71-.71a.49.49,0,0,1,0-.7L16.67,8H.5A.5.5,0,0,1,0,7.5Z"
          transform="translate(2 5.002)"
          fill="#2c77ff"
        />
      </svg>
    </button>
    <button
      type="button"
      class="btn btn rounded-pill float-right"
      style="
        border-color: #2c77ff;
        padding-left: 27px;
        padding-right: 27px;
        background-color: #2c77ff;
        color: white;
      "
      (click)="create()"
      translate
    >
      finish
    </button>
  </div>
</div>
