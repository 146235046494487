import { StateService } from './../../services/state.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AxiosCallService } from '../../services/axios-call.service';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss']
})
export class ProfilePageComponent implements OnInit {
  profileForm: FormGroup;
  professionalInfoForm: FormGroup;
  passwordForm: FormGroup;
  hide = true;
  username: string;
  stateUser: any;
  user: any;
  loading = false;
  imgUrl;
  fileToUpload: File = null;
  firstName: any;
  lastName: any;
  personalPhone: any;
  businessPhone: any;
  businessEmail: any;
  role: any;
  npa: any;
  localite: any;
  baseUrl = this.axiosService.baseUrl;

  constructor(
    private axiosService: AxiosCallService,
    private userService: UserService,
    private toastr: ToastrService,
    private state: StateService,
    public translate: TranslateService,
  ) {
    // translateService.setDefaultLang('fr');
  }

  // switchLanguage(language: string) {
  //   this.translateService.use(language);
  // }

  ngOnInit() {
    this.userService.loaderActivate.next(true);

    this.profileForm = new FormGroup({
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      email: new FormControl('')
    });

    this.professionalInfoForm = new FormGroup({
      personalPhone: new FormControl(''),
      businessPhone: new FormControl(''),
      npa: new FormControl(''),
      localite: new FormControl(''),
      role: new FormControl(''),
      businessEmail: new FormControl('')
    });

    this.passwordForm = new FormGroup({
      current: new FormControl(''),
      password: new FormControl(''),
      password_confirmation: new FormControl('')
    });

    this.state.user.subscribe((result) => {
      if (result.id) {
        this.userService.loaderActivate.next(false);
        this.user = result;

        this.profileForm.setValue({
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email
        });

        this.professionalInfoForm.setValue({
          npa: this.user.npa,
          localite: this.user.localite,
          personalPhone: this.user.personal_phone,
          businessPhone: this.user.business_phone,
          role: this.user.position,
          businessEmail: this.user.business_email
        });

        this.imgUrl = this.baseUrl + this.user.profile_pic;
      }
    });
  }

  submitProfileData(data: FormData) {
    this.axiosService
      .api_call_post('user', data)
      .then((res: any) => {
        this.userService.loaderActivate.next(false);
        this.state.setUser(res.data.data);
        // this.toastr.success('Notification!', 'Profile updated!');
        this.translate.get('updatedProfile').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((err) => {
        this.userService.loaderActivate.next(false);
        console.log('err', err);
      });
  }

  changeGeneralInfo() {
    this.userService.loaderActivate.next(true);

    let formData = new FormData();

    if (this.profileForm.value.firstname) formData.append('firstname', this.profileForm.value.firstname);
    if (this.profileForm.value.lastname) formData.append('lastname', this.profileForm.value.lastname);
    if (this.profileForm.value.email) formData.append('email', this.profileForm.value.email);
    if (this.fileToUpload) formData.append('profile_pic', this.fileToUpload);

    this.submitProfileData(formData);
  }

  changeProfessionalInfo() {
    this.userService.loaderActivate.next(true);

    let formData = new FormData();

    if (this.professionalInfoForm.value.npa) formData.append('npa', this.professionalInfoForm.value.npa);
    if (this.professionalInfoForm.value.localite) formData.append('localite', this.professionalInfoForm.value.localite);
    if (this.professionalInfoForm.value.role) formData.append('position', this.professionalInfoForm.value.role);
    if (this.professionalInfoForm.value.personalPhone) formData.append('personal_phone', this.professionalInfoForm.value.personalPhone);
    if (this.professionalInfoForm.value.businessPhone) formData.append('business_phone', this.professionalInfoForm.value.businessPhone);
    if (this.professionalInfoForm.value.businessEmail) formData.append('business_email', this.professionalInfoForm.value.businessEmail);

    this.submitProfileData(formData);
  }

  changePassword() {
    this.userService.loaderActivate.next(true);

    let formData = new FormData();
    formData.append('current', this.passwordForm.value.current);
    formData.append('password', this.passwordForm.value.password);
    formData.append('password_confirmation', this.passwordForm.value.password_confirmation);

    this.axiosService
      .api_call_post(`user/password`, formData)
      .then((response) => {
        this.toastr.success(response.data.message, 'Success');
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.data.errors.password, 'Error');
        } else {
          console.log(error.message);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }
}
