import { OrganizationComponent } from './components/organization/organization.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { DossiersComponent } from './components/dossiers/dossiers.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Canvas4Component } from './components/canvas4/canvas4.component';
import { Canvas8Component } from './components/canvas8/canvas8.component';
import { RegisterComponent } from './components/register/register.component';
import { OrganizationAccessComponent } from './components/organization-access/organization-access.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, pathMatch: 'full' },
  { path: 'dossiers', component: DossiersComponent, pathMatch: 'full' },
  { path: 'profile', component: ProfilePageComponent, pathMatch: 'full' },
  { path: 'procedure/:id', component: Canvas8Component, pathMatch: 'full' },
  { path: 'description-du-processus', component: TimelineComponent, pathMatch: 'full' },
  { path: 'registrer', component: RegisterComponent, pathMatch: 'full' },
  { path: 'organization', component: OrganizationComponent, pathMatch: 'full' },
  { path: 'organizations', component: OrganizationAccessComponent, pathMatch: 'full' },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
