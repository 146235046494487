import { last } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { EditContactComponent } from '../edit-contact/edit-contact.component';
import * as _ from 'lodash';
import { StateService } from 'src/app/services/state.service';

interface DialogData {
  procedure: any;
  multiPerson: any;
  singlePerson: any;
}

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  personTypes = this.data.multiPerson;
  singlePersonTypes = this.data.singlePerson;
  constructor(
    private dialog: MatDialog,
    public listDialogRef: MatDialogRef<ContactListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private axiosCallService: AxiosCallService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public state: StateService
  ) {}

  ngOnInit(): void {
    this.data.procedure.people = this.data.procedure.people.sort((a, b) => a.sorting - b.sorting);
  }

  onNoClick(): void {
    this.listDialogRef.close();
  }

  addContact(): void {
    const dialogRef = this.dialog.open(EditContactComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: {
        contact: {},
        personTypes: this.personTypes
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const params = {
          procedure_id: this.data.procedure.id,
          organization_id: this.data.procedure.organization_id,
          civil_status: result.contact.civil_status
        };

        // nese ka shtuar titull
        if (result.contact.title) {
          Object.assign(params, { title: result.contact.title });
        }

        // nese ka shtuar emrin
        if (result.contact.firstname) {
          Object.assign(params, { firstname: result.contact.firstname });
        }

        // nese ka shtuar mbiemrin
        if (result.contact.lastname) {
          Object.assign(params, { lastname: result.contact.lastname });
        }

        // nese ka shtuar company
        if (result.contact.company) {
          Object.assign(params, { company: result.contact.company });
        }

        // nese ka shtuar email
        if (result.contact.email) {
          Object.assign(params, { email: result.contact.email });
        }

        // nese ka shtuar numer
        if (result.contact.phone) {
          Object.assign(params, { phone: result.contact.phone });
        }

        // nese ka shtuar adrese
        if (result.contact.address) {
          Object.assign(params, { address: result.contact.address });
        }

        // nese ka shtuar npa
        if (result.contact.npa) {
          Object.assign(params, { npa: result.contact.npa });
        }

        // nese ka shtuar localite
        if (result.contact.localite) {
          Object.assign(params, { localite: result.contact.localite });
        }

        this.axiosCallService
          .api_call_post(`organizations/${this.data.procedure.organization_id}/individuals`, params)
          .then((response) => {
            this.data.procedure.people.push(response.data.data);
            this.translate.get('contactAdded').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
            this.translate.get('invalidData').subscribe((res: string) => {
              this.toastr.error(res);
            });
            return;
          });
      }
    });
  }

  editContact(contact): void {
    const singlePerson = this.singlePersonTypes.find((el) => el.name === contact.civil_status);
    const dialogRef = this.dialog.open(EditContactComponent, {
      width: '700px',
      maxHeight: '90vh',
      data: {
        contact,
        personTypes: this.personTypes,
        singlePersonTypes: this.singlePersonTypes,
        singlePerson
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.contact.id) {
        const params = {
          civil_status: result.contact.civil_status
        };

        // nese ka shtuar titull
        if (result.contact.title) {
          Object.assign(params, { title: result.contact.title });
        }

        // nese ka shtuar emer
        if (result.contact.firstname) {
          Object.assign(params, { firstname: result.contact.firstname });
        }

        // nese ka shtuar mbiemer
        if (result.contact.lastname) {
          Object.assign(params, { lastname: result.contact.lastname });
        }

        // nese ka shtuar company
        if (result.contact.company) {
          Object.assign(params, { company: result.contact.company });
        }

        // nese ka shtuar email
        if (result.contact.email) {
          Object.assign(params, { email: result.contact.email });
        }

        // nese ka shtuar numer
        if (result.contact.phone) {
          Object.assign(params, { phone: result.contact.phone });
        }

        // nese ka shtuar adrese
        if (result.contact.address) {
          Object.assign(params, { address: result.contact.address });
        }

        // nese ka shtuar npa
        if (result.contact.npa) {
          Object.assign(params, { npa: result.contact.npa });
        }

        // nese ka shtuar localite
        if (result.contact.localite) {
          Object.assign(params, { localite: result.contact.localite });
        }

        this.axiosCallService
          .api_call_post(
            `organizations/${this.data.procedure.organization_id}/individuals/${contact.id}?_method=PATCH`,
            params
          )
          .then((response) => {
            this.translate.get('contactUpdated').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
            this.translate.get('invalidData').subscribe((res: string) => {
              this.toastr.error(res);
            });
            return;
          });
      }
    });
  }

  deleteContact(id): void {
    const thatPerson = this.data.procedure.people.filter((element) => element.id === id);
    const found = this.data.multiPerson.some(
      (personTypeElement) => personTypeElement.name === thatPerson[0].civil_status
    );
    if (found) {
      this.axiosCallService
        .api_call_delete(`organizations/${this.data.procedure.organization_id}/individuals/${id}`)
        .then((response) => {
          this.data.procedure.people = this.data.procedure.people.filter((contact) => contact.id != id);
          this.translate.get('deletedContact').subscribe((res: string) => {
            this.toastr.success(res);
          });
        })
        .catch((error) => {
          error.response ? console.log(error.response) : console.log(error);
        });
    } else {
      this.translate.get('cantDeletePerson').subscribe((res: string) => {
        this.toastr.error(res);
      });
    }
  }

  getSinglePersonTypeTranslation(contact): string {
    const personType = this.personTypes.find((el) => el.name === contact.civil_status);
    if (!personType) {
      return contact.civil_status;
    }

    return this.state.returnTranslation(personType.translations, 'name');
  }
}
