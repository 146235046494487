<div class="scroll p-3">
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <h1 translate>manageContacts</h1>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group">
        <label for="personId">ID</label>
        <ng-container *ngIf="singlePerson">
          <p class="m-0">
            {{ this.state.returnTranslation(singlePerson.translations, "name") }}
          </p>
        </ng-container>

        <select
          *ngIf="!singlePerson"
          type=""
          class="form-control custom-select"
          id="personId"
          required="required"
          [(ngModel)]="data.contact.civil_status"
        >
          <option *ngFor="let personType of personTypes" [value]="personType.name">
            {{ state.returnTranslation(personType.translations, "name") }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="titre" translate>title</label>
        <select type="" class="form-control custom-select" id="titre" [(ngModel)]="data.contact.title">
          <option selected></option>
          <option value="mr" translate>mr</option>
          <option value="mrs" translate>mrs</option>
          <option value="me">Me</option>
          <option value="dr">Dr</option>
        </select>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="nom" translate>name1</label>
        <input type="text" id="nom" class="form-control" [(ngModel)]="data.contact.firstname" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="prenom" translate>surname1</label>
        <input type="text" id="prenom" class="form-control" [(ngModel)]="data.contact.lastname" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="adresse" translate>address</label>
        <input
          type="text"
          id="adresse"
          required="required"
          class="form-control"
          [(ngModel)]="data.contact.address"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="societe" translate>company</label>
        <input type="text" id="societe" class="form-control" [(ngModel)]="data.contact.company" />
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label for="npa" translate>zipCode</label>
        <input type="text" id="npa" class="form-control" [(ngModel)]="data.contact.npa" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="localite" translate>location1</label>
        <input type="text" id="localite" class="form-control" [(ngModel)]="data.contact.localite" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="telephone" translate>phoneNumber</label>
        <input type="tel" id="telephone" class="form-control" [(ngModel)]="data.contact.phone" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="email" translate>email1</label>
        <input type="email" class="form-control" id="email" [(ngModel)]="data.contact.email" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="dateOfBirth" translate>dateOfBirth</label>
        <input type="date" id="dateOfBirth" class="form-control" [(ngModel)]="data.contact.date_of_birth" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="placeOfBirth" translate>placeOfBirth</label>
        <input type="text" id="placeOfBirth" class="form-control" [(ngModel)]="data.contact.place_of_birth" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="municipality" translate>municipality</label>
        <input type="text" class="form-control" id="municipality" [(ngModel)]="data.contact.municipality" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="dateOfDeath" translate>dateOfDeath</label>
        <input type="date" id="dateOfDeath" class="form-control" [(ngModel)]="data.contact.date_of_death" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label for="placeOfDeath" translate>placeOfDeath</label>
        <input type="text" class="form-control" id="placeOfDeath" [(ngModel)]="data.contact.place_of_death" />
      </div>
    </div>
  </div>
  <div class="text-right">
    <button (click)="onNoClick()" class="btn btn-secondary mr-2 rounded-pill" translate>cancel</button>
    <button (click)="save()" class="btn btn-primary rounded-pill" translate>save</button>
  </div>
</div>
