import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core'

interface DialogData {
  name: string;
  description: string;
}

@Component({
  selector: 'app-edit-identifier',
  templateUrl: './edit-identifier.component.html',
  styleUrls: ['./edit-identifier.component.scss']
})
export class EditIdentifierComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<EditIdentifierComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
