import { ListeProcedureComponent } from '../liste-procedure/liste-procedure.component';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { validateEmail } from 'src/app/functions';
import { User, ProcedureType, Step } from 'src/app/classes';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  user: User = this.data.user;
  procedure: ProcedureType = this.data.procedureType;
  ouvertureSteps: Step[] = this.procedure.steps
    .filter((step) => step.is_ouverture === true)
    .sort((a, b) => a.sorting - b.sorting);
  maxSteps = this.ouvertureSteps.length;
  step: any = 0;
  stepsParams = [];
  newSteps = [];
  nom = '';
  fileNo = '';
  group = null;
  userGroups = null;
  personSteps = [];
  textSteps = [];

  constructor(
    public dialog: MatDialog,
    private userService: UserService,
    private api: AxiosCallService,
    private toastr: ToastrService,
    private router: Router,
    public translate: TranslateService,
    public dialogRef: MatDialogRef<StepperComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.user.groups.length) {
      this.setGroup();
    }
  }

  setGroup(): void {
    this.userGroups = this.user.groups;
    const defaultGroup = this.userGroups.find((group) => 'Default Group' === group.name);
    this.group = defaultGroup ? defaultGroup.id : this.userGroups[0].id;
  }

  next(): void {
    this.step = this.step + 1;
    this.userService.reset.next({ params: this.stepsParams, index: this.step });
  }

  previous(): void {
    this.step = this.step - 1;
    this.userService.reset.next({ params: this.stepsParams, index: this.step });
  }

  close(): void {
    this.dialogRef.close();
    this.dialog.open(ListeProcedureComponent, {
      width: '80%',
      data: {
        user: this.user
      }
    });
  }

  getParams(event): void {
    this.stepsParams[event.index] = event;
  }

  returnErrors(): void {
    // no folder error
    if (this.nom === '') {
      this.translate.get('emptyNameFolder').subscribe((res: string) => {
        this.toastr.error(res);
      });
    }

    // no group error
    if (!this.group) {
      this.translate.get('groupRequired').subscribe((res: string) => {
        this.toastr.error(res);
      });
    }
  }

  // optional for later
  checkOptionalFields(parameters, ...args: Array<object>): void {
    args.forEach((element) => {
      if (element) {
        for (const [key, value] of Object.entries(element)) {
          // console.log(key, value);
          Object.assign(parameters, { fds: value });
        }
      }
      console.log(parameters);
    });
  }

  create(): void {
    this.returnErrors();
    const procedureParams = {
      organization_id: this.procedure.organization_id,
      procedure_type_id: this.procedure.id,
      slug: this.procedure.slug,
      number: this.fileNo,
      name: this.nom,
      group_id: this.group
    };

    this.personSteps = this.stepsParams.filter((step) => step.type === 'person');
    this.textSteps = this.stepsParams.filter((step) => step.type === 'text');

    this.userService.loaderActivate.next(true);
    this.createProcedure(procedureParams)
      .then((res) => {
        this.personSteps.forEach((person) => {
          const individualParams = {
            procedure_id: res.data.data.id,
            organization_id: this.procedure.organization_id,
            civil_status: person.name
          };

          if (person.title) {
            Object.assign(individualParams, { title: person.title });
          }

          if (person.company) {
            Object.assign(individualParams, { company: person.company });
          }

          if (person.firstname) {
            Object.assign(individualParams, { firstname: person.firstname });
          }

          if (person.lastname) {
            Object.assign(individualParams, { lastname: person.lastname });
          }

          if (person.address) {
            Object.assign(individualParams, { address: person.address });
          }

          if (person.npa) {
            Object.assign(individualParams, { npa: person.npa });
          }

          if (person.localite) {
            Object.assign(individualParams, { localite: person.localite });
          }

          if (person.phone) {
            Object.assign(individualParams, { phone: person.phone });
          }

          if (person.email && validateEmail(person.email)) {
            Object.assign(individualParams, { email: person.email });
          }

          if (person.date_of_birth) {
            Object.assign(individualParams, { date_of_birth: person.date_of_birth });
          }

          if (person.place_of_birth) {
            Object.assign(individualParams, { place_of_birth: person.place_of_birth });
          }

          if (person.municipality) {
            Object.assign(individualParams, { municipality: person.municipality });
          }

          if (person.date_of_death) {
            Object.assign(individualParams, { date_of_death: person.date_of_death });
          }

          if (person.place_of_death) {
            Object.assign(individualParams, { place_of_death: person.place_of_death });
          }

          this.addIndividual(individualParams)
            .then(() => {})
            .catch((error) => {
              if (error.response) {
                console.log(error.response);
              } else {
                console.log(error);
                this.toastr.error(error.message, 'Error');
              }
            })
            .finally(() => {});
        });

        this.getProcedure(res.data.data.id).then((procedureData) => {
          console.log(procedureData);
          this.textSteps.forEach((text) => {
            const textStep = procedureData.data.data.steps.find((step) => {
              if (step.ouverture_type === 'text' && step.is_ouverture && step.name === text.name) {
                return step;
              }
            });

            const currentText = this.stepsParams.find((step) => step.name === textStep.name);

            const textParams = {
              text_value: currentText.text ? currentText.text : '<p></p>',
              numeric_value: 123,
              procedure_step_id: textStep.id,
              procedure_id: res.data.data.id,
              done: 0,
              waiting: 0
            };

            this.addText(res.data.data.id, textStep.id, textParams)
              .then(() => {})
              .catch((error) => {
                if (error.response) {
                  console.log(error.response);
                } else {
                  console.log(error);
                  this.toastr.error(error.message, 'Error');
                }
              })
              .finally(() => this.userService.loaderActivate.next(false));
          });
        });
        this.dialogRef.close();
        void this.router.navigate(['/procedure', res.data.data.id]);

        this.translate.get('successfullyCreatedProcedure').subscribe((result: string) => {
          this.toastr.success(result);
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      })
      .finally(() => this.userService.loaderActivate.next(false));
  }

  createProcedure(params): Promise<any> {
    return this.api.api_call_post(`organizations/${this.procedure.organization_id}/procedures`, params);
  }

  addIndividual(params): Promise<any> {
    return this.api.api_call_post(`organizations/${this.procedure.organization_id}/individuals`, params);
  }

  addExtraStep(id, params): Promise<any> {
    return this.api.api_call_post(
      `organizations/${this.procedure.organization_id}/procedures/${id}/steps`,
      params
    );
  }

  addText(procedureId, id, params): Promise<any> {
    return this.api.api_call_post(
      `organizations/${this.procedure.organization_id}/procedures/${procedureId}/steps/${id}/data`,
      params
    );
  }

  getProcedure(id): Promise<any> {
    return this.api.api_call_get(`organizations/${this.procedure.organization_id}/procedures/${id}`);
  }
}
