<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]> 
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="label" style="color: #707070; font-weight: lighter" translate>selectRole</label>
    <select class="custom-select" [(ngModel)]="data.role">
      <option value="member" translate>member</option>
      <option value="admin" translate>administrator</option>
    </select>
  </div>

  <div class="text-center">
    <button class="btn btn-secondary rounded-pill mr-3" (click)="onNoClick()" translate>cancel</button>
    <button class="btn btn-primary rounded-pill" [mat-dialog-close]="data" translate>save</button>
  </div>
</div>