import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'app-canvas12',
  templateUrl: './canvas12.component.html',
  styleUrls: ['./canvas12.component.scss']
})
export class Canvas12Component implements OnInit {
  concilation: FormGroup;
  @Input() step: any;
  @Input() current: any;
  @Input() max: any;
  @Output() parameters = new EventEmitter<any>();
  config = {
    toolbar: [
      ['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }]
    ]
  };
  public text = '';
  constructor(public dialog: MatDialog, private user: UserService) {
    this.user.reset.subscribe(d => {
      if (!d.s[d.n]) {
        this.text = '';
      } else {
        this.text = d.s[d.n].text;
      }
    });
  }
  ngOnInit(): void {
    // const p = {
    //   text: this.text, no: this.current
    // };
    // this.parameters.emit(p);
    this.concilation = new FormGroup({
      tekst: new FormControl(null, [Validators.required, Validators.minLength(20)])
    });
  }
  onChange(): void {
    const p = {
      text: this.text, no: this.current
    };
    this.parameters.emit(p);
  }

  // openDialog(){
  //   this.dialog.closeAll();
  //   this.dialog.open(Canvas16Component);
  // }
  // back(){
  //   this.dialog.closeAll();
  //   this.dialog.open(Canvas11Component);
  // }
}
