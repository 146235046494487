<div>
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true" id="close-button">&times;</span>
  </button>
  <div class="text-center mb-3">
    <h1 translate>forgotPassword</h1>
    <p translate>enterEmail</p>
  </div>
  <div class="form-group">
    <label for="email" style="color: #707070; font-weight: lighter" translate>email</label>
    <input type="email" class="form-control" id="email" [(ngModel)]="email" />
  </div>
  <div class="text-center mt-2">
    <button class="btn btn-primary rounded-pill" (click)="save()" translate>save</button>
  </div>
</div>
