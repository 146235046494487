<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]> 
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="label" style="color: #707070; font-weight: lighter" translate>selectUser</label>
    <select class="custom-select mt-2" [(ngModel)]="data.user">
      <option value="select" selected>Select user...</option>
      <option *ngFor="let user of organization.users" [value]="user.id">
        {{ user.firstname && user.lastname ? user.firstname + ' ' + user.lastname : user.email }}
      </option>
    </select>
  </div>

  <div class="text-center">
    <button class="btn btn-secondary rounded-pill mr-3" (click)="onNoClick()" translate>
      cancel
    </button>
    <button class="btn btn-primary rounded-pill" [mat-dialog-close]="data" translate>
      save
    </button>
  </div>
</div>