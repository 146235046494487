import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDialogModule } from '@angular/material/dialog';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClientXsrfModule, HttpClient } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { QuillModule } from 'ngx-quill';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoginComponent } from './components/login/login.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DossiersComponent } from './components/dossiers/dossiers.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FooterComponent } from './components/footer/footer.component';
import { Canvas12Component } from './components/canvas12/canvas12.component';
import { Canvas4Component } from './components/canvas4/canvas4.component';
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ListeProcedureComponent } from './components/liste-procedure/liste-procedure.component';
import { Canvas27Component } from './components/canvas27/canvas27.component';
import { AttenteComponent } from './components/attente/attente.component';
import { ConfigurerNProcedureComponent } from './components/configurer-n-procedure/configurer-n-procedure.component';
import { Canvas8Component } from './components/canvas8/canvas8.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { RegisterComponent } from './components/register/register.component';
import { OuvertureComponent } from './components/ouverture/ouverture.component';
import { AddLinkComponent } from './components/add-link/add-link.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { OrganizationComponent } from './components/organization/organization.component';
import { NewGroupComponent } from './components/new-group/new-group.component';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { OrganizationAccessComponent } from './components/organization-access/organization-access.component';
import { ConfirmUploadComponent } from './components/confirm-upload/confirm-upload.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SortDirective } from './directive/sort.directive';
import { ChangeRoleDialogComponent } from './components/change-role-dialog/change-role-dialog.component';
import { UsersAccessComponent } from './components/users-access/users-access.component';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
// import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxFileDragDropModule } from 'ngx-file-drag-drop';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { EditContactComponent } from './components/edit-contact/edit-contact.component';

import { MatNativeDateModule } from '@angular/material/core';
import { EditIdentifierComponent } from './components/edit-identifier/edit-identifier.component';
import { AreYouSureComponent } from './components/are-you-sure/are-you-sure.component';

import { DatePipe } from '@angular/common';
import { WordFileDialogComponent } from './components/word-file-dialog/word-file-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TimelineComponent,
    DashboardComponent,
    DossiersComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    Canvas12Component,
    ProfilePageComponent,
    ListeProcedureComponent,
    Canvas27Component,
    AttenteComponent,
    ConfigurerNProcedureComponent,
    Canvas4Component,
    Canvas8Component,
    StepperComponent,
    RegisterComponent,
    SortDirective,
    OuvertureComponent,
    AddLinkComponent,
    ForgotPasswordComponent,
    OrganizationComponent,
    NewGroupComponent,
    AddUserDialogComponent,
    OrganizationAccessComponent,
    ConfirmUploadComponent,
    ChangeRoleDialogComponent,
    UsersAccessComponent,
    VerifyEmailComponent,
    ContactListComponent,
    EditContactComponent,
    EditIdentifierComponent,
    AreYouSureComponent,
    WordFileDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFileDragDropModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    MatGridListModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    QuillModule.forRoot(),
    ImageCropperModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    Ng2SearchPipeModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatButtonModule,
    MatFormFieldModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      closeButton: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: 'toast-top-right',
      timeOut: 5000
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateHttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function translateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
