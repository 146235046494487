import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/classes';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ApiCallService } from 'src/app/services/api-call.service';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { VerifyEmailComponent } from '../verify-email/verify-email.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  user: User;
  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiCallService,
    private axiosService: AxiosCallService,
    private toastr: ToastrService,
    public translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', {
        updateOn: 'change',
        validators: [
          Validators.email,
          Validators.required
          // tslint:disable-next-line: max-line-length
          // Validators.pattern(
          //   '^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$'
          // )
        ]
      }),
      password: new FormControl('', {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(8)]
      }),
      password_confirmation: new FormControl('', {
        updateOn: 'change',
        validators: [Validators.required, Validators.minLength(8)]
      })
    });

    this.user = this.form.value;
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    const params = {
      email: this.form.value.email,
      password: this.form.value.password,
      password_confirmation: this.form.value.password_confirmation
    };

    this.loading = true;

    this.axiosService
      .register(params)
      .then((res: any) => {
        this.loading = false;

        this.translate.get('registered').subscribe((res: string) => {
          this.toastr.success(res);
        });

        this.router.navigate(['/login']);
        this.dialog.open(VerifyEmailComponent, {
          width: '500px',
          data: {
            email: params.email
          }
        });
      })
      .catch((err) => {
        this.loading = false;

        if (params.password !== params.password_confirmation) {
          this.translate.get('passNotMach').subscribe((res: string) => {
            this.toastr.error(res);
          });
          return;
        }

        if (params.password.length < 8) {
          this.translate.get('passLengthError').subscribe((res: string) => {
            this.toastr.error(res);
          });
          return;
        }

        if (params !== null) {
          if (err.response?.data?.errors?.email) {
            console.log('data.errors.machin', err.response.data.errors.email);
            this.translate.get(err.response.data.errors.email[0]).subscribe((res: string) => {
              this.toastr.error(res);
            });
            return;
          }
          this.translate.get('invalidData').subscribe((res: string) => {
            this.toastr.error(res);
          });
          return;
        }
      });
  }

  changeLanguage(lang) {
    localStorage.setItem('currentLanguage', lang);
    this.translate.use(lang);
    // this.translate.setDefaultLang(lang)
  }
  getLang(language: string): string {
    if (!language) language = 'fr';
    let result = '';

    this.translate.get(language).subscribe((res) => {
      if (res) result = res;
    });

    return result;
  }

  getFlag(language: string): string {
    if (!language) language = 'fr';

    switch (language) {
      case 'fr':
        return '../../assets/images/fr.svg';
        break;

      case 'de':
        return '../../assets/images/de.svg';
        break;

      case 'en':
        return '../../assets/images/en.svg';
        break;

      default:
        break;
    }
  }
  notMatching() {
    if (this.form.value.password !== this.form.value.password_confirmation) {
      return true;
    } else {
      return false;
    }
  }
}
