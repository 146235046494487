import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpClientXsrfModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor,
  HttpParams
} from '@angular/common/http';
// import { Users } from '../classes/users';
// import { map, catchError } from 'rxjs/operators';
// import { error } from 'console';
// import { from, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  private APIPATH: string;
  private baseUrl = 'http://localhost:8000/';

  protected httpOptions = {
    withCredentials: true
  };

  constructor(private http: HttpClient) {}

  get(endpoint: string, params?: any, reqOpts?: any) {
    this.APIPATH = this.baseUrl;
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams()
      };
    }
    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      // tslint:disable-next-line: prefer-const tslint:disable-next-line: forin
      for (let k in params) {
        reqOpts.params.set(k, params[k]);
      }
    }
    console.log(this.baseUrl + endpoint);
    return this.http.get(this.baseUrl + endpoint, reqOpts);
  }

  post(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.baseUrl + endpoint, body, reqOpts);
  }
  post_paginate(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.baseUrl + endpoint, body, reqOpts);
  }

  delete(endpoint: string, reqOpts?: any) {
    return this.http.delete(this.baseUrl + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.baseUrl + endpoint, body, reqOpts);
  }

  logIn(params?: any) {
    // const headers1 = new HttpHeaders({
    //   'content-type': 'application/json'
    // });

    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    };

    return new Promise((resolve, reject) => {
      this.get('api/csrf-cookie').subscribe(res => {
        this.post('login', params, httpPostOptions).subscribe(
          data => { 
            resolve(data);
          },
          err => {
            reject(err);
            // this.common.toastMsg(err.error.message, 'bottom', 'danger');
          }
        );
      });
    });
  }
  signUp(params?: any) {
    return new Promise((resolve, reject) => {
      this.post('register', params).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  signUpCheckout(params?: any) {
    return new Promise((resolve, reject) => {
      this.post('auth/signupCheckout', params).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  api_call_post(link, params?: any) {
    // const headers = new HttpHeaders({
    //   // Authorization: 'Bearer ' + this.token,
    //   'Content-Type': 'application/json',
    // });
    const httpPostOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    };

    return new Promise((resolve, reject) => {
      this.post(link, params, httpPostOptions).subscribe(
        data => {
          resolve(data);
        },
        err => {
          reject(err);
        }
      );
    });
  }
  api_call_post_paginate(link, params?: any) {
    const headers = new HttpHeaders({
      // Authorization: 'Bearer ' + this.token,
      'Content-Type': 'application/json'
    });
    return new Promise((resolve, reject) => {
      this.post_paginate(link, params, { headers }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (err.status === 401) {
            console.log('401');
          } else {
            reject(err);
          }
        }
      );
    });
  }
  api_call_get(link, params?: any) {
    const headers1 = new HttpHeaders({
      // Authorization: 'Bearer ' + this.token,
      'content-type': 'application/json'
    });
    return new Promise((resolve, reject) => {
      this.get(link, params, { headers: headers1 }).subscribe(
        data => {
          resolve(data);
        },
        err => {
          if (err.status === 401) {
            console.log('401');
          } else {
            reject(err);
          }
        }
      );
    });
  }
}

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     let requestToForward = req;
//         let token = HttpXsrfTokenExtractor;
//         console.log(HttpXsrfTokenExtractor)
//         if (token !== null) {
//             requestToForward = req.clone({ setHeaders: { "X-XSRF-TOKEN": token.toString() } });
//         }
//         return next.handle(requestToForward);
// }
// getToken(){
//   // this.httpclient.wi
//   // this.httpclient.get('http://localhost:8000/api/csrf-cookie').subscribe((data: any) => {
//   //   console.log(data.get('XRF-token'));
//   // })
//   return this.httpclient.get("http://localhost:8000/api/csrf-cookie", {withCredentials: true})
//   .pipe(
//     map((data: any)=>{
//       console.log('data sent', data);
//       return data;
//     }),catchError(error =>{
//       console.log('failed');
//       return throwError('Something went wrong');
//     })
//     )
// }
// getRealToken() {
//   // this.httpclient.wi
//   this.httpclient.get("http://localhost:8000/api/csrf-cookie").subscribe((response) => {
//     this.httpclient
//       .post("http://localhost:8000/login", {
//         email: "eritislami@gmail.com",
//         password: "erit1234"
//       })
//       .subscribe((res) => {
//         console.log(res);
//         // this.$router.push({ name: "Dashboard" });
//       });
//   });
// }
