<div class="organization-page">
  <div class="container">
    <div class="text-center py-5">
      <img src="../../assets/images/logo_stepline.png" height="60px" />
    </div>
    <div class="card">
      <div class="card-body p-sm-4 p-2">
        <div class="d-flex flex-sm-row flex-column justify-content-between my-3">
          <h1 translate>joinOrganization</h1>
          <div class="d-flex align-items-center">
            <div class="dropdown mr-2">
              <a
                class="nav-link dropdown-toggle"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img [src]="getFlag(translate.currentLang)" class="mb-1 mr-1" />
                {{ getLang(translate.currentLang) }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li
                  *ngFor="let lang of translate.getLangs()"
                  (click)="changeLanguage(lang)"
                  class="dropdown-item"
                  translate
                >
                  <img [src]="getFlag(lang)" class="mb-1 mr-1" /> {{ lang }}
                </li>
              </ul>
            </div>
            <span
              (click)="logout()"
              class="material-icons text-danger"
              style="align-self: center; cursor: pointer"
            >
              logout
            </span>
          </div>
        </div>

        <div class="table-responsive mb-3">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" translate>name1</th>
                <th scope="col" translate>owner</th>
                <th scope="col" translate>created</th>
                <th scope="col" translate>actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let organization of organizations">
                <td>{{ organization.name }}</td>
                <td>
                  <img
                    class="rounded-circle"
                    style="object-fit: cover; object-position: center"
                    width="25"
                    height="25"
                    [src]="getAllOwnerAvatar(organization.id)"
                  />
                  {{ getAllOwner(organization.id) }}
                </td>
                <td>{{ organization.created_at | date: "dd/MM/yyyy" }}</td>
                <td>
                  <p class="lead m-0 p-0">
                    <span
                      *ngIf="user.requests.length < 1"
                      (click)="apply(organization.id)"
                      class="btn btn-sm btn-success"
                      style="cursor: pointer"
                      >Apply</span
                    >
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="user" class="mb-3">
          <h1 translate>pendingRequests</h1>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col" translate>name1</th>
                  <th scope="col" translate>owner</th>
                  <th scope="col" translate>created</th>
                  <th scope="col" translate>status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let request of user.requests">
                  <td>{{ getOrganizationName(request.organization_id) }}</td>
                  <td>
                    <img
                      class="rounded-circle"
                      style="object-fit: cover; object-position: center"
                      width="25"
                      height="25"
                      [src]="getOwnerAvatar(request.organization_id)"
                    />
                    {{ getOwner(request.organization_id) }}
                  </td>
                  <td>
                    {{ getOrganizationCreated(request.organization_id) | date: "dd/MM/yyyy" }}
                  </td>
                  <td>
                    <span class="btn btn-sm btn-warning">{{ request.status ? "Pending" : "Accepted" }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div class="mb-3">
          <p class="lead text-danger">Developer testing, will be disable in final version</p>
          <div class="border border-danger p-4">
            <h1 translate>createOrganization</h1>
            <div class="form-inline">
              <div class="form-group mr-2">
                <label for="organizationName" translate>orgName</label>
                <input
                  id="organizationName"
                  type="text"
                  [(ngModel)]="organizationName"
                  class="form-control d-inline ml-2"
                />
              </div>
              <button class="btn btn-primary rounded-pill d-inline" (click)="submit()" translate>save</button>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <footer class="footer text-dark text-center py-5">
      <small translate>loginFooter</small>
    </footer>
  </div>
</div>
