import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core'

interface DialogData {
  files: any;
  confirm: boolean;
}

@Component({
  selector: 'app-confirm-upload',
  templateUrl: './confirm-upload.component.html',
  styleUrls: ['./confirm-upload.component.scss'],
})
export class ConfirmUploadComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
