import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  email = '';
  resetEmail: FormGroup;

  constructor(
    private dialog: MatDialog,
    private axiosCall: AxiosCallService,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>
  ) { }

  ngOnInit(): void {
    this.resetEmail = new FormGroup({
      email: new FormControl('', [Validators.email, Validators.required])
    });
  }

  save() {
    this.axiosCall
      .api_call_post_base('/password/email', { email: this.email })
      .then((response: any) => {
        this.toastr.success(response.data.message, 'Success');
        this.dialogRef.close();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.data.errors.email, 'Error');
        } else {
          console.log(error);
        }
      });
  }
}
