<button type="button" class="close" aria-label="Close" [mat-dialog-close]>
  <span aria-hidden="true">&times;</span>
</button>
<h1 translate>users</h1>
<div class="scroll">
  <ul class="list-unstyled pr-2">
    <li *ngFor="let user of data.users" class="mb-3 d-flex flex-row flex-wrap justify-content-between">
      <div>
        <img
          [src]="baseUrl + user.profile_pic"
          class="img-fluid rounded-circle mr-1"
          style="width: 25px; height: 25px; object-fit: cover; object-position: center"
        />
        <span>{{ user.firstname }} {{ user.lastname }}</span>
      </div>
      <span class="badge badge-primary p-2">{{ user.email }}</span>
    </li>
  </ul>
</div>
