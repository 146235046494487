import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'

interface DialogData {
  procedure: any;
}
@Component({
  selector: 'app-canvas27',
  templateUrl: './canvas27.component.html',
  styleUrls: ['./canvas27.component.scss']
})
export class Canvas27Component implements OnInit {

  constructor(private route: Router, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
   }

   
  ngOnInit(): void {
  }
  goTo(){
    this.route.navigate(['dossiers']);
    this.dialog.closeAll();
  }
}
