<div class="container p-0">
  <div class="text-primary justify-content-center text-bold d-flex">
    <h1>{{ "procedureOf" | translate }} {{ procedure.slug }} {{ stepIndex + 1 }}/{{ steps.length }}</h1>
  </div>

  <div class="canvas4-form" *ngIf="steps[stepIndex].ouverture_type === 'person'">
    <p class="text-muted">{{ state.returnTranslation(steps[stepIndex].translations, "name") }} ({{ 1 }})</p>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="title" translate>title</label>
        <select class="form-control custom-select" id="title" [(ngModel)]="title" (change)="onChange()">
          <option selected></option>
          <option value="mr" translate>mr</option>
          <option value="mrs" translate>mrs</option>
          <option value="me">Me</option>
          <option value="dr">Dr</option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="company" translate>company</label>
        <input
          type="text"
          class="form-control"
          id="company"
          [(ngModel)]="company"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="firstname" translate>name1</label>
        <input
          type="text"
          class="form-control"
          id="firstname"
          [(ngModel)]="firstname"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="lastname" translate>surname1</label>
        <input
          type="text"
          class="form-control"
          id="lastname"
          [(ngModel)]="lastname"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label for="address" translate>address</label>
        <input
          type="text"
          class="form-control"
          id="address"
          [(ngModel)]="address"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="npa" translate>zipCode</label>
        <input
          type="postal"
          class="form-control"
          id="npa"
          [(ngModel)]="npa"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="localite" translate>location1</label>
        <input
          type="text"
          class="form-control"
          id="localite"
          [(ngModel)]="localite"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="phone" translate>phoneNumber</label>
        <input
          type="tel"
          class="form-control"
          id="phone"
          [(ngModel)]="phone"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="email" translate>email1</label>
        <input
          type="email"
          class="form-control"
          id="email"
          [(ngModel)]="email"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="dateOfBirth" translate>dateOfBirth</label>
        <input
          type="date"
          class="form-control"
          id="dateOfBirth"
          [(ngModel)]="date_of_birth"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="municipality" translate>municipality</label>
        <input
          type="text"
          class="form-control"
          id="municipality"
          [(ngModel)]="municipality"
          (keyup)="onChange()"
          debounce="50"
        />
      </div>
    </div>
    <ng-container *ngIf="stepIndex==0 && [2, 4].includes(procedure.id)">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="placeOfBirth{{ i }}" translate>placeOfBirth</label>
          <input
            type="text"
            class="form-control"
            id="placeOfBirth{{ i }}"
            [(ngModel)]="place_of_birth"
            (keyup)="onChange()"
            debounce="50"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="dateOfDeath" translate>dateOfDeath</label>
          <input
            type="date"
            class="form-control"
            id="dateOfDeath"
            [(ngModel)]="date_of_death"
            (keyup)="onChange()"
            debounce="50"
          />
        </div>
        <div class="form-group col-md-6">
          <label for="placeOfDeath" translate>placeOfDeath</label>
          <input
            type="text"
            class="form-control"
            id="placeOfDeath"
            [(ngModel)]="place_of_death"
            (keyup)="onChange()"
            debounce="50"
          />
        </div>
      </div>
    </ng-container>
    <!-- Added extra persons -->
    <div *ngIf="currentStep?.extra?.length > 0">
      <div *ngFor="let person of currentStep.extra; let i = index">
        <div class="canvas4-form">
          <p class="text-muted">
            {{ state.returnTranslation(steps[stepIndex].translations, "name") }} ({{ i + 2 }})
          </p>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="title{{ i }}" translate>title</label>
              <select
                type=""
                class="form-control custom-select"
                id="title{{ i }}"
                [(ngModel)]="person.title"
                (change)="onChange()"
              >
                <option selected></option>
                <option value="mr" translate>mr</option>
                <option value="mrs" translate>mrs</option>
                <option value="me">Me</option>
                <option value="dr">Dr</option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label for="company{{ i }}" translate>company</label>
              <input
                type="text"
                class="form-control"
                id="company{{ i }}"
                [(ngModel)]="person.company"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="firstname{{ i }}" translate>name1</label>
              <input
                type="text"
                class="form-control"
                id="firstname{{ i }}"
                [(ngModel)]="person.firstname"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="lastname{{ i }}" translate>surname1</label>
              <input
                type="text"
                class="form-control"
                id="lastname{{ i }}"
                [(ngModel)]="person.lastname"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="address{{ i }}" translate>address</label>
              <input
                type="text"
                class="form-control"
                id="address{{ i }}"
                [(ngModel)]="person.address"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="npa{{ i }}" translate>zipCode</label>
              <input
                type="postal"
                class="form-control"
                id="npa{{ i }}"
                [(ngModel)]="person.npa"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="localite{{ i }}" translate>location1</label>
              <input
                type="text"
                class="form-control"
                id="localite{{ i }}"
                [(ngModel)]="person.localite"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="phone{{ i }}" translate>phoneNumber</label>
              <input
                type="tel"
                class="form-control"
                id="phone{{ i }}"
                [(ngModel)]="person.phone"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="email{{ i }}" translate>email1</label>
              <input
                type="email"
                class="form-control"
                id="email{{ i }}"
                [(ngModel)]="person.email"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-6">
              <label for="dateOfBirth{{ i }}" translate>dateOfBirth</label>
              <input
                type="date"
                class="form-control"
                id="dateOfBirth{{ i }}"
                [(ngModel)]="person.date_of_birth"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
            <div class="form-group col-md-6">
              <label for="municipality{{ i }}" translate>municipality</label>
              <input
                type="text"
                class="form-control"
                id="municipality{{ i }}"
                [(ngModel)]="person.municipality"
                (keyup)="onChange()"
                debounce="50"
              />
            </div>
          </div>
          <ng-container *ngIf="stepIndex==0 && [2, 4].includes(procedure.id)">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="placeOfBirth{{ i }}" translate>placeOfBirth</label>
                <input
                  type="text"
                  class="form-control"
                  id="placeOfBirth{{ i }}"
                  [(ngModel)]="person.place_of_birth"
                  (keyup)="onChange()"
                  debounce="50"
                />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="dateOfDeath{{ i }}" translate>dateOfDeath</label>
                <input
                  type="date"
                  class="form-control"
                  id="dateOfDeath{{ i }}"
                  [(ngModel)]="person.date_of_death"
                  (keyup)="onChange()"
                  debounce="50"
                />
              </div>
              <div class="form-group col-md-6">
                <label for="placeOfDeath{{ i }}" translate>placeOfDeath</label>
                <input
                  type="text"
                  class="form-control"
                  id="placeOfDeath{{ i }}"
                  [(ngModel)]="person.place_of_death"
                  (keyup)="onChange()"
                  debounce="50"
                />
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="currentStep.multiple">
      <a (click)="addPerson()" class="btn btn-link text-primary" style="margin-left: -14px" translate>
        <button
          type="button"
          class="btn btn-primary rounded-circle"
          style="padding-left: 13px; padding-right: 13px"
        >
          +
        </button>
        addNewForm
      </a>
    </div>
  </div>

  <div id="quill-forma" class="quill-form" *ngIf="currentStep.ouverture_type === 'text'">
    <div class="form-group">
      <label for="text" style="color: #63799b">{{
        state.returnTranslation(currentStep.translations, "name")
      }}</label>
      <quill-editor [modules]="config" id="text" [(ngModel)]="text" (keyup)="onChange()" [placeholder]="'textEditorPlaceholder' | translate"> </quill-editor>
    </div>
  </div>
</div>
