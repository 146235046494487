import { validateEmail } from 'src/app/functions';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.scss']
})
export class EditContactComponent implements OnInit {
  filled: Boolean = false;
  personTypes = this.data.personTypes;
  singlePersonTypes = this.data.singlePersonTypes;
  singlePerson = this.data.singlePerson;
  constructor(
    public dialogRef: MatDialogRef<EditContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toastr: ToastrService,
    public translate: TranslateService,
    public state: StateService
  ) {}

  ngOnInit(): void {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  save(): void {
    // Check if mandatory fields are empty
    if (!this.data.contact.civil_status) {
      this.translate.get('personEmpty').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return;
    }
    if (this.data.contact.email && !validateEmail(this.data.contact.email)) {
      this.data.contact.email = '';
      this.translate.get('invalidEmail').subscribe((res: string) => {
        this.toastr.error(res);
      });
      return;
    }
    // Close and send data back
    this.dialogRef.close(this.data);
  }
}
