<div class="container">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="d-flex">
        <h1 id="head">{{"validerStepTitle" | translate}}{{ data.procedure.type.slug }}!</h1>
    </div>
    <p id="content" translate>validerStepText</p>
    <div class="text-right">
        <button class="btn btn-primary rounded-pill" style="padding-left: 20px;
        padding-right: 20px;" (click)="goTo()" translate>goToDossiersButton</button>
    </div>
</div>