
import { ConfigurerNProcedureComponent } from './../configurer-n-procedure/configurer-n-procedure.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { UserService } from 'src/app/services/user.service';
import { StepperComponent } from '../stepper/stepper.component';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from 'src/app/services/state.service';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';

@Component({
  selector: 'app-liste-procedure',
  templateUrl: './liste-procedure.component.html',
  styleUrls: ['./liste-procedure.component.scss']
})
export class ListeProcedureComponent implements OnInit {
  user = this.data.user
  baseUrl: String = '';
  isAdmin: Boolean = false;
  organization = null;
  procedureTypes = [];
  loading = false;
  hasGroup: Boolean = false;

  constructor(
    private dialog: MatDialog,
    private service: AxiosCallService,
    private userService: UserService,
    private state: StateService,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<ListeProcedureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.baseUrl = this.service.baseUrl;
  }

  ngOnInit() {
    this.userService.loaderActivate.next(true);
    if (this.user.id) {
      if (this.user.roles.length) this.isAdmin = true;
      this.user.groups.length ? (this.hasGroup = true) : (this.hasGroup = false);
      this.organization = this.user.organization_id;
    }

    this.service.getProcedureType(this.organization).then((result) => {
      result.data.data = result.data.data.filter((procedureType) => procedureType.is_visible)
      return result;
    }).then((result) => {
      const sortedProcedureTypes = _.orderBy(result.data.data, 'id', 'desc');
      this.procedureTypes = sortedProcedureTypes;
    }).catch(error => error.resonse ? console.log(error.response) : console.log(error))
      .finally(() => this.userService.loaderActivate.next(false));
  }

  onSubmit() {
    this.dialogRef.close();
    this.userService.editId = 0;
    let configProcedureDialog = this.dialog.open(ConfigurerNProcedureComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        user: this.user,
        editId: 0,
        procedure: null
      }
    });

    configProcedureDialog.afterClosed().subscribe((result) => {
      this.userService.overtures = [];
      this.userService.links = [];
    });
  }

  openProcedure(data) {
    this.dialogRef.close();
    this.userService.currentProcedure = data;
    this.dialog.open(StepperComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        user: this.user,
        procedureType: data
      }
    });
  }

  editProcedure(data) {
    this.dialogRef.close();

    this.userService.editId = data.id;
    this.userService.editStep = data;

    let temp = this.userService.editStep.steps.filter((step) => step.is_ouverture);
    this.userService.overtures = temp;
    let temp2 = this.userService.editStep.steps.filter((step) => !step.is_ouverture);
    temp2.forEach((element, ind) => {
      this.userService.links[ind] = element.links;
    });

    let editProcedureDialog = this.dialog.open(ConfigurerNProcedureComponent, {
      panelClass: 'custom-dialog-container',
      data: {
        user: this.user,
        editId: data.id,
        procedure: data
      }
    });

    editProcedureDialog.afterClosed().subscribe((result) => {
      this.userService.overtures = [];
      this.userService.links = [];
      this.userService.editId = 0;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
