<div class="container">
  <div class="pl-3 pr-3">
    <h1 class="text-center text-bold" style="color: #2C77FF;">
      Conclusions {{current+1}}/{{max}}
    </h1>
    <div id="quill-forma" class="quill-form">
      <div class="form-group">
        <label for="tekst" style="color: #63799B;" translate>c12Subtitle</label>
        <quill-editor [modules]="config" [(ngModel)]="text" (keyup)="onChange()">
        </quill-editor>
      </div>
    </div>
  </div>
</div>