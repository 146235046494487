import { UserService } from './../../services/user.service';
import { StateService } from './../../services/state.service';
import { ToastrService } from 'ngx-toastr';
import { AxiosCallService } from './../../services/axios-call.service';
import { Router } from '@angular/router';
import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() event: EventEmitter<string> = new EventEmitter();
  user: any;
  imgUrl: string = '../../assets/images/default-profile-pic.png';
  baseUrl = this.axiosService.baseUrl;

  constructor(
    public router: Router,
    private axiosService: AxiosCallService,
    private toastr: ToastrService,
    private state: StateService,
    public translate: TranslateService,
    private userService: UserService
  ) {
    this.state.user.subscribe((res) => {
      if (res.id) {
        this.user = res;
      }
    });
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.userService.loaderActivate.next(true);
    this.axiosService
      .api_call_post_base('/logout', {})
      .then((res: any) => {
        this.translate.get('loggedOut').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((err) => {
        err.response ? console.log(err.response) : console.error(err);
      })
      .finally(() => {
        this.router.navigate(['/login']);
        this.state.clearState();
        this.userService.loaderActivate.next(false);

        // Fix
        window.location.reload();
      });
  }

  changeLanguage(lang) {
    localStorage.setItem('currentLanguage', lang);
    this.translate.use(lang);
    // this.translate.setDefaultLang(lang)
  }

  getLang(language: string): string {
    if (!language) language = 'fr';
    let result = '';

    this.translate.get(language).subscribe((res) => {
      if (res) result = res;
    });

    return result;
  }

  getFlag(language: string): string {
    if (!language) language = 'fr';

    switch (language) {
      case 'fr':
        return '../../assets/images/fr.svg';
        break;

      case 'de':
        return '../../assets/images/de.svg';
        break;

      case 'en':
        return '../../assets/images/en.svg';
        break;

      default:
        break;
    }
  }
}
