<div class="scroll">
  <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="container-fluid">
    <h1 class="text-primary" translate>editProcedure</h1>
    <div class="row">
      <div *ngIf="isAdmin" class="col-12 col-lg-6 col-xl-4 my-2" (click)="onSubmit()">
        <div class="card" style="background-color: #f3f5f7; cursor: pointer">
          <div class="card-body text-center">
            <img class="img-fluid" src="../../../assets/images/add-procedure.png" />
            <p class="text-primary m-0" translate>newProcedure</p>
          </div>
        </div>
      </div>
      <div *ngIf="!hasGroup" class="col-12 my-2">
        <p class="lead text-danger" translate>noGroupsMessage</p>
      </div>
      <ng-container *ngIf="hasGroup">
        <div
          *ngFor="let procedureType of procedureTypes; let i = index"
          class="col-12 col-lg-6 col-xl-4 my-2"
        >
          <div class="card">
            <div *ngIf="isAdmin" class="d-flex flex-row-reverse flex-wrap">
              <button
                (click)="editProcedure(procedureType)"
                class="btn btn-sm btn-light m-1 justify-content-end"
                translate
              >
                edit
              </button>
            </div>
            <div class="card-body text-center" id="{{ i }}">
              <img
                [src]="baseUrl + procedureType.picto"
                onerror="this.src='../../assets/images/default-profile-pic.png'"
                style="cursor: pointer"
                (click)="openProcedure(procedureType)"
              />
              <p class="text-white m-0">{{ procedureType.slug }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
