import { AxiosCallService } from './axios-call.service';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class StateService implements OnInit {
  private readonly userSource = new BehaviorSubject<any>({});
  readonly user = this.userSource.asObservable();

  private readonly organizationSource = new BehaviorSubject<any>({});
  readonly organization = this.organizationSource.asObservable();

  // private readonly translationSource = new BehaviorSubject<any>({});
  // readonly translation = this.translationSource.asObservable();

  constructor(private axiosService: AxiosCallService, public translate: TranslateService) {
    this.refreshUser();
    this.refreshOrganization();
  }

  ngOnInit() {}

  defaultLanguage(lang) {
    this.translate.currentLang ? this.translate.setDefaultLang(lang) : this.translate.setDefaultLang('fr');
  }

  returnTranslation(val, type) {
    let str = '';

    // Find the translation that matches the users language
    const currentLanguage = this.translate.currentLang ? this.translate.currentLang : 'fr';
    let primary = val.find((translation) => translation.language == currentLanguage);
    str = primary[type];

    /* if (val && val.length > 0) {
      val.forEach((element) => {
        if (element.language === this.translate.currentLang) {
          str = element[type];
        }
      });
    } */

    return str;
  }

  clearState() {
    this.userSource.next({});
    this.organizationSource.next({});
  }

  getUser() {
    return this.userSource.getValue();
  }

  getOrganization() {
    return this.organizationSource.getValue();
  }

  setUser(user) {
    this.userSource.next(user);
  }

  setOrganization(organization) {
    this.organizationSource.next(organization);
  }

  refreshUser() {
    this.axiosService
      .getUser()
      .then((res) => {
        this.setUser(res.data.data);
      })
      .catch((error) => {
        if (error.response.status != 401) console.error('State Error: ', error);
      });
  }

  refreshOrganization() {
    this.axiosService
      .api_call_get('organizations', {})
      .then((res: any) => {
        this.setOrganization(res.data.data);
      })
      .catch((error) => {
        if (error.response.status != 401) console.error('State Error: ', error);
      });
  }
}
