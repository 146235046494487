import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AxiosCallService } from './services/axios-call.service';
import { UserService } from './services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Line';
  public loading = false;

  constructor(
    private axiosService: AxiosCallService,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService,
    private dialog: MatDialog,
    public translate: TranslateService
  ) {
    this.userService.loaderActivate.subscribe((res) => {
      this.loading = res;
    });

    this.translate.addLangs(['fr', 'de']);
    this.translate.setDefaultLang('fr');
    const storageLang = localStorage.getItem('currentLanguage');
    this.translate.use(storageLang ? storageLang : 'fr');

    this.axiosService
      .getUser()
      .then((response) => {
        const user = response.data.data;

        // If email is not verified log out and show message
        if (!user.verified) {
          this.axiosService
            .api_call_post_base('/logout', {})
            .then((response) => {
              this.router.navigate(['/login']);

              this.dialog.open(VerifyEmailComponent, {
                width: '500px',
                data: {
                  email: user.email
                }
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else if (window.location.pathname == '/login' || window.location.pathname == '/registrer') {
          this.router.navigate(['/dashboard']);
        }
      })
      .catch((error) => {
        console.log(error);
        this.userService.loaderActivate.next(false);

        if (window.location.pathname !== '/registrer') this.router.navigate(['/login']);

        if (error.response.data.message.includes('verified'))
          this.translate.get('verifyEmailError').subscribe((res: string) => {
            this.toastr.error(res);
          });
      })
    // .finally(() => this.userService.loaderActivate.next(false));

    /* if (window.location.pathname == '/login' || window.location.pathname == '/registrer') {
      this.axiosService
        .getUser()
        .then((response) => {
          this.router.navigate(['/dashboard']);
        })
        .catch((error) => {
          // Do nothing
        });
    } else {
      this.axiosService
        .getUser()
        .then((response: any) => {
          this.userService.loaderActivate.next(false);

          const user = response.data.data;

          // Check if user has verified email
          if (!user.verified) {
            this.axiosService
              .api_call_post_base('/logout', {})
              .then((response) => {
                this.router.navigate(['/login']);

                this.dialog.open(VerifyEmailComponent, {
                  width: '500px',
                  data: {
                    email: user.email
                  }
                });
              })
              .catch((error) => {
                console.log(error);
              });
          }
        })
        .catch((error) => {
          this.userService.loaderActivate.next(false);
          this.router.navigate(['/login']);
          if (error.response.data.message.includes('verified'))
            this.translate.get('verifyEmailError').subscribe((res: string) => {
              this.toastr.error(res);
            });
        });
    } */
  }

  ngOnInit() { }
}
