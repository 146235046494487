import { StateService } from 'src/app/services/state.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListeProcedureComponent } from '../liste-procedure/liste-procedure.component';
import { OuvertureComponent } from '../ouverture/ouverture.component';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { UserService } from 'src/app/services/user.service';
import { AddLinkComponent } from '../add-link/add-link.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgxFileDragDropComponent } from 'ngx-file-drag-drop';

@Component({
  selector: 'app-configurer-n-procedure',
  templateUrl: './configurer-n-procedure.component.html',
  styleUrls: ['./configurer-n-procedure.component.scss']
})
export class ConfigurerNProcedureComponent implements OnInit {
  // @ViewChild('frDocs') fDocs: NgxFileDragDropComponent;
  // @ViewChild('enDocs') eDocs: NgxFileDragDropComponent;
  // @ViewChild('deDocs') dDocs: NgxFileDragDropComponent;
  @ViewChildren('frDocs') fDocs: NgxFileDragDropComponent;
  @ViewChildren('enDocs') eDocs: NgxFileDragDropComponent;
  @ViewChildren('deDocs') dDocs: NgxFileDragDropComponent;
  user = this.data.user;
  editId = this.data.editId;
  procedure = this.data.procedure;
  organization = this.data.user.organization_id;
  configurerProcedure: FormGroup;
  procedureTypeId = null;
  fileToUpload: File = null;
  imgUrl = '';
  overtures = this.userService.overtures;
  deleteOuver = [];
  deleteDocs = [];
  links = this.userService.links;
  uploadedDocumentsEn = [];
  uploadedDocumentsFr = [];
  uploadedDocumentsDe = [];
  items: Array<object> = [];
  stepFilesEn = [];
  stepFilesFr = [];
  stepFilesDe = [];
  skip = false;
  userLanguage: string = '';
  acceptedExt = [
    '.pdf',
    '.docx',
    '.docm',
    '.dotx',
    '.dotm',
    '.docb',
    '.jpg',
    '.pjpeg',
    '.gif',
    '.svg',
    '.png',
    '.ico',
    '.json',
    '.ppt',
    '.pptx',
    '.rtf',
    '.txt',
    '.xls',
    '.xlsx',
    '.csv'
  ];

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private service: AxiosCallService,
    private userService: UserService,
    private toastr: ToastrService,
    public translate: TranslateService,
    public state: StateService,
    public dialogRef: MatDialogRef<ConfigurerNProcedureComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.userLanguage = this.translate.currentLang;

    // if (this.userService.editId === 0) {
    if (this.editId === 0) {
      this.configurerProcedure = this.fb.group({
        titre1: ['', [Validators.required]],
        pictograme: [''],
        etapes: this.fb.array([this.addEtapes()])
      });
    } else {
      this.configurerProcedure = this.fb.group({
        titre1: [this.userService.editStep.slug, [Validators.required]],
        pictograme: [''],
        etapes: this.fb.array([])
      });

      let temp = this.userService.editStep.steps.filter((step) => !step.is_ouverture);

      temp.forEach((element, index) => {
        let tdocsen = element.docs.filter((doc) => doc.language === 'en');
        this.uploadedDocumentsEn[index] = tdocsen;

        let tdocsfr = element.docs.filter((doc) => doc.language === 'fr');
        this.uploadedDocumentsFr[index] = tdocsfr;
        let tdocsde = element.docs.filter((doc) => doc.language === 'de');
        this.uploadedDocumentsDe[index] = tdocsde;

        let cs = element.canskip ? 1 : 0;
        let cw = element.canwaiting ? 1 : 0;

        (<FormArray>this.configurerProcedure.get('etapes')).push(
          this.addEtapes(
            element.translations[0].id,
            element.translations[0].name,
            element.translations[0].text,
            element.translations[1].id,
            element.translations[1].name,
            element.translations[1].text,
            element.translations[2].id,
            element.translations[2].name,
            element.translations[2].text,
            cs,
            cw,
            element.id
          )
        );
      });
    }
  }

  addEtapes(
    idEn = 0,
    nEn = '',
    tEn = '',
    idFr = 0,
    nFr = '',
    tFr = '',
    idDe = 0,
    nDe = '',
    tDe = '',
    cs = 0,
    cw = 0,
    ids = 0
  ): FormGroup {
    return this.fb.group({
      id: [ids, [Validators.required]],
      idEn: [idEn, [Validators.required]],
      nameEn: [nEn, [Validators.required]],
      textEn: [tEn, [Validators.required]],
      idFr: [idFr, [Validators.required]],
      nameFr: [nFr, [Validators.required]],
      textFr: [tFr, [Validators.required]],
      idDe: [idDe, [Validators.required]],
      nameDe: [nDe, [Validators.required]],
      textDe: [tDe, [Validators.required]],
      canskip: [cs],
      canwaiting: [cw]
    });
  }

  addEtapesButton(): void {
    (<FormArray>this.configurerProcedure.get('etapes')).push(this.addEtapes());
  }

  onSubmit(ev?: any) {
    if (ev) {
      ev.preventDefault();
    }

    let stepsToAdd = [];
    let invalid = false;
    if (this.overtures && this.overtures.length > 0) {
      this.overtures.forEach((element) => {
        element.is_ouverture = 1;
        stepsToAdd.push(element);
      });
    }

    if (this.configurerProcedure.value.etapes.length > 0) {
      this.configurerProcedure.value.etapes.forEach((element, index) => {
        element.translations = [
          { language: 'en', name: '', text: '' },
          { language: 'fr', name: '', text: '' },
          { language: 'de', name: '', text: '' }
        ];

        if (
          element.textEn === '' ||
          element.textFr === '' ||
          element.textDe === '' ||
          element.nameEn === '' ||
          element.nameFr === '' ||
          element.nameDe === ''
        ) {
          invalid = true;
          return this.translate.get('invalidData').subscribe((res: string) => {
            this.toastr.error(res);
          });
        }
        let textEn = element.textEn;
        let textFr = element.textFr;
        let textDe = element.textDe;

        element.is_ouverture = 0;

        if (element.canskip) element.canskip = 1;
        else element.canskip = 0;

        if (element.canwaiting) element.canwaiting = 1;
        else element.canwaiting = 0;

        if (this.links[index] && this.links[index].length > 0) {
          element.links = [];
          this.links[index].forEach((value) => {
            let params = {
              label_en: null,
              url_en: null,
              label_fr: null,
              url_fr: null,
              label_de: null,
              url_de: null,
              id: 0
            };

            if (value.label_en && value.url_en) {
              params.label_en = value.label_en;
              params.url_en = value.url_en;
            }

            if (value.label_fr && value.url_fr) {
              params.label_fr = value.label_fr;
              params.url_fr = value.url_fr;
            }

            if (value.label_de && value.url_de) {
              params.label_de = value.label_de;
              params.url_de = value.url_de;
            }

            element.links.push(params);
          });
        }

        element.translations[0].id = element.idEn;
        element.translations[0].name = element.nameEn;
        element.translations[0].text = textEn;

        element.translations[1].id = element.idFr;
        element.translations[1].name = element.nameFr;
        element.translations[1].text = textFr;

        element.translations[2].id = element.idDe;
        element.translations[2].name = element.nameDe;
        element.translations[2].text = textDe;

        // Get values from the users language
        const primary = element.translations.find((translation) => translation.language == this.userLanguage);
        element.name = primary.name ? primary.name : 'null';
        element.text = primary.text ? primary.text : 'null';

        stepsToAdd.push(element);
      });
    }

    let params: any = {
      organization_id: this.organization,
      slug: this.configurerProcedure.value.titre1,
      steps: null
    };

    if (stepsToAdd.length > 0) {
      stepsToAdd.forEach((value, index) => {
        value.sorting = index + 1;
      });
      params.steps = stepsToAdd;
    }
    if (invalid) {
      return;
    }
    // Show error if no ouvertures are added
    if (!params.steps.find((step) => step.is_ouverture))
      return this.translate.get('noOuverture').subscribe((res: string) => {
        this.toastr.error(res);
      });
    this.userService.loaderActivate.next(true);

    // New procedure type
    if (this.editId === 0) {
      // Create the procedure type
      this.service
        .api_call_post(`organizations/${this.organization}/proceduretypes`, params)
        .then((res: any) => {
          // Check if any steps have files attached, if yes upload them
          if (this.stepFilesFr.length > 0) {
            let fileData = new FormData();
            let temp = res.data.data.steps.filter((step) => !step.is_ouverture);

            temp.forEach((value, index) => {
              if (this.stepFilesFr[index] && this.stepFilesFr[index].length > 0) {
                this.stepFilesFr[index].forEach((el, i) => {
                  fileData.append('files[' + value.id + '][' + i + ']', el);
                });
              }
            });
            fileData.append('language', 'fr');
            this.service
              .api_call_post(
                `organizations/${this.organization}/proceduretypes/${res.data.data.id}?_method=PATCH`,
                fileData
              )
              .then((r: any) => {})
              .catch((error) => {
                if (error.response) {
                  console.log(error.response);
                  this.translate.get('invalidData').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                } else {
                  console.log(error);
                  this.toastr.error(error.message, 'Error');
                }

                // Delete the procedure because files failed to upload
                this.service
                  .api_call_delete(`organizations/${this.organization}/proceduretypes/${res.data.data.id}`)
                  .then((response) => {
                    this.translate.get('failedUpload').subscribe((res: string) => {
                      this.toastr.error(res);
                    });
                    this.overtures = [];
                    this.iks();
                  })
                  .catch((error) => {
                    if (error.response) {
                      console.log(error.response);
                      this.translate.get('invalidData').subscribe((res: string) => {
                        this.toastr.error(res);
                      });
                    } else {
                      console.log(error);
                      this.toastr.error(error.message, 'Error');
                    }
                  })
                  .finally(() => this.userService.loaderActivate.next(false));
              });
          }
          if (this.stepFilesDe.length > 0) {
            let fileData = new FormData();
            let temp = res.data.data.steps.filter((step) => !step.is_ouverture);

            temp.forEach((value, index) => {
              if (this.stepFilesDe[index] && this.stepFilesDe[index].length > 0) {
                this.stepFilesDe[index].forEach((el, i) => {
                  fileData.append('files[' + value.id + '][' + i + ']', el);
                });
              }
            });
            fileData.append('language', 'de');

            this.service
              .api_call_post(
                `organizations/${this.organization}/proceduretypes/${res.data.data.id}?_method=PATCH`,
                fileData
              )
              .then((r: any) => {})
              .catch((error) => {
                if (error.response) {
                  console.log(error.response);
                  this.translate.get('invalidData').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                } else {
                  console.log(error);
                  this.toastr.error(error.message, 'Error');
                }

                // Delete the procedure because files failed to upload
                this.service
                  .api_call_delete(`organizations/${this.organization}/proceduretypes/${res.data.data.id}`)
                  .then((response) => {
                    this.translate.get('failedUpload').subscribe((res: string) => {
                      this.toastr.error(res);
                    });
                    this.overtures = [];
                    this.iks();
                  })
                  .catch((error) => {
                    if (error.response) {
                      console.log(error.response);
                      this.translate.get('invalidData').subscribe((res: string) => {
                        this.toastr.error(res);
                      });
                    } else {
                      console.log(error);
                      this.toastr.error(error.message, 'Error');
                    }
                  })
                  .finally(() => this.userService.loaderActivate.next(false));
              });
          }
          if (this.stepFilesEn.length > 0) {
            let fileData = new FormData();
            let temp = res.data.data.steps.filter((step) => !step.is_ouverture);

            temp.forEach((value, index) => {
              if (this.stepFilesEn[index] && this.stepFilesEn[index].length > 0) {
                this.stepFilesEn[index].forEach((el, i) => {
                  fileData.append('files[' + value.id + '][' + i + ']', el);
                });
              }
            });
            fileData.append('language', 'en');

            this.service
              .api_call_post(
                `organizations/${this.organization}/proceduretypes/${res.data.data.id}?_method=PATCH`,
                fileData
              )
              .then((r: any) => {})
              .catch((error) => {
                if (error.response) {
                  console.log(error.response);
                  this.translate.get('invalidData').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                } else {
                  console.log(error);
                  this.toastr.error(error.message, 'Error');
                }

                // Delete the procedure because files failed to upload
                this.service
                  .api_call_delete(`organizations/${this.organization}/proceduretypes/${res.data.data.id}`)
                  .then((response) => {
                    this.translate.get('failedUpload').subscribe((res: string) => {
                      this.toastr.error(res);
                    });
                    this.overtures = [];
                    this.iks();
                  })
                  .catch((error) => {
                    if (error.response) {
                      console.log(error.response);
                      this.translate.get('invalidData').subscribe((res: string) => {
                        this.toastr.error(res);
                      });
                    } else {
                      console.log(error);
                      this.toastr.error(error.message, 'Error');
                    }
                  })
                  .finally(() => this.userService.loaderActivate.next(false));
              });
          }

          // Upload pictogram if it is attached
          if (this.fileToUpload) {
            let formData = new FormData();
            formData.append('picto', this.fileToUpload);

            this.service
              .api_call_post(
                `organizations/${this.organization}/proceduretypes/${res.data.data.id}?_method=PATCH`,
                formData
              )
              .then((r: any) => {
                this.overtures = [];
                this.iks();
              })
              .catch((error) => {
                if (error.response) {
                  console.log(error.response);
                  this.translate.get('invalidData').subscribe((res: string) => {
                    this.toastr.error(res);
                  });
                } else {
                  console.log(error);
                  this.toastr.error(error.message, 'Error');
                }
              })
              .finally(() => this.userService.loaderActivate.next(false));
          } else {
            this.userService.loaderActivate.next(false);
            this.overtures = [];
            this.iks();
          }
        })
        .catch((error) => {
          this.userService.loaderActivate.next(false);
          if (error.response) {
            console.log(error.response);
            this.translate.get('invalidData').subscribe((res: string) => {
              this.toastr.error(res);
            });
          } else {
            console.log(error);
            this.toastr.error(error.message, 'Error');
          }
        });
    } else {
      // Editing existing procedure type

      // Delete ouvertures
      if (this.deleteOuver.length > 0) {
        this.deleteOuver.forEach((element) => {
          this.service
            .api_call_delete(
              `organizations/${this.organization}/proceduretypes/${this.editId}/steps/${element}`
            )
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            });
        });
      }
      // Delete Docs
      if (this.deleteDocs.length > 0) {
        this.deleteDocs.forEach((element) => {
          this.service
            .api_call_delete(`organizations/${this.organization}/documents/${element}`)
            .then((res) => {})
            .catch((err) => {
              console.log(err);
            });
        });
      }
      // Check if any steps have files attached, if yes upload them
      if (this.stepFilesFr.length > 0) {
        let fileData = new FormData();
        let temp = this.userService.editStep.steps.filter((step) => !step.is_ouverture);

        temp.forEach((value, index) => {
          if (this.stepFilesFr[index] && this.stepFilesFr[index].length > 0) {
            this.stepFilesFr[index].forEach((el, i) => {
              fileData.append('files[' + value.id + '][' + i + ']', el);
            });
          }
        });
        fileData.append('language', 'fr');
        this.service
          .api_call_post(
            `organizations/${this.organization}/proceduretypes/${this.editId}?_method=PATCH`,
            fileData
          )
          .then((r: any) => {})
          .catch((error) => {
            // Error while uploading files
            if (error.response) {
              console.log(error.response);
              this.translate.get('invalidData').subscribe((res: string) => {
                this.toastr.error(res);
              });
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          });
      }
      if (this.stepFilesDe.length > 0) {
        let fileData = new FormData();
        let temp = this.userService.editStep.steps.filter((step) => !step.is_ouverture);

        temp.forEach((value, index) => {
          if (this.stepFilesDe[index] && this.stepFilesDe[index].length > 0) {
            this.stepFilesDe[index].forEach((el, i) => {
              fileData.append('files[' + value.id + '][' + i + ']', el);
            });
          }
        });
        fileData.append('language', 'de');
        this.service
          .api_call_post(
            `organizations/${this.organization}/proceduretypes/${this.editId}?_method=PATCH`,
            fileData
          )
          .then((r: any) => {})
          .catch((error) => {
            // Error while uploading files
            if (error.response) {
              console.log(error.response);
              this.translate.get('invalidData').subscribe((res: string) => {
                this.toastr.error(res);
              });
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          });
      }
      if (this.stepFilesEn.length > 0) {
        let fileData = new FormData();
        let temp = this.userService.editStep.steps.filter((step) => !step.is_ouverture);

        temp.forEach((value, index) => {
          if (this.stepFilesEn[index] && this.stepFilesEn[index].length > 0) {
            this.stepFilesEn[index].forEach((el, i) => {
              fileData.append('files[' + value.id + '][' + i + ']', el);
            });
          }
        });
        fileData.append('language', 'en');
        this.service
          .api_call_post(
            `organizations/${this.organization}/proceduretypes/${this.editId}?_method=PATCH`,
            fileData
          )
          .then((r: any) => {})
          .catch((error) => {
            // Error while uploading files
            if (error.response) {
              console.log(error.response);
              this.translate.get('invalidData').subscribe((res: string) => {
                this.toastr.error(res);
              });
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          });
      }

      this.service
        .api_call_post(
          `organizations/${this.organization}/proceduretypes/${this.editId}?_method=PATCH`,
          params
        )
        .then((r: any) => {
          //
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
            this.translate.get('invalidData').subscribe((res: string) => {
              this.toastr.error(res);
            });
          } else {
            console.log(error);
            this.toastr.error(error.message, 'Error');
          }
        });

      // Upload pictogram if it is attached
      if (this.fileToUpload) {
        let formData = new FormData();
        formData.append('picto', this.fileToUpload);

        this.service
          .api_call_post(
            `organizations/${this.organization}/proceduretypes/${this.editId}?_method=PATCH`,
            formData
          )
          .then((r: any) => {
            this.overtures = [];
            this.iks();
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.translate.get('invalidData').subscribe((res: string) => {
                this.toastr.error(res);
              });
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          })
          .finally(() => this.userService.loaderActivate.next(false));
      } else {
        this.userService.loaderActivate.next(false);
        this.overtures = [];
        this.iks();
      }
    }
  }

  addOuverture() {
    this.dialog.open(OuvertureComponent);
  }

  iks() {
    this.dialogRef.close();
    this.userService.overtures = [];
    this.userService.links = [];
    this.dialog.open(ListeProcedureComponent, {
      width: '80%',
      data: {
        user: this.user
      }
    });
  }

  handleDocumentInput(file: FileList, index: any, type: any) {
    if (type === 'fr') {
      if (!this.stepFilesFr[index]) {
        this.stepFilesFr[index] = [];
      }

      for (let i = 0; i < file.length; i++) {
        this.stepFilesFr[index].push(file.item(i));
      }
    } else if (type === 'de') {
      if (!this.stepFilesDe[index]) {
        this.stepFilesDe[index] = [];
      }

      for (let i = 0; i < file.length; i++) {
        this.stepFilesDe[index].push(file.item(i));
      }
    } else if (type === 'en') {
      if (!this.stepFilesEn[index]) {
        this.stepFilesEn[index] = [];
      }

      for (let i = 0; i < file.length; i++) {
        this.stepFilesEn[index].push(file.item(i));
      }
    }
  }

  dragDropDoc(file, index: any, type: any) {
    if (type === 'fr') {
      if (!this.stepFilesFr[index]) {
        this.stepFilesFr[index] = [];
      }
      file.forEach((element) => {
        /*
        // try this if checking with type instead of extension
        if (this.isFileAllowed(element.type)) {
          // this.stepFilesFr[index].push(file);
        } else {
          this.fDocs['_results'][index].removeFile(element);
        }
      });*/
        if (this.isFileAllowed(element.name)) {
          // this.stepFilesFr[index].push(file);
        } else {
          this.fDocs['_results'][index].removeFile(element);
        }
      });
      this.stepFilesFr[index] = this.fDocs['_results'][index].files;
    } else if (type === 'de') {
      if (!this.stepFilesDe[index]) {
        this.stepFilesDe[index] = [];
      }
      file.forEach((element) => {
        if (this.isFileAllowed(element.name)) {
          // this.stepFilesDe[index].push(file);
        } else {
          this.dDocs['_results'][index].removeFile(element);
        }
      });
      this.stepFilesDe[index] = this.dDocs['_results'][index].files;
    } else if (type === 'en') {
      if (!this.stepFilesEn[index]) {
        this.stepFilesEn[index] = [];
      }
      file.forEach((element) => {
        if (this.isFileAllowed(element.name)) {
          // this.stepFilesEn[index].push(file);
        } else {
          this.eDocs['_results'][index].removeFile(element);
        }
      });
      this.stepFilesEn[index] = this.eDocs['_results'][index].files;
    }
  }
  isFileAllowed(fileName: string) {
    let isFileAllowed = false;
    const allowedFiles = this.acceptedExt;
    // ['.pdf', '.docx', '.docm', '.dotx', '.dotm', '.docb', '.jpg', '.pjpeg', '.gif', '.svg', '.png', '.ico', '.json', '.ppt', '.pptx', '.rtf', '.txt', '.xls', '.xlsx', '.csv'];
    /*
    // try this if checking with type instead of extension
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/jpeg', 'image/pjpeg', 'image/gif', 'image/svg+xml', 'image/png', 'image/vnd.microsoft.icon', 'image/x-icon', 'application/json', 'application/docx', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/rtf', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
    */
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      /*
      // try this if checking with type instead of extension
      for (const ext of allowedTypes) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      } 
      */
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
      if (!isFileAllowed) {
        this.translate.get('fileError').subscribe((res: string) => {
          this.toastr.error(res);
        });
      }
    }
    return isFileAllowed;
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();

    reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
    };

    reader.readAsDataURL(this.fileToUpload);
  }

  addLink(i): void {
    this.userService.currentLinkInd = i;
    this.dialog.open(AddLinkComponent);
  }

  deleteOverture(i, id): void {
    if (id !== 0) {
      this.deleteOuver.push(id);
    }
    this.userService.overtures.splice(i, 1);
  }

  deleteUploaded(i, k, id, type): void {
    if (id !== 0) {
      this.deleteDocs.push(id);
    }
    if (type === 'en') {
      this.uploadedDocumentsEn[i].splice(k, 1);
    } else if (type === 'fr') {
      this.uploadedDocumentsFr[i].splice(k, 1);
    } else if (type === 'de') {
      this.uploadedDocumentsDe[i].splice(k, 1);
    }
  }

  deleteLinks(i, k): void {
    this.userService.links[i].splice(k, 1);
  }

  printFileNames(files) {
    let str = '';
    for (let i = 0; i < files.length; i++) {
      str += files[i].name;
      if (i !== files.length - 1) {
        str += ', ';
      }
    }
    return str;
  }

  changeTest(etape) {}
  returnLinkName(item) {
    if (item.label) {
      return item.label;
    } else {
      return item['label_' + this.userLanguage];
    }
  }
}
