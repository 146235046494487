import { AreYouSureComponent } from './../are-you-sure/are-you-sure.component';
import { UserService } from './../../services/user.service';
import { NewGroupComponent } from './../new-group/new-group.component';
import { StateService } from './../../services/state.service';
import { Component, OnInit } from '@angular/core';
import { AxiosCallService } from 'src/app/services/axios-call.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddUserDialogComponent } from '../add-user-dialog/add-user-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ChangeRoleDialogComponent } from '../change-role-dialog/change-role-dialog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  user = null;
  isAdmin = false;
  organization = null;
  requests = null;
  baseUrl = null;
  newName = null;
  showInput = false;
  searchUser;
  searchMembership;
  fileToUpload: File = null;
  imgUrl;

  // Dialog variables
  data = {
    name: '',
    description: ''
  };

  constructor(
    private axiosCallService: AxiosCallService,
    private state: StateService,
    private dialog: MatDialog,
    private userService: UserService,
    private toastr: ToastrService,
    public translate: TranslateService
  ) {
    this.baseUrl = this.axiosCallService.baseUrl;
  }

  ngOnInit(): void {
    this.userService.loaderActivate.next(true);
    this.state.refreshOrganization();
    this.state.user.subscribe((res) => {
      if (res.id) {
        this.user = res;
        if (res.roles.length) this.isAdmin = true;
      }
    });

    this.state.organization.subscribe((res) => {
      if (res.id) {
        this.organization = res;
        this.newName = res.name;

        this.axiosCallService
          .api_call_get(`organizations/${res.id}/requests`)
          .then((response) => {
            var requests = response.data.data;
            // TODO: move filtering logic to backend
            requests = requests.filter(request => {
              const oneDay = 24 * 60 * 60 * 1000; // in ms
              if (!!request.updated_at && request.status !== 'pending') {
                const updateTime = new Date(request.updated_at).getTime();
                if (updateTime + oneDay < Date.now()) {
                  return false;
                }
              }
              return true;
            });
            this.requests = requests;
            this.userService.loaderActivate.next(false);
          })
          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
          });
      }
    });
  }

  changeRole(userId) {
    const dialogRef = this.dialog.open(ChangeRoleDialogComponent, {
      data: {
        role: 'member'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Security check
        if (userId == this.user.id) return;
        if (userId == this.organization.created_by) return;
        /* Send request */
        this.axiosCallService
          .api_call_post(`organizations/${this.organization.id}/users/${userId}/role`, {
            organization_id: this.organization.id,
            user_id: userId,
            role: result.role
          })
          .then((response) => {
            this.state.refreshUser();
            this.state.refreshOrganization();
          })
          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
          });
      }
    });
  }

  refreshRequests() {
    this.axiosCallService
      .api_call_get(`organizations/${this.organization.id}/requests`)
      .then((response) => {
        this.requests = response.data.data;
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  editOrganization() {
    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}?_method=PATCH`, {
        name: this.newName
      })
      .then((res) => {
        this.state.setOrganization(res.data.data); //ska nevoj tboet refresh per te dal emri
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
    this.showInput = false;
  }

  removeGroupUserDialog(groupId, userId) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        group_id: groupId,
        user_id: userId
      }
    });

    dialogRef.afterClosed().subscribe((element) => {
      if (element) this.removeGroupUser(element.group_id, element.user_id);
    });
  }

  removeGroupUser(groupId, userId) {
    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}/groups/${groupId}/remove/${userId}`, {})
      .then((res) => {
        this.state.refreshOrganization();
        this.state.refreshUser();
        this.translate.get('groupUpdated').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      });
  }

  removeOrganizationUserDialog(userId) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        user_id: userId
      }
    });

    dialogRef.afterClosed().subscribe((element) => {
      if (element) this.removeOrganizationUser(element.user_id);
    });
  }

  removeOrganizationUser(userId) {
    this.axiosCallService
      .api_call_delete(`organizations/${this.organization.id}/users/${userId}`)
      .then((res) => {
        this.state.refreshOrganization();
        this.state.refreshUser();
        this.translate.get('organizationUpdated').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      });
  }

  addUser(groupId) {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      data: {
        user: 'select'
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.user > 0) {
        this.axiosCallService
          .api_call_post(`organizations/${this.organization.id}/groups/${groupId}/add/${result.user}`, {})
          .then((res) => {
            this.state.refreshOrganization();
            this.state.refreshUser();
            this.translate.get('groupUpdated').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.toastr.error(error.response.data.errors.message, 'Error');
            } else {
              console.log(error);
              this.toastr.error(error.message, 'Error');
            }
          });
      }
    });
  }

  createGroup() {
    const dialogRef = this.dialog.open(NewGroupComponent, {
      data: {
        name: '',
        description: '',
        place: '',
        po_box: '',
        zip: '',
        email: '',
        web: '',
        phone: ''
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.name) {
        let params: any = {
          organization_id: this.organization.id,
          name: result.name,
          description: result.description,
          place: result.place,
          po_box: result.po_box,
          zip: result.zip,
          email: result.email,
          web: result.web,
          phone: result.phone
        };

        if (!params.description) delete params.description;
        if (!params.place) delete params.place;
        if (!params.po_box) delete params.po_box;
        if (!params.zip) delete params.zip;
        if (!params.email) delete params.email;
        if (!params.web) delete params.web;
        if (!params.phone) delete params.phone;

        this.axiosCallService
          .api_call_post(`organizations/${this.organization.id}/groups`, params)
          .then((res) => {
            this.state.refreshOrganization();
          })
          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
          });
      } else {
        this.translate.get('groupNameRequired').subscribe((res: string) => {
          this.toastr.error(res);
        });
      }
    });
  }

  editGroup(id: Number) {
    const group = this.organization.groups.find((group) => group.id == id);

    const dialogRef = this.dialog.open(NewGroupComponent, {
      data: {
        name: group.name,
        description: group.description,
        place: group.place,
        po_box: group.po_box,
        zip: group.zip,
        email: group.email,
        web: group.web,
        phone: group.phone
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.name || result.description) {
        let params = {
          name: result.name,
          description: result.description,
          place: result.place,
          po_box: result.po_box,
          zip: result.zip,
          email: result.email,
          web: result.web,
          phone: result.phone
        };

        if (!params.name) delete params.name;
        if (!params.description) delete params.description;
        if (!params.place) delete params.place;
        if (!params.po_box) delete params.po_box;
        if (!params.zip) delete params.zip;
        if (!params.email) delete params.email;
        if (!params.web) delete params.web;
        if (!params.phone) delete params.phone;

        this.axiosCallService
          .api_call_post(`organizations/${this.organization.id}/groups/${id}?_method=PATCH`, params)
          .then((res) => {
            this.state.refreshOrganization();
            this.state.refreshUser();
            this.translate.get('groupUpdated').subscribe((res: string) => {
              this.toastr.success(res);
            });
          })

          .catch((error) => {
            error.response ? console.log(error.response) : console.log(error);
          });
      }
    });
  }

  dangerDialogGroup(id) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      data: {
        group_id: id
      }
    });

    dialogRef.afterClosed().subscribe((group) => {
      if (group) this.deleteGroup(group.group_id);
    });
  }

  deleteGroup(id) {
    this.axiosCallService
      .api_call_delete(`organizations/${this.organization.id}/groups/${id}`)
      .then((res) => {
        this.state.refreshOrganization();
        this.state.refreshUser();
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      });
  }

  acceptRequest(id) {
    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}/requests/accept/${id}`, {})
      .then((response) => {
        this.state.refreshOrganization();
        this.refreshRequests();
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  declineRequest(id) {
    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}/requests/decline/${id}`, {})
      .then((response) => {
        this.state.refreshOrganization();
        this.refreshRequests();
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  handleFileInput(id, file) {
    this.fileToUpload = file.item(0);
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgUrl = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);

    let formData = new FormData();
    formData.append('profile_pic', this.fileToUpload);

    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}/groups/${id}?_method=PATCH`, formData)
      .then((res) => {
        this.state.refreshOrganization();
        // this.state.refreshUser();
        this.translate.get('groupUpdated').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((error) => {
        error.response ? console.log(error.response) : console.log(error);
      });
  }

  deleteLogo(id) {
    let params = {
      remove_logo: 'true'
    };
    this.axiosCallService
      .api_call_post(`organizations/${this.organization.id}/groups/${id}?_method=PATCH`, params)
      .then((res) => {
        this.state.refreshOrganization();
        this.translate.get('groupUpdated').subscribe((res: string) => {
          this.toastr.success(res);
        });
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response);
          this.toastr.error(error.response.message, 'Error');
        } else {
          console.log(error);
          this.toastr.error(error.message, 'Error');
        }
      });
  }
}
