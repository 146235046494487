<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="label" style="color: #707070; font-weight: lighter" translate>fileRef</label>
    <input type="text" class="form-control" id="label" [(ngModel)]="data.name" />
  </div>

  <div class="text-center">
    <button class="btn btn-secondary rounded-pill mr-3" (click)="onNoClick()" translate>cancel</button>
    <button class="btn btn-primary rounded-pill" [mat-dialog-close]="data" translate>save</button>
  </div>
</div>
