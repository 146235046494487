import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { StateService } from 'src/app/services/state.service';

interface DialogData {
  baseUrl: string;
  procedure: any;
  userLanguage: string;
}

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {
  constructor(
    private dialog: MatDialog,
    public state: StateService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  iks() {
    this.dialog.closeAll();
  }
  returnDocs(val) {
    let tmp: any = this.data;
    val = val.filter((doc) => doc.language == tmp.language);
    return val;
  }
}
