<div class="shadow bg-white" style="border-radius: 10px; height: 100%; width: 100%">
  <ul class="nav navbar-nav flex-row flex-md-column justify-content-center" style="align-items: center">
    <li class="mx-3 my-3 mt-0 mt-sm-4 mb-sm-4 mx-sm-1 text-center">
      <a *ngIf="user" routerLink="/profile">
        <img
          class="rounded-circle"
          [src]="baseUrl + user.profile_pic"
          onerror="this.src='../../assets/images/default-profile-pic.png'"
          alt=""
          style="width: 50px; height: 50px; object-fit: cover; object-position: center"
          routerlink="/profile"
        />
        <p class="mt-2 mb-0" style="color: #212529">{{ user.firstname }} {{ user.lastname }}</p>
        <p *ngIf="isAdmin" class="mt-1 mb-0"><span class="badge badge-dark">Admin</span></p>
      </a>
    </li>

    <li class="mx-3 my-3 mx-sm-2">
      <a routerLink="/dashboard">
        <svg xmlns="http://www.w3.org/2000/svg" width="29.55" height="29.55" viewBox="0 0 29.55 29.55">
          <path
            routerLinkActive="changeStroke"
            id="Icon_color"
            data-name="Icon color"
            d="M27.75,14.245V26.208a1.542,1.542,0,0,1-1.542,1.542H20.042A1.542,1.542,0,0,1,18.5,26.208V17.729a.771.771,0,0,0-.771-.771H10.021a.771.771,0,0,0-.771.771v8.479A1.542,1.542,0,0,1,7.708,27.75H1.542A1.542,1.542,0,0,1,0,26.208V14.245a4.625,4.625,0,0,1,1.357-3.268L11.886.447A1.542,1.542,0,0,1,12.965,0h1.819a1.542,1.542,0,0,1,1.079.447l10.53,10.53A4.625,4.625,0,0,1,27.75,14.245Z"
            transform="translate(0.9 0.9)"
            fill="none"
            stroke="#d3ddea"
            stroke-width="1.8"
          />
        </svg>
      </a>
    </li>
    <li class="mx-3 my-3 mx-sm-2">
      <a routerLink="/dossiers"
        ><svg id="folder" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
          <path
            routerLinkActive="changeStroke"
            id="Icon_color"
            data-name="Icon color"
            d="M25.5,2.833H17.581a1.417,1.417,0,0,1-.992-.411L14.577.411A1.417,1.417,0,0,0,13.586,0H2.833A2.833,2.833,0,0,0,0,2.833v17a2.833,2.833,0,0,0,2.833,2.833H25.5a2.833,2.833,0,0,0,2.833-2.833V5.667A2.833,2.833,0,0,0,25.5,2.833Z"
            transform="translate(2.833 5.667)"
            fill="none"
            stroke="#d3ddea"
            stroke-width="1.8"
          />
        </svg>
      </a>
    </li>
    <li class="mx-3 my-3 mx-sm-2">
      <a routerLink="/profile"
        ><svg xmlns="http://www.w3.org/2000/svg" width="29.845" height="29.8" viewBox="0 0 29.845 29.8">
          <path
            routerLinkActive="changeStroke"
            id="Icon_color"
            data-name="Icon color"
            d="M26.254,28H1.746A1.75,1.75,0,0,1,.189,25.463L2.71,20.405A5.222,5.222,0,0,1,7.41,17.5H20.581a5.228,5.228,0,0,1,4.709,2.906l2.521,5.057A1.75,1.75,0,0,1,26.254,28ZM14,14a7,7,0,1,1,7-7A6.956,6.956,0,0,1,14,14Z"
            transform="translate(0.922 0.9)"
            fill="none"
            stroke="#d3ddea"
            stroke-width="1.8"
          />
        </svg>
      </a>
    </li>
    <li class="mx-3 my-3 mx-sm-2">
      <a routerLink="/organization">
        <svg
          routerLinkActive="changeStroke"
          xmlns="http://www.w3.org/2000/svg"
          enable-background="new 0 0 24 24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#d3ddea"
          width="35px"
          height="35px"
        >
          <!-- <rect fill="none" height="24" width="24" /> -->
          <path
            d="M12,7V3H2v18h20V7H12z M10,19H4v-2h6V19z M10,15H4v-2h6V15z M10,11H4V9h6V11z M10,7H4V5h6V7z M20,19h-8V9h8V19z M18,11h-4v2 h4V11z M18,15h-4v2h4V15z"
          />
        </svg>
      </a>
    </li>
  </ul>
</div>
