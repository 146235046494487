<button type="button" class="close" aria-label="Close" [mat-dialog-close]>
  <span aria-hidden="true">&times;</span>
</button>
<h1 translate>
  contacts
  <button *ngIf="personTypes.length >= 1" (click)="addContact()" class="btn btn-success">+</button>
</h1>
<div class="scroll">
  <ul class="list-unstyled pr-2">
    <li
      *ngFor="let person of data.procedure.people"
      class="mb-3 d-flex flex-row flex-wrap align-items-center justify-content-between"
    >
      <div>
        <div>
          <p class="mb-1 fs-6" *ngIf="person.firstname || person.lastname">
            {{ person.firstname }} {{ person.lastname }}
          </p>
          <p class="mb-1 fs-6" *ngIf="person.company && !person.firstname && !person.lastname">
            {{ person.company }}
          </p>
          <p class="mb-1 fs-6" *ngIf="!person.company && !person.firstname && !person.lastnamee">
            {{ getSinglePersonTypeTranslation(person) }}
          </p>
          <p class="m-0 text-muted" *ngIf="person.company || person.firstname || person.lastnamee" style="font-size: 12px">
            {{ getSinglePersonTypeTranslation(person) }}
          </p>
        </div>
      </div>
      <div>
        <button (click)="editContact(person)" class="btn btn-warning mr-2" style="font-size: 14px" translate>
          edit
        </button>
        <button (click)="deleteContact(person.id)" class="btn btn-danger" style="font-size: 14px" translate>
          delete
        </button>
      </div>
    </li>
  </ul>
</div>
