<div>
  <div class="mb-3">
    <button type="button" class="close" aria-label="Close" [mat-dialog-close]>
      <span aria-hidden="true">&times;</span>
    </button>
    <label for="labelEn" class="custom-label" translate>en</label>
    <input type="text" class="form-control" id="labelEn" [(ngModel)]="labelEn" />
    <div class="mb-3">
      <label for="linkUrlEn" class="custom-label" translate>linkUrl</label>
      <input type="url" id="linkUrlEn" [ngClass]="checkUrl()" [(ngModel)]="linkUrlEn" (change)="checkUrl()" />
      <small class="text-muted" translate>validLink</small>
    </div>
    <label for="labelFr" class="custom-label" translate>fr</label>
    <input type="text" class="form-control my-2" id="labelFr" [(ngModel)]="labelFr" />
    <div class="mb-3">
      <label for="linkUrlFr" class="custom-label" translate>linkUrl</label>
      <input type="url" id="linkUrlFr" [ngClass]="checkUrl()" [(ngModel)]="linkUrlFr" (change)="checkUrl()" />
      <small class="text-muted" translate>validLink</small>
    </div>
    <label for="labelDe" class="custom-label" translate>de</label>
    <input type="text" class="form-control" id="labelDe" [(ngModel)]="labelDe" />
    <div class="mb-3">
      <label for="linkUrlDe" class="custom-label" translate>linkUrl</label>
      <input type="url" id="linkUrlDe" [ngClass]="checkUrl()" [(ngModel)]="linkUrlDe" (change)="checkUrl()" />
      <small class="text-muted" translate>validLink</small>
    </div>
  </div>

  <div class="text-center">
    <button class="btn btn-primary rounded-pill" (click)="save()" translate>save</button>
  </div>
</div>
